import styled from "@emotion/styled";
import { Col, Modal, Row, Select, Spin } from "antd";
import Button from "atoms/Button";
import COLORS from "common/Colors";
import { LEAVE_ICON } from "common/Constants";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import LeaveDonutChart from "./LeaveDonutChart";
// import COLORS from 'common/Colors';

// Redux
import { reduce } from "lodash";
import { connect } from "react-redux";
import {
  getAllDepartmentList,
  getClientid,
  getUserList,
  getUserRoleId,
} from "redux/selectors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledModal = styled(Modal)`
  width: 700px !important;
  .ant-modal-body {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 10px;
`;

const ButtonOutlined = styled(Button)`
  padding: 5px 20px;
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 5px;
  background: transparent;
  font-weight: normal;
  color: ${COLORS.PRIMARY};
  white-space: nowrap;

  &:hover {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  &:focus {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  @media (max-width: 900px) {
    margin: 10px 0px;
  }
`;

const StyledSelect = styled(Select)`
  padding-bottom: 20px;
  .ant-select-arrow {
    top: 30% !important;
  }
`;

function DepartmentStaffLeaveBalance({
  displayModal,
  toggleModal,
  leaveTypeId,
  leaveName,
  selectedDepartmentId,
  leaveCategoryList,
  allDepartmentList,
  userList,
  clientId,
  leaveList,
  leaveCatgeory,
  filterDepartment,
  all,
  storeUserData,
}) {
  const [userLeaves, setUserLeaves] = useState([]);
  const [displayLeaveReportLoader, toggleLeaveReportLoader] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState([]);
  const [departmentName, setdepartmentName] = useState("");
  const [CSVDepartmentLeave, setCSVDepartmentLeave] = useState([]);
  const csvLinkDepartment = useRef();
  const [leaveSummary, setLeaveSummary] = useState({});
  const [departmentLeave, setDepartmentLeave] = useState({});
  const [showMemberList, setShowMemberList] = useState(false);

  const filteredUserList = userList.filter((user) => user.statusid === "1");
  useEffect(() => {
    setFilteredUsers([]);
    setShowMemberList(false);
    setFilter([]);
  }, [displayModal]);

  useEffect(() => {
    let leaveStatistics = {};
    allDepartmentList.forEach((category) => {
      leaveStatistics[category.department_id] = {
        available: 0,
        consumed: 0,
      };
    });

    let approvedLeaves = [];
    approvedLeaves = leaveCatgeory.filter((item) => {
      return item.status_id === "5" && item.leave_category_id === leaveTypeId;
    });

    approvedLeaves.forEach((leave) => {
      const leaveCategoryName = leave.department_id;
      leaveStatistics[leaveCategoryName].consumed += parseFloat(
        leave.number_of_days
      );
    });

    const updatedLeaveData = allDepartmentList.map((category) => {
      const categoryName = category.department_name;
      const viewDepartmentId = category.department_id;

      let availableSum = 0;
      let consumeSum = 0;

      leaveCategoryList.forEach((leaveData) => {
        const LeaveTypeId = leaveData.leave_category_id;
        storeUserData.forEach((user) => {
          if (
            LeaveTypeId === leaveTypeId &&
            user.userdepartmentid === viewDepartmentId
          ) {
            if (
              user.genter.toLowerCase() === leaveData.eligible.toLowerCase() ||
              leaveData.eligible === "both" ||
              leaveData.eligible === "Both"
            ) {
              if (user.available) {
                availableSum += parseFloat(user.available);
              }
            }
            if (
              user.genter.toLowerCase() === leaveData.eligible.toLowerCase() ||
              leaveData.eligible === "both" ||
              leaveData.eligible === "Both"
            ) {
              if (user.consume) {
                consumeSum += parseFloat(user.consume);
              }
            }
          }
        });
      });

      const availableCount = leaveCategoryList.find(
        (data) => data.leave_category_id === leaveTypeId
      );

      const departmentFilteredUserList = filteredUserList.filter((user) => {
        const userDepartmentIds = user.userdepartmentid;
        return Object.values(userDepartmentIds).some((deptIds) =>
          deptIds.includes(viewDepartmentId)
        );
      });

      return {
        categoryName: categoryName,
        categoryid: viewDepartmentId,
        data: [
          {
            type: "available",
            // value: parseInt(departmentFilteredUserList?.length * availableCount?.number_of_leaves || 0, 10),
            value: availableSum,
          },
          {
            type: "consumed",
            // value: leaveStatistics[categoryid]?.consumed || 0,
            value: consumeSum,
          },
        ],
      };
    });

    setLeaveSummary(updatedLeaveData);
    setUserLeaves(updatedLeaveData);
  }, [leaveTypeId, leaveCatgeory, filteredUsers, storeUserData]);

  const selecetDepartment = (id) => {
    let leaveStatistics = {};
    // const teamFilteredUserList = filteredUserList.filter((user) => {
    //   const userDepartmentIds = user.basedepartmentid;
    //   return Object.values(userDepartmentIds).some((deptIds) =>
    //     deptIds.includes(id)
    //   );
    //   return userDepartmentIds
    // });
    const teamFilteredUserList = filteredUserList.filter(
      (user) => user.basedepartmentid === id
    );
    teamFilteredUserList.forEach((user) => {
      leaveStatistics[user.userid] = {
        available: 0,
        consumed: 0,
      };
    });
    let approvedLeaves = leaveCatgeory.filter((item) => {
      const isDepartmentFiltered = item.department_id === id;
      return (
        isDepartmentFiltered &&
        item.status_id === "5" &&
        item.leave_category_id === leaveTypeId
      );
    });

    approvedLeaves.forEach((leave) => {
      const leaveCategoryName = leave.userid;
      if (!leaveStatistics[leaveCategoryName]) {
        leaveStatistics[leaveCategoryName] = {
          available: 0,
          consumed: 0,
        };
      }
    });
    const getAllActiveTotal = leaveCategoryList.map((category) => {
      let sum = 0;
      let available = 0;
      let user = "";
      let processedUsers = {};
      const LeaveTypeId = category.leave_category_id;
      teamFilteredUserList.forEach((storeUser) => {
        storeUserData.forEach((finduser) => {
          if (
            storeUser.userid === finduser.userID &&
            LeaveTypeId === leaveTypeId
          ) {
            if (
              finduser.genter.toLowerCase() ===
                category.eligible.toLowerCase() ||
              category.eligible === "both" ||
              category.eligible === "Both"
            ) {
              user = storeUser.userid;
              if (processedUsers[user]) {
                available += parseFloat(finduser.available) || 0;
                sum += parseFloat(finduser.consume) || 0;
              } else {
                available = parseFloat(finduser.available) || 0;
                sum = parseFloat(finduser.consume);
                processedUsers[user] = true;
              }
            }
          }
        });

        if (!leaveStatistics[user]) {
          leaveStatistics[user] = {};
        }

        leaveStatistics[user].available = available;
        leaveStatistics[user].consumed = sum;
      });
    });

    const updatedLeaveData = teamFilteredUserList.map((user) => {
      const categoryName = user.fullname;
      const categoryid = user.userid;

      const availableCount = leaveCategoryList.find(
        (data) => data.leave_category_id === leaveTypeId
      );

      return {
        categoryName: categoryName,
        categoryid: categoryid,
        data: [
          {
            type: "available",
            value: leaveStatistics[categoryid]?.available || 0,
          },
          {
            type: "consumed",
            value: leaveStatistics[categoryid]?.consumed || 0,
          },
        ],
      };
    });

    setDepartmentLeave(updatedLeaveData);
  };

  const showDepartmentMemberList = (id) => {
    setFilteredUsers([id]);
    selecetDepartment(id);
    setShowMemberList(true);
  };

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Available Leaves" },
    { label: "Consumed Leaves" },
    { label: "Total Leaves" },
  ];
  const prepareCSV = async (event, done) => {
    if (showMemberList) {
      if (departmentLeave.length > 0) {
        setCSVDepartmentLeave(
          reduce(
            departmentLeave.filter((leave) => {
              if (filter && filter.length) {
                return filter.includes(leave.categoryid);
              }
              return true;
            }),
            function (result, leave) {
              result.push({
                Name: leave.categoryName,
                "Available Leaves": leave.data[0].value - leave.data[1].value,
                "Consumed Leaves": leave.data[1].value,
                "Total Leaves": leave.data[0].value,
              });
              return result;
            },
            []
          )
        );
        setTimeout(() => {
          csvLinkDepartment.current.link.click();
        });
      } else {
        console.log("No data available to download");
      }
    } else {
      if (userLeaves.length > 0) {
        setCSVDepartmentLeave(
          reduce(
            userLeaves,
            function (result, leave) {
              result.push({
                "Department ID": leave.categoryid,
                "Department Name": leave.categoryName,
                "Available Leaves": leave.data[0].value - leave.data[1].value,
                "Consumed Leaves": leave.data[1].value,
                "Total Leaves": leave.data[0].value,
              });
              return result;
            },
            []
          )
        );

        setTimeout(() => {
          csvLinkDepartment.current.link.click();
        });
      } else {
        console.log("No data available to download");
      }
    }
  };

  function findLeaveCategoryName(leaveCategoryList, leaveTypeId) {
    const leaveCategory = leaveCategoryList.find(
      (category) => category.leave_category_id === leaveTypeId
    );
    return leaveCategory
      ? leaveCategory.leave_category_name
      : "Category not found";
  }
  const leaveCategoryName = findLeaveCategoryName(
    leaveCategoryList,
    leaveTypeId
  );
  const handleFilterChange = (newValue) => {
    setFilter(newValue);
  };

  return (
    <>
      <StyledModal
        title={
          showMemberList
            ? `${departmentName} - ${leaveCategoryName} Report`
            : `Overall ${leaveCategoryName} Report`
        }
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
      >
        <Spin spinning={displayLeaveReportLoader}>
          <Row gutter={16}>
            <Col span={8}>
              <p>Department</p>
              <StyledSelect
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Search Department"
                name="user"
                optionFilterProp="department_name"
                options={allDepartmentList}
                fieldNames={{
                  label: "department_name",
                  value: "department_id",
                }}
                value={filteredUsers}
                onChange={(newValue) => {
                  const selectedDepartment = allDepartmentList.find(
                    (dept) => dept.department_id === newValue
                  );
                  setFilteredUsers([newValue]);
                  setdepartmentName(selectedDepartment.department_name);
                  showDepartmentMemberList(newValue);
                }}
              />
            </Col>
            <Col span={8}>
              <p>Employee</p>
              <StyledSelect
                mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="All"
                name="user"
                optionFilterProp="fullname"
                filterOption={(input, option) =>
                  option.fullname.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  optionA.fullname
                    .toLowerCase()
                    .localeCompare(optionB.fullname.toLowerCase());
                }}
                options={filteredUserList}
                fieldNames={{
                  label: "fullname",
                  value: "userid",
                }}
                value={filter}
                onChange={handleFilterChange}
                disabled={!showMemberList}
              />
            </Col>

            <Col
              span={8}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <ButtonOutlined
                style={{ margin: "0px !important" }}
                onClick={prepareCSV}
                disabled={userLeaves.length === 0}
              >
                Export CSV file
              </ButtonOutlined>
              <CSVLink
                header={CSVHeaders}
                data={CSVDepartmentLeave}
                filename={"Department Leave List.csv"}
                asyncOnClick={true}
                ref={csvLinkDepartment}
              />
            </Col>
          </Row>
          {showMemberList ? (
            <Row gutter={16}>
              {Array.isArray(departmentLeave) &&
                departmentLeave
                  .filter((leave) => {
                    if (filter && filter.length) {
                      return filter.includes(leave.categoryid);
                    }
                    return true;
                  })
                  .map((value, index) => (
                    <StyledCol md={12} sm={24} xs={24} key={index}>
                      <LeaveDonutChart
                        iconName={LEAVE_ICON[7]}
                        title={value.categoryName}
                        data={value.data}
                      />
                    </StyledCol>
                  ))}
            </Row>
          ) : (
            <Row gutter={16}>
              {Array.isArray(leaveSummary) &&
                leaveSummary
                  .filter(
                    (value) =>
                      filteredUsers.length === 0 ||
                      filteredUsers.includes(value.categoryid)
                  )
                  .map((value, index) => (
                    <StyledCol
                      md={12}
                      sm={24}
                      xs={24}
                      key={index}
                      onClick={() => {
                        showDepartmentMemberList(value.categoryid);
                        const selectedDepartment = allDepartmentList.find(
                          (dept) => dept.department_id === value.categoryid
                        );
                        setdepartmentName(selectedDepartment.department_name);
                      }}
                    >
                      <LeaveDonutChart
                        iconName={LEAVE_ICON[7]}
                        title={value.categoryName}
                        data={value.data}
                      />
                    </StyledCol>
                  ))}
            </Row>
          )}
        </Spin>
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
  clientId: getClientid(state),
});

export default connect(mapStateToProps, null)(DepartmentStaffLeaveBalance);
