import { Row, Col } from "react-bootstrap";
import { Button, Spin, Input } from "antd";
import { useEffect, useState } from "react";
import { CALL_API } from "common/API";
import { SearchOutlined } from "@ant-design/icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Table from "atoms/Table";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import moment from "moment";

const AppraisalHistory = ({
  displayLocationLoader,
  setPageView,
  history,
  AllDepartmentList,
  appraisal,
  fetchDataBYId,
  myappraisal,
  myAppraisal,
  setViewResponseData,
  searchTerm,
  setSearchTerm,
}) => {
  const textcolor = { color: "#2d53da" };

  // const [searchTerm, setSearchTerm] = useState('');

  const [viewResponse, setViewResponse] = useState(false);
  const [responseById, setResponseById] = useState(false);
  console.log("responseById2-->", responseById);
  const [responseLoader, setResponseloader] = useState(false);
  const [appraisalName, setAppraisalName] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const fetchData = async (id) => {
    setResponseloader(true);
    try {
      const res = await CALL_API(
        `appraisalform/completedappraisal/${id}`,
        "get"
      );
      setResponseloader(false);
      setResponseById(res?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      const filterData = responseById?.filter((item) =>
        item?.userName?.toLowerCase()?.includes(searchTerm.toLowerCase())
      );
      setFilterData(filterData);
    } else {
      setFilterData(responseById);
    }
  }, [searchTerm, responseById]);

  // const fetchData = async () => {
  //   setResponseloader(true);
  //   try {
  //     const res = await CALL_API(`appraisalform/completedappraisal`, "get");
  //     setResponseloader(false);
  //     setResponseById(res?.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleFilterAppraisals = (id, assignId) => {
    fetchData(id);
  };

  const findDepartmentName = (departmentId) => {
    const departments = AllDepartmentList.filter((dep) =>
      departmentId?.includes(dep?.department_id)
    );
    const departmentNames = departments.map((item) => item.department_name);
    return departmentNames;
  };

  const findAppraisalName = (appraisalFormId) => {
    const department = appraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return department ? department.title : "N/A";
  };

  const findAppraisalTime = (appraisalFormId) => {
    const department = myAppraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return department
      ? department.appraisalForm + " - " + department.appraisalTo
      : "N/A";
  };

  const appraisalHistorycolumns = [
    {
      title: "Form Title",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      render: (appraisalFormId) => findAppraisalName(appraisalFormId),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
    },
    {
      title: "Department",
      dataIndex: "departmentId",
      key: "departmentId",
      width: "10%",
      render: (departmentId) => {
        const departmentNames = findDepartmentName(departmentId);
        return departmentNames.length ? departmentNames.join(", ") : "N/A";
      },
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <>
            <ButtonOutlined
              onClick={() => {
                setViewResponse(true);

                handleFilterAppraisals(record.appraisalFormId, record.assignId);
                setAppraisalName(record.appraisalFormId);
              }}
              type="link"
              className="fw-medium"
              style={textcolor}
            >
              View form
            </ButtonOutlined>
          </>
        );
      },
    },
  ];

  const responseColumn = [
    {
      title: "Employee name",
      dataIndex: "userName",
      key: "userName",
      width: "10%",
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: "10%",
      render: (updatedAt) => (
        <>{moment(updatedAt.slice(0, 10)).format("DD-MM-YYYY")}</>
      ),
    },
    {
      title: "Department",
      dataIndex: "departmentId",
      key: "departmentId",
      width: "10%",
      render: (departmentId) => findDepartmentName(departmentId),
    },
    {
      title: "Hike percentage",
      dataIndex: "hikePercentage",
      key: "hikePercentage",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <>
            <ButtonOutlined
              onClick={() => {
                setPageView("historyResponse");
                setViewResponseData(record);
              }}
              type="link"
              className="fw-medium"
            >
              View form response
            </ButtonOutlined>
          </>
        );
      },
    },
  ];

  const myappraisalHistorycolumns = [
    {
      title: "Title",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      render: (appraisalFormId) => findAppraisalName(appraisalFormId),
    },
    {
      title: "Time Period",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      render: (appraisalFormId) => findAppraisalTime(appraisalFormId),
    },
    {
      title: "Hike percentage",
      dataIndex: "hikePercentage",
      key: "hikePercentage",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <>
            <ButtonOutlined
              onClick={() => {
                setPageView("historyResponse");
                setViewResponseData(record);
              }}
              type="link"
              className="fw-medium"
              style={textcolor}
            >
              View form
            </ButtonOutlined>
          </>
        );
      },
    },
  ];

  const filteredDataSource = (myappraisal ? history : history)?.filter(
    (item) =>
      findAppraisalName(item?.appraisalFormId)
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase()) &&
      findAppraisalName(item?.appraisalFormId) !== "N/A"
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <Row>
        <Col sm={12} md={4} lg={4}>
          <Input
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            suffix={<SearchOutlined />}
          />
        </Col>
        <Col sm={12} md={8} lg={8}></Col>
      </Row>
      {myappraisal ? (
        <Table
          className="mb-5"
          dataSource={filteredDataSource}
          columns={myappraisalHistorycolumns}
          rowKey="department_id"
          pagination={false}
        />
      ) : (
        <>
          {viewResponse ? (
            <>
              <label className="bg-transparent border-0 mt-2 text-left styled-cursor">
                <span onClick={() => setViewResponse(false)}>Back</span>
                <span className="px-2">
                  <ArrowForwardIosIcon style={{ fontSize: "12px" }} />{" "}
                </span>
                <b style={{ color: "black" }}>
                  {findAppraisalName(appraisalName)}
                </b>
              </label>
              <Spin spinning={responseLoader}>
                <Table
                  className="mb-5"
                  dataSource={filterData}
                  columns={responseColumn}
                  rowKey="department_id"
                  pagination={false}
                />
              </Spin>
            </>
          ) : (
            <Spin spinning={displayLocationLoader}>
              <Table
                className="mb-5"
                dataSource={filteredDataSource}
                columns={appraisalHistorycolumns}
                rowKey="department_id"
                pagination={false}
              />
            </Spin>
          )}
        </>
      )}
    </div>
  );
};

export default AppraisalHistory;
