import styled from "@emotion/styled";
import { SearchOutlined } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Typography,
  message,
} from "antd";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import COLORS from "common/Colors";
import { EXPENSE_STATUS, STATUS_CODE } from "common/Constants";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import Deleted from "../../../../assets/Deleted.svg";
import Filter from "../../../../assets/Filter.svg";
import ViewBusinessTrip from "./ViewBusinessTrip";
import { width } from "@mui/system";
import { MenuList } from "@mui/material";

const { TextArea } = Input;

const { Text } = Typography;

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-cell:nth-last-child(2) {
    // min-width: 200px;
    white-space: normal;
  }
  .non-white {
    background: #f9f9f9;
  }

  .ellipsis {
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledViewMenu = styled.div`
  width: 150px;
  cursor: pointer;
`;
const StyledDropdownMenu = styled(Dropdown)`
  width: 150px;
  cursor: pointer;
`;

const StyledWrap = styled.div`
  .title {
    color: #636980;
    font-size: 15px;
    margin-bottom: 5px;
  }
`;
const MyBusinessTrip = ({
  ClientId,
  userInfo,
  updateSuccessModal,
  Menulist,
  searchString,
  setSearchString,
}) => {
  const hasEditPermission = Menulist?.find(
    (item) => item?.key === "mytravelexpense" && item?.permission.edit
  );
  const hasDeletePermission = Menulist?.find(
    (item) => item?.key === "mytravelexpense" && item?.permission.delete
  );

  const hasCreatePermission = Menulist?.find(
    (item) => item?.key === "mytravelexpense" && item?.permission.create
  );
  const showView = hasEditPermission || hasDeletePermission;

  const columns = [
    {
      title: "Travel ID",
      dataIndex: "travelId",
      key: "travelId",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      ellipsis: true,
      render: (text) => (
        <Text className="ellipsis" ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
      width: "10%",
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => (
        <StyledDropdownMenu
          onClick={() => handleDropdownClick()}
          onVisibleChange={(visible) => handleDropdownVisibleChange(visible)}
          overlay={
            <StyledViewMenu>
              <Menu>
                {record.status !== "Waiting" || !showView ? (
                  <>
                    {Menulist?.find(
                      (item) =>
                        item?.key === "MyFolder" &&
                        item?.subMenu?.find(
                          (subItem) =>
                            subItem?.key === "mytravelexpense" &&
                            subItem?.permission.view
                        )
                    ) && (
                      <Menu.Item
                        key="2"
                        onClick={() => {
                          setSelectedItem(record);
                          setView(true);
                        }}
                      >
                        View
                      </Menu.Item>
                    )}
                  </>
                ) : (
                  <>
                    {hasEditPermission && (
                      <Menu.Item
                        key="2"
                        onClick={() => {
                          setSelectedItem(record);
                          setEdit(true);
                          showModal();
                        }}
                      >
                        Edit
                      </Menu.Item>
                    )}
                    {hasDeletePermission && (
                      <Menu.Item
                        key="4"
                        onClick={() => {
                          setDeleteId(record?.id);
                          setDeleteData(true);
                        }}
                      >
                        Delete the request
                      </Menu.Item>
                    )}
                  </>
                )}
              </Menu>
            </StyledViewMenu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <MoreHorizIcon />
          </span>
        </StyledDropdownMenu>
      ),
    },
  ];

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "16px" };
  const containerRef = useRef(null);

  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [view, setView] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [edit, setEdit] = useState(false);
  const [showLoader, toggleLoader] = useState(false);
  const [traveldata, setTraveldata] = useState([]);
  const [isClickWithinDropdownMenu, setIsClickWithinDropdownMenu] =
    useState(false);

  const handleDropdownClick = () => {
    setIsClickWithinDropdownMenu(true);
  };

  const handleDropdownVisibleChange = (visible) => {
    setIsClickWithinDropdownMenu(visible);
  };

  const [value, setValue] = useState({
    reason: "",
    days: 0,
    departure: null,
    arrival: null,
    destination: "",
  });

  const [errors, setErrors] = useState({
    reason: "",
    departure: "",
    arrival: "",
    destination: "",
  });

  const [filteredData, setFilteredData] = useState(traveldata);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    lastEvaluatedKey: null,
  });

  useEffect(() => {
    if (searchString.length) {
      const filtered = traveldata.filter((item) =>
        String(item?.travelId)
          .toLowerCase()
          .includes(String(searchString).toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(traveldata);
    }
    // setFilteredData(traveldata);
  }, [traveldata, searchString]);

  const handleSearchChange = (e) => {
    const searchString = e.target.value;
    setSearchString(searchString);
  };

  const handleSelectChange = (selectedValue) => {
    if (selectedValue !== "All") {
      const filtered =
        traveldata.filter((item) => item.status === selectedValue) || [];
      setFilteredData(filtered);
    } else {
      setFilteredData(traveldata);
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    handleSubmit();
  };

  const handleEdit = () => {
    handleSubmitedit();
  };

  const handleCancel = () => {
    setOpen(false);
    setValue({
      reason: "",
      days: 0,
      departure: null,
      arrival: null,
      destination: "",
    });
    setErrors({
      reason: "",
      departure: "",
      arrival: "",
      destination: "",
    });
    toggleLoader(false);
    setSelectedItem([]);
    setEdit(false);
  };

  const onChangeValue = (e) => {
    const { name, value: inputValue } = e.target;
    setValue((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const onChangeEdit = (e) => {
    const { name, value: inputValue } = e.target;
    setSelectedItem((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  const onChange = (date, dateString, field) => {
    setValue((prevState) => ({
      ...prevState,
      [field]: date,
    }));
    if (errors[field]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }
  };

  const onChangeEditdate = (date, dateString, field) => {
    setSelectedItem((prevState) => ({
      ...prevState,
      [field]: date,
    }));
  };

  const disabledDateBefore = (current, targetDate) => {
    return current && current < moment(targetDate).startOf("day");
  };

  const disabledDateAfter = (current, targetDate) => {
    return current && current > moment(targetDate).endOf("day");
  };

  const updateDays = (departure, arrival) => {
    if (departure && arrival) {
      const daysDifference = moment(arrival).diff(departure, "days");

      let days;
      if (daysDifference === 0) {
        days = 1;
      } else if (daysDifference === 1) {
        days = 2;
      } else if (daysDifference > 1) {
        days = daysDifference + 1;
      } else {
        days = 0;
      }

      if (edit) {
        setSelectedItem((prevState) => ({
          ...prevState,
          days: days,
        }));
      } else {
        setValue((prevState) => ({
          ...prevState,
          days: days,
        }));
      }
    }
  };

  const handleRowClick = (record) => {
    if (!isClickWithinDropdownMenu) {
      setSelectedItem(record);
      setView(true);
    }
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors = {};
    if (value.reason.trim() === "") {
      newErrors.reason = "Reason is required";
      isValid = false;
    }
    if (value.departure === null) {
      newErrors.departure = "Departure date is required";
      isValid = false;
    }
    if (value.arrival === null) {
      newErrors.arrival = "Arrival date is required";
      isValid = false;
    }
    if (value.destination.trim() === "") {
      newErrors.destination = "Destination is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      onSubmit(value);
    }
  };

  const onSubmit = async () => {
    toggleLoader(true);
    const { code } = await CALL_API(`travel-expense/businesstrip`, "post", {
      reason: value.reason,
      days: value.days,
      departure: value.departure,
      arrival: value.arrival,
      destination: value.destination,
      clientId: ClientId,
      userId: userInfo.userid,
    });
    if (code === STATUS_CODE.SUCCESS) {
      toggleLoader(false);
      message.success("Successfully Added");
      handleCancel();
      setTraveldata([]);
      getData();
    } else {
      toggleLoader(false);
      message.error("Error");
    }
  };

  const handleSubmitedit = async () => {
    toggleLoader(true);
    const { code } = await CALL_API(`travel-expense/businesstrip`, "patch", {
      reason: selectedItem.reason,
      days: selectedItem.days,
      departure: selectedItem.departure,
      arrival: selectedItem.arrival,
      destination: selectedItem.destination,
      id: selectedItem.id,
      clientId: ClientId,
      userId: userInfo.userid,
    });
    if (code === STATUS_CODE.SUCCESS) {
      toggleLoader(false);
      updateSuccessModal({
        visibility: true,
        message: "Changes saved",
      });
      handleCancel();
      setTraveldata([]);
      getData();
    } else {
      toggleLoader(false);
      message.error("Error");
    }
  };

  const handleDelete = async () => {
    toggleLoader(true);
    const { code } = await CALL_API(
      `travel-expense/businesstrip?&clientId=${ClientId}&id=${deleteId}`,
      "delete"
    );
    if (code === STATUS_CODE.SUCCESS) {
      toggleLoader(false);
      updateSuccessModal({
        visibility: true,
        message: "Deleted",
        Sucessicon: (
          <div className="d-flex justify-content-center align-itemw-center">
            {" "}
            <img className="d-block" src={Deleted} alt="deleted" />
          </div>
        ),
      });
      handleCancel();
      setDeleteData(false);
      setTraveldata([]);
      getData();
    } else {
      toggleLoader(false);
      message.error("Error");
    }
  };

  const getData = async () => {
    setDisplayTableLoader(true);
    const { code, data, LastEvaluatedKey } = await CALL_API(
      `travel-expense/businesstrip?LastEvaluatedKey=${
        pagination.lastEvaluatedKey || 0
      }&clientId=${ClientId}&userId=${userInfo.userid}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      setTraveldata(data);
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: LastEvaluatedKey,
      }));
      setDisplayTableLoader(false);
    } else {
      toggleLoader(false);
      message.error("Error");
      setDisplayTableLoader(false);
    }
  };

  const getMoreData = async () => {
    setDisplayTableLoader(true);
    const { code, data, LastEvaluatedKey } = await CALL_API(
      `travel-expense/businesstrip?LastEvaluatedKey=0&clientId=${ClientId}&userId=${userInfo.userid}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      setTraveldata([...traveldata, ...data]);
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: LastEvaluatedKey,
      }));
      setDisplayTableLoader(false);
    } else {
      toggleLoader(false);
      message.error("Error");
      setDisplayTableLoader(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextPage = () => {
    if (pagination.lastEvaluatedKey !== null) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: prevPagination.current + 1,
      }));
    }
  };

  useEffect(() => {
    if (pagination.lastEvaluatedKey !== null) {
      getMoreData();
    }
  }, [pagination.current]);

  return (
    <div>
      <Row className="page-title">
        <Col
          xs={24}
          sm={8}
          md={5}
          className="page-title-head pe-3"
          ref={containerRef}
        >
          <Input
            style={styledInput}
            prefix={<SearchOutlined style={styledIcon} />}
            placeholder="Search "
            onChange={handleSearchChange}
            value={searchString}
            className="me-3"
          />
        </Col>
        <Col
          xs={24}
          sm={8}
          md={5}
          className="page-title-head"
          ref={containerRef}
        >
          <Select
            placeholder={
              <>
                <img src={Filter} alt="filter" />{" "}
                <span className="Status-placeholder">Status</span>
              </>
            }
            options={EXPENSE_STATUS}
            getPopupContainer={() => containerRef.current}
            className="w-100"
            defaultValue={"All"}
            onChange={handleSelectChange}
          />
        </Col>
        <Col xs={24} sm={24} md={14} className="align-right">
          {hasCreatePermission && (
            <div>
              <ButtonFilled onClick={showModal}>Request to Travel</ButtonFilled>
            </div>
          )}
        </Col>
      </Row>

      <Row className="page-title">
        <Col
          xs={24}
          sm={19}
          md={19}
          className="page-title-head"
          ref={containerRef}
        ></Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <div style={{ overflowX: "auto" }}>
          <StyledTable
            dataSource={filteredData}
            columns={columns}
            rowKey="user_leave_id"
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
          {pagination.lastEvaluatedKey !== null && (
            <div className="d-flex justify-content-center align-items-center my-3">
              <Button
                type="primary"
                onClick={handleNextPage}
                disabled={displayTableLoader}
              >
                Load More
              </Button>
            </div>
          )}
        </div>
      </Spin>

      <Modal
        open={open}
        closable={false}
        footer={[
          <ButtonOutlined key="back" onClick={handleCancel}>
            Cancel
          </ButtonOutlined>,
          <ButtonFilled
            key="submit"
            type="primary"
            onClick={edit ? handleEdit : handleOk}
            confirmLoading={showLoader}
            disabled={showLoader}
          >
            {edit ? "Save Changes" : "Submit"}
          </ButtonFilled>,
        ]}
      >
        <StyledWrap>
          <Typography.Title className="p-2" level={4}>
            {edit ? "Editing the Request" : " Requesting to Travel"}
          </Typography.Title>

          {edit ? (
            <Typography.Title className="pb-2 px-2" level={4}>
              Travel ID: {selectedItem?.id}
            </Typography.Title>
          ) : (
            <></>
          )}
          <Row>
            <Col xs={24} sm={24} md={24} className="p-2">
              <Typography className="title">
                Reason for Travel<span className="error">*</span>
              </Typography>
              <TextArea
                value={edit ? selectedItem?.reason : value.reason}
                name="reason"
                onChange={(e) => (edit ? onChangeEdit(e) : onChangeValue(e))}
                autoSize={{ minRows: 3 }}
              />
              {errors.reason && <span className="error">{errors.reason}</span>}
            </Col>
            <Col xs={24} sm={24} md={12} className="p-2">
              <Typography className="title">
                Date of Departure<span className="error">*</span>
              </Typography>
              <DatePicker
                name="departure"
                className="w-100"
                format="DD MMM YYYY"
                value={edit ? moment(selectedItem?.departure) : value.departure}
                onChange={(date, dateString) => {
                  if (edit) {
                    onChangeEditdate(date, dateString, "departure");
                    updateDays(date, moment(selectedItem?.arrival));
                  } else {
                    onChange(date, dateString, "departure");
                    updateDays(date, value.arrival);
                  }
                }}
                disabledDate={(current) =>
                  disabledDateAfter(
                    current,
                    edit ? moment(selectedItem?.arrival) : value.arrival
                  )
                }
              />
              {errors.departure && (
                <span className="error">{errors.departure}</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} className="p-2">
              <Typography className="title">
                Return date<span className="error">*</span>
              </Typography>
              <DatePicker
                name="arrival"
                format="DD MMM YYYY"
                className="w-100"
                value={edit ? moment(selectedItem?.arrival) : value.arrival}
                onChange={(date, dateString) => {
                  if (edit) {
                    onChangeEditdate(date, dateString, "arrival");
                    updateDays(moment(selectedItem?.departure), date);
                  } else {
                    onChange(date, dateString, "arrival");
                    updateDays(value.departure, date);
                  }
                }}
                disabledDate={(current) =>
                  disabledDateBefore(
                    current,
                    edit ? moment(selectedItem?.departure) : value.departure
                  )
                }
              />
              {errors.arrival && (
                <span className="error">{errors.arrival}</span>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} className="p-2">
              <Typography className="title">No of Days</Typography>
              <Typography>{edit ? selectedItem?.days : value.days}</Typography>
            </Col>
            <Col xs={24} sm={24} md={12} className="p-2">
              <Typography className="title">
                Travel Destination<span className="error">*</span>
              </Typography>
              <Input
                onChange={(e) => (edit ? onChangeEdit(e) : onChangeValue(e))}
                name="destination"
                value={edit ? selectedItem?.destination : value.destination}
                maxLength={50}
              />
              {errors.destination && (
                <span className="error">{errors.destination}</span>
              )}
            </Col>
          </Row>
        </StyledWrap>
        <style>
          {`
            .ant-btn-primary {
              border-radius: 5px;
              color: #fff;
            }
            .ant-btn-default {
              border: 1px solid #1890ff !important;
              color: #1890ff;
              border-radius: 5px;
            } 
          `}
        </style>
      </Modal>

      <Modal
        open={deleteData}
        onOk={() => handleDelete()}
        confirmLoading={showLoader}
        okText={"Yes"}
        cancelText={"No"}
        onCancel={() => setDeleteData(false)}
        closable={false}
      >
        <Typography.Title className="p-2" level={5}>
          Are you sure want to delete this travel request?
        </Typography.Title>
      </Modal>

      <ViewBusinessTrip
        view={view}
        setView={setView}
        selectedItem={selectedItem}
        setEdit={setEdit}
        setDeleteId={setDeleteId}
        setDeleteData={setDeleteData}
        showModal={showModal}
        hrView={false}
        hasDeletePermission={hasDeletePermission}
        hasEditPermission={hasEditPermission}
      />
    </div>
  );
};

export default MyBusinessTrip;
