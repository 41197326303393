import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import PricingWrap from "./style";
import tymePlusHrLogo from "assets/tymeplushrmobileheader.svg";
import { Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getClientid, getUserInfo } from "redux/selectors";
import { CALL_API } from "common/API";
import moment from "moment";
import { setCurrentPlan } from "redux/actions";
import { AtmCard } from "pages/Payment";
import Button from "atoms/Button";

const plans = [
  {
    tier: "Standard",
    users: `Upto 100 Users`,
    userCount: 100,
    price: `$10`,
    amount: "10",
    month: `per user, per month`,
    button: `Get Started`,
    redirect: ``,
    loading: false,
    active: false,
  },
  {
    tier: "Premium",
    users: `Upto 200 Users`,
    userCount: 200,
    price: `$14`,
    amount: "14",
    month: `per user, per month`,
    button: `Get Started`,
    redirect: ``,
    loading: false,
    active: false,
  },
  {
    tier: "Enterprise",
    users: `Over 200 Users`,
    userCount: 200,
    price: `Talk to Us!`,
    amount: "0",
    month: `Will discuss`,
    button: `Contact Us`,
    redirect: `https://www.dev.website.tymeplushr.com/contactus`, //TODO: add the contact us page link
    loading: false,
    active: false,
  },
];

export const Pricing = ({
  setClientSecret,
  setPlan,
  currentPlan,
  setShowSubscribeFormModal,
}) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statePlans, setStatePlans] = useState(plans);
  const [atmCards, setAtmCards] = useState([]);
  const [cardViaPlan, setCardViaPlan] = useState("");
  const [subscribeLoading, setSubscribeLoading] = useState(false);

  const currentPlanType = currentPlan
    ? currentPlan?.planType?.toLowerCase()
    : "";

  const currentPlanStatus = currentPlan ? currentPlan?.subscriptionStatus : "";

  useEffect(() => {
    if (
      currentPlanType === "Free" ||
      currentPlanType === "trial" ||
      currentPlanType === "trail" ||
      currentPlanType === "" ||
      currentPlanType === undefined
    )
      return;
    setStatePlans((prev) =>
      prev.map((p) => {
        if (
          p.tier.toLowerCase() === "premium" ||
          p.tier.toLowerCase() === "standard"
        ) {
          p.button = "Switch Plan";
        }
        if (p.tier.toLowerCase() === currentPlanType) {
          p.active = true;
          p.button = currentPlanStatus === "active" ? "Active" : "Renew";
        }
        return p;
      })
    );
  }, [currentPlanType, currentPlanStatus]);

  const userInfo = useSelector((state) => getUserInfo(state));
  const clientId = useSelector((state) => getClientid(state));

  const initiateSubscription = async (plan) => {
    if (plan.button === "Active") {
      notification.info({
        message: "Subscription is already active",
        description: "You are already subscribed to this plan",
      });
      return;
    }

    if (plan.tier === "Enterprise") {
      window.location.href = plan.redirect;
      return;
    }

    setStatePlans(
      statePlans.map((p) => {
        if (p.tier === plan.tier) {
          p.loading = true;
        }
        return p;
      })
    );

    let isPlanChange = plan.button === "Switch Plan" || plan.button === "Renew";

    const API_URL =
      plan.button === "Renew" || plan.button === "Switch Plan"
        ? "plan/create"
        : "plan/create";

    const response = await CALL_API(API_URL, "post", {
      clientId: clientId,
      emailId: userInfo.userid,
      userName: userInfo.name,
      userCount: plan.userCount,
      planType: plan.tier ? plan.tier.toLowerCase() : "",
      amount: plan.amount,
    });

    if (Array.isArray(response)) {
      setStatePlans(
        statePlans.map((p) => {
          p.active = false;

          if (p.tier === plan.tier) {
            p.loading = false;
            p.active = true;
          }
          return p;
        })
      );

      setAtmCards(response);
      setCardViaPlan(plan);
      return;
    }

    if (isPlanChange) {
      setStatePlans(
        statePlans.map((p) => {
          p.active = false;

          if (p.tier === plan.tier) {
            p.loading = false;
            p.active = true;
          }
          return p;
        })
      );
      const planStartResponse = await CALL_API("plan/start", "post", {
        // subscriptionId,
        clientId,
        emailId: userInfo.userid,
        userName: userInfo.name,
        planType: plan.tier ? plan.tier.toLowerCase() : "",
        amount: plan.amount,
        date: moment().format("MMMM Do YYYY, h:mm:ss a"),
        type: "change",
      });

      if (planStartResponse) {
        notification.success({
          message: "Subscription successful",
          description: planStartResponse,
        });
        setShowSubscribeFormModal(false);

        dispatch(
          setCurrentPlan({
            planType: plan.tier.toLowerCase(),
          })
        );
      }

      return;
    } else {
      setStatePlans(
        statePlans.map((p) => {
          if (p.tier === plan.tier) {
            p.loading = false;
          }
          return p;
        })
      );
    }

    setClientSecret(response);
    setPlan(plan);
  };

  const getCardDetails = async () => {
    try {
      const response = await CALL_API(
        `card/details?clientId=${clientId}`,
        "get",
        {}
      );

      let cards = response;

      setAtmCards(cards || []);
      console.log("getCardDetails  response----->", response);
    } catch (error) {
      notification.error({
        message: "Failed to get Card Details",
        description: error,
      });
      console.log("getCardDetails  error----->", error);
    }
  };

  const handleSubscribe = async () => {
    try {
      const currentActiveCard = atmCards.find(
        (card) => card.status === "active"
      );

      if (!currentActiveCard) {
        notification.error({
          message: "Failed to Subscribe",
          description: "Please select an ATM card to subscribe",
        });
        return;
      }

      setSubscribeLoading(true);

      const subscribeResponse = await CALL_API("plan/start", "post", {
        // subscriptionId,
        clientId,
        emailId: userInfo.userid,
        userName: userInfo.name,
        planType: cardViaPlan.tier ? cardViaPlan.tier.toLowerCase() : "",
        amount: cardViaPlan.amount,
        date: moment().format("MMMM Do YYYY, h:mm:ss a"),
        type: "create",
        payment_method: currentActiveCard,
      });
      console.log(
        "handleSubscribe  subscribeResponse----->",
        subscribeResponse
      );

      if (subscribeResponse) {
        notification.success({
          message: "Subscription successful",
          description: subscribeResponse,
        });
        setShowSubscribeFormModal(false);
      }
      setSubscribeLoading(false);

      window.location.reload();
    } catch (error) {
      setSubscribeLoading(false);
      notification.error({
        message: "Failed to Subscribe",
        description: error,
      });
      console.log("handleSubscribe  error----->", error);
    }
  };

  return (
    <>
      <PricingWrap>
        <section className="pricing">
          <Container
            fluid
            style={{
              color: "#fff",
              border: "none",
            }}
            className="mb-4"
          >
            <Row>
              <Col>
                <div className="text-center">
                  <img src={tymePlusHrLogo} alt="Logo" />
                </div>
                <h2 className="text-center my-3">
                  {currentPlan?.notification?.title}
                </h2>

                <p className="context">{currentPlan?.notification?.message}</p>

                {currentPlan?.notification?.renewalDeadline && (
                  <h4 className="renewalDeadline">
                    {currentPlan?.notification?.renewalDeadline}
                  </h4>
                )}

                {currentPlan?.notification?.deadline && (
                  <p className="context">
                    {currentPlan?.notification?.deadline}
                  </p>
                )}

                {atmCards.length > 0 ? (
                  <div>
                    <h3 className="text-center my-3">Choose an ATM Card</h3>

                    <Container fluid>
                      <div className="atm-cards">
                        {atmCards.map((card, idx) => (
                          <AtmCard
                            key={idx}
                            card={card}
                            getCardDetails={getCardDetails}
                          />
                        ))}
                      </div>

                      <div className="text-end mt-2">
                        <Button
                          type="primary"
                          onClick={() => {
                            handleSubscribe();
                          }}
                          loading={subscribeLoading}
                        >
                          Subscribe
                        </Button>
                      </div>
                    </Container>
                  </div>
                ) : (
                  <div className="pricing-contain text-center">
                    {statePlans.map((plan, idx) => (
                      <div key={idx}>
                        <Card className={`pricing-card`}>
                          <Card.Body className="py-4">
                            <Card.Title className="card-title pt-4 pb-2">
                              {plan.tier}
                            </Card.Title>
                            <Card.Subtitle className="mb-4 text-muted card-sub">
                              {plan.users}
                            </Card.Subtitle>
                            <Card.Text className="card-price mb-2">
                              {plan.price}
                            </Card.Text>
                            <Card.Subtitle className="mb-2 text-muted card-month">
                              {plan.month}
                            </Card.Subtitle>
                            <Spin spinning={plan.loading}>
                              <Button
                                className={`my-4 mt-3 custom-button ${
                                  plan.active ? "active" : ""
                                }`}
                                size="large"
                                onClick={() => {
                                  initiateSubscription(plan);
                                }}
                              >
                                {plan.button}
                              </Button>{" "}
                            </Spin>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </PricingWrap>
    </>
  );
};

export default Pricing;
