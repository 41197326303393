import { Row, Col } from "react-bootstrap";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Button, Input, Spin } from "antd";
import { Link } from "react-router-dom";
import UISearch from "../reusableComponent/UISearch/SearchInput";
import { message } from "antd";
import { CALL_API } from "common/API";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import Table from "atoms/Table";
import AlertModal from "organisms/AlertModal";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
    border: none;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 5px 10px;
    height: 0px !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #f4f4f4;
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;

const CurrentAppraisal = ({
  appraisal,
  displayLocationLoader,
  setPageView,
  fetchDataBYId,
  setAppraisal,
  myappraisal,
  fetchResponse,
  myappraisalData,
  setSelectedItem,
  fetchALlData,
  fetchData,
  Menulist,
  searchTerm,
  setSearchTerm,
}) => {
  const btnStyle = { borderRadius: "5px", margin: "3px 0" };
  // const [searchTerm, setSearchTerm] = useState("");
  const [deleteId, setdeleteId] = useState("");
  const [deleteIndex, setDeleteIndex] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const btnSearch = {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    border: "none",
    margin: "0 5px",
    minWidth: "60%",
  };

  const deleteModal = (id, index) => {
    setdeleteId(id);
    setDeleteIndex(index);
    setDeleteOpen(true);
  };

  const deleteAppraisal = async (id, index) => {
    setLoading(true);
    let obj = {
      deleteStatus: true,
    };
    const { code } = await CALL_API(`appraisalform/${deleteId}`, "patch", obj);

    if (code === "SUCCESS") {
      const updatedData = [...appraisal];
      updatedData.splice(deleteIndex, 1);
      setAppraisal(updatedData);
      message.success("Deleted successfully");
    }
    setLoading(false);
    setDeleteOpen(false);
  };

  const appraisalcolumns = [
    {
      title: "Form Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
      onCell: (record) => ({
        onClick: () => {
          setPageView("appraisalView");
          fetchDataBYId(record.appraisalFormId);
        },
      }),
      className: "cursor-pointer",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "left",
      render: (location_id, record, index) => {
        return (
          <>
            <ButtonOutlined
              className="m-0 mx-2"
              onClick={() => {
                setPageView("response");
                fetchResponse(record.appraisalFormId);
                fetchDataBYId(record.appraisalFormId);
              }}
            >
              View response
            </ButtonOutlined>
            {Menulist?.find(
              (item) =>
                item?.key === "HRResources" &&
                item?.subMenu?.find(
                  (subItem) =>
                    subItem?.key === "appraisal" && subItem?.permission.delete
                )
            ) && (
              <ButtonOutlined
                className="m-0"
                onClick={() => deleteModal(record.appraisalFormId, index)}
              >
                Delete
              </ButtonOutlined>
            )}
          </>
        );
      },
    },
  ];

  const findAppraisalName = (appraisalFormId) => {
    const data = appraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    return data ? data.title : "N/A";
  };

  const findAppraisal = (appraisalFormId) => {
    const data = appraisal.find(
      (dep) => dep.appraisalFormId === appraisalFormId
    );
    setSelectedItem(data);
  };

  const myappraisalcolumns = [
    {
      title: "Title",
      dataIndex: "appraisalFormId",
      key: "appraisalFormId",
      width: "10%",
      className: "cursor-pointer",
      render: (appraisalFormId) => findAppraisalName(appraisalFormId),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      className: "cursor-pointer",
      render: (record) => {
        return <p>In complete</p>;
      },
    },
    {
      title: "Due date",
      dataIndex: "appraisalTo",
      key: "appraisalTo",
      width: "10%",
      className: "cursor-pointer",
    },
    {
      title: "Action",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "left",
      render: (location_id, record, index) => {
        return (
          <>
            <ButtonOutlined
              onClick={() => {
                setPageView("Attend");
                findAppraisal(record?.appraisalFormId);
              }}
              type="link"
              className="fw-bold text-primary"
            >
              Attend
            </ButtonOutlined>
          </>
        );
      },
    },
  ];

  const filteredDataSource = (
    myappraisal ? myappraisalData : appraisal
  )?.filter(
    (item) =>
      findAppraisalName(item?.appraisalFormId)
        ?.toLowerCase()
        .includes(searchTerm?.toLowerCase()) &&
      findAppraisalName(item?.appraisalFormId) !== "N/A"
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <Row>
        <Col sm={12} md={4} lg={4}>
          <Input
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            suffix={<SearchOutlined />}
          />
        </Col>
        {!myappraisal && (
          <Col sm={12} md={8} lg={8}>
            <div className="text-end">
              {Menulist?.find(
                (item) =>
                  item?.key === "HRResources" &&
                  item?.subMenu?.find(
                    (subItem) =>
                      subItem?.key === "appraisal" && subItem?.permission.create
                  )
              ) && (
                <Link to="/add-appraisal">
                  <Button style={btnStyle} type="primary">
                    + Add new form
                  </Button>
                </Link>
              )}
            </div>
          </Col>
        )}
      </Row>
      {myappraisal ? (
        <Table
          className="mb-5"
          dataSource={filteredDataSource}
          columns={myappraisalcolumns}
          rowKey="department_id"
          pagination={false}
        />
      ) : (
        <Spin spinning={displayLocationLoader}>
          <Table
            className="mb-5"
            dataSource={filteredDataSource}
            columns={appraisalcolumns}
            rowKey="department_id"
            pagination={false}
          />
        </Spin>
      )}

      <AlertModal
        isModalOpen={deleteOpen}
        handleCancel={() => setDeleteOpen(false)}
        handleOk={deleteAppraisal}
        name={"Are you sure you want to delete this Appraisal Form?"}
        loading={loading}
      />
    </div>
  );
};

export default CurrentAppraisal;
