import React from 'react'
import { ButtonFilled, ButtonOutlined } from 'reusableComponent/UIButtons'
import { Modal } from "antd";
import styled from "@emotion/styled";
import COLORS from 'common/Colors';

const StyledModal = styled(Modal)`
  border-radius: 10px;
  padding-top: 5%;
  p{
    font-size: 16px;
    color: ${COLORS.TEXT.PRIMARY};
    font-weight: 600;
  }
  small{
    font-size: 13px;
    color: ${COLORS.TEXT.PRIMARY};
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-footer {
    display: none;
  }
  .stylebtn{
    min-width: 130px;
  }
`;

function AlertModal({ isModalOpen, handleCancel, handleOk, loading, name, note }) {

  return (
    <div>
      <StyledModal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}

      >
        <div className='mt-3 text-center'>
          <p >{name}</p>
          
          <div>
            <ButtonOutlined key="back" onClick={handleCancel} className='stylebtn'>
              No
            </ButtonOutlined>,
            <ButtonFilled
              key="submit"
              loading={loading}
              onClick={handleOk}
              className='stylebtn'
            >
              Yes
            </ButtonFilled>
          </div>
          <br/>
          {note && <small>{note}</small>}

        </div>

      </StyledModal>
    </div>
  )
}

export default AlertModal