import React, { useState } from "react";
import styled from "@emotion/styled";

// Lodash

// Redux
import { connect } from "react-redux";
import {
  getUserName,
  getTime,
  getAMPM,
  getLongDay,
  getUserRoleId,
} from "redux/selectors";

// Common
import COLORS from "common/Colors";

// Components
import LeaveForm from "organisms/LeaveForm";
import { DEVICE } from "common/Device";

const StyledTime = styled.span`
  font-size: 1.2rem;
  color: ${COLORS.PRIMARY};
  font-weight: bold;
`;

const StyledAMPM = styled.span`
  color: ${COLORS.PRIMARY};
  font-size: 12px;
  padding-left: 10px;
  font-weight: 600;
  white-space: nowrap;
  @media ${DEVICE.tablet} {
    font-size: 10px;
    margin-left: -10px;
  }
  @media ${DEVICE.mobileL} {
    font-size: 10px;
  }
`;

const StyledDate = styled.div`
  color: ${COLORS.PRIMARY};
  font-size: 12px;
  margin-top: -45px;
  white-space: nowrap;
  @media ${DEVICE.tablet} {
    font-size: 8px;
    margin-left: -10px;
  }
`;

function DashboardTop({ userName, systemTime, ampm, longDay, userRoleId }) {
  const [displayLeaveModal, toggleLeaveModal] = useState(false);
  return (
    <>
      <div className="ps-2 mt-3 text-center pt-1">
        <div>
          <StyledTime>{systemTime}</StyledTime>
          <StyledAMPM>{ampm}</StyledAMPM>
        </div>
        <StyledDate>{longDay}</StyledDate>
      </div>
      <LeaveForm
        isModalVisible={displayLeaveModal}
        closeModal={() => toggleLeaveModal(false)}
        leaveDetails={{}}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  systemTime: getTime(state),
  ampm: getAMPM(state),
  longDay: getLongDay(state),
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(DashboardTop);
