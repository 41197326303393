import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import Button from "atoms/Button";
import { Form, notification } from "antd";
import { CALL_API } from "common/API";
import { useDispatch, useSelector } from "react-redux";
import { getClientid, getUserInfo } from "redux/selectors";
import moment from "moment";
import { setCurrentPlan } from "redux/actions";

const Subscribe = ({ data, setShowSubscribeFormModal, plan, setPlan }) => {
  const dispatch = useDispatch();
  const [clientSecret] = useState(data.clientSecret);
  const [subscriptionId] = useState(data.subscriptionId);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [messages, _setMessages] = useState("");
  const clientId = useSelector((state) => getClientid(state));
  const userInfo = useSelector((state) => getUserInfo(state));

  const [renderPaymentElement, setRenderPaymentElement] = useState(false);

  useEffect(() => {
    // This will re-render the PaymentElement when the component is mounted
    setRenderPaymentElement(true);
  }, []);

  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return "";
  }

  const handleSubmit = async (values) => {
    setSubscribeLoading(true);
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });

    if (error) {
      // show error and collect new card details.
      setMessage(error.message);
      setSubscribeLoading(false);

      return;
    }
    if (paymentIntent.status === "requires_action") {
      // Card was declined, show error and collect new card details.
      setMessage(paymentIntent.next_action.message);
      setSubscribeLoading(false);

      return;
    }
    console.log("handleSubmit  paymentIntent----->", paymentIntent);
    const response = await CALL_API("plan/start", "post", {
      // subscriptionId,
      clientId,
      emailId: userInfo.userid,
      userName: userInfo.name,
      planType: plan.tier ? plan.tier.toLowerCase() : "",
      amount: plan.amount,
      date: moment().format("MMMM Do YYYY, h:mm:ss a"),
      type: "create",
      payment_method_id: paymentIntent.payment_method,
    });
    console.log("handleSubmit  response----->", response);
    if (paymentIntent && paymentIntent.status === "succeeded" && response) {
      notification.success({
        message: "Subscription successful",
        description: response,
      });
    } else {
      notification.error({
        message: "Subscription failed",
        description: response ?? "Please try again",
      });
    }
    setSubscribeLoading(false);
    dispatch(
      setCurrentPlan({
        planType: plan.tier.toLowerCase(),
      })
    );
    setShowSubscribeFormModal(false);
    setPlan("");
    window.location.reload();
  };

  return (
    <>
      {/* <p>
        Try the successful test card: <span>4242424242424242</span>.
      </p>

      <p>
        Try the test card that requires SCA: <span>4000002500003155</span>.
        </p>

        <p>
        Use any <i>future</i> expiry date, CVC,5 digit postal code
      </p> */}

      <Form onFinish={handleSubmit} id="payment-form">
        <h1>Subscribe</h1>
        {/* <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter your name",
            },
          ]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item> */}
        {/* 
        <LinkAuthenticationElement
          id="link-authentication-element"
          className="mb-2"
        /> */}
        {renderPaymentElement && (
          <PaymentElement
            id="payment-element"
            options={{
              layout: {
                type: "tabs",
                defaultCollapsed: false,
              },
              paymentMethodOrder: ["card"],
            }}
          />
        )}

        <div className="text-end mt-4">
          <Button
            type="primary"
            htmlType="submit"
            disabled={!stripe}
            loading={subscribeLoading}
          >
            Subscribe
          </Button>
        </div>

        <div>{messages}</div>
      </Form>
    </>
  );
};

export default Subscribe;
