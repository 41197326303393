import React, { useEffect, useState } from "react";
import { Column, Bar } from "@ant-design/plots";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Spin } from "antd";

// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getClientid,
  getLeaveCategoryList,
  getAllDepartmentList,
  getUserList,
  getIsCheckedIn,
  getUserInfo,
} from "redux/selectors";
import DepartmentAttendanceReport from "./DepartmentAttendanceReport";
import AttendanceStaffList from "./AttendanceStaffList";
import { CALL_API } from "common/API";
import { concat, includes, isNaN, map, max, size, filter } from "lodash";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import { bottomNavigationActionClasses } from "@mui/material";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  padding: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 5px !important;
  height: 100%;
  // margin-bottom: 20px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
    font-weight: 600;
  }
`;

function AttendanceBarChart({
  userRoleId,
  selectedDepartmentId,
  ClientId,
  leaveCategoryList,
  allDepartmentList,
  userList,
  isCheckedIn,
  isHrDashboard = false,
  userInfo
}) {
  console.log('selecteddepartmentid--->',selectedDepartmentId)
  const [
    displayDepartmentAttendanceReportModal,
    toggleDepartmentAttendanceReportModal,
  ] = useState(false);
  const [displayAttendanceStaffModal, toggleDisplayAttendanceStaffModal] =
    useState(false);
  const [reportType, setReportType] = useState("");
  const [reportName, setReportName] = useState("");
  const [reportList, setReportList] = useState([]);
  const [reportColor, setReportColor] = useState("");
  const [attendanceReportTitle, setAttendanceReportTitle] = useState("");
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [absentList, setAbsentList] = useState([]);
  const [lateListData, setLateListData] = useState([]);
  const [onTimeList, setOnTimeList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [reportData, setReportData] = useState([
    {
      name: "onTime",
      type: "Present",
      numbers: 0,
      list: [],
      title: "On time Check-in Report",
    },
    {
      name: "Absent",
      type: "Absent",
      numbers: 0,
      list: [],
      title: "Absent Report",
    },
    // {
    //   name:'Post cut Off',
    //   type: 'Post cut Off',
    //   numbers: 0,
    //   list: [],
    // },
    {
      name: "Late",
      type: "Present",
      numbers: 0,
      list: [],
      title: "Late Check-in Report",
    },
    {
      name: "Leave",
      type: "Leave",
      numbers: 0,
      list: [],
      title: "Leave Report",
    },
    // {
    //   name:'Others',
    //   type: 'Present',
    //   numbers: 0,
    //   list: [],
    // }
  ]);
  const config = {
    isStack: true,
    dodgePadding: 0.2,
    data: reportData,
    height: 400,
    xField: "numbers",
    yField: "type",
    seriesField: "name",
    intervalPadding: 10,
    legend: false,
    position: "right",
    color: ({ name }) => {
      if (name === "Absent") {
        return "orange";
      } else if (name === "onTime") {
        return "#0565f5";
      } else if (name === "Late") {
        return "#5d9af5";
      } else if (name === "Leave") {
        return "#bd34eb";
      } else {
        return "#f5e0ff";
      }
    },
    theme: {
      maxColumnWidth: 50,
      minColumnWidth: 50,
    },
    label: {
      position: "middle",
      content: "", // Set this to an empty string
    },
    columnStyle: {
      cursor: "pointer",
    },
    xAxis: {
      max: isNaN(max(map(reportData, "numbers")))
        ? 100
        : max(map(reportData, "numbers")) + 15,
      min: 0,
      label: null, // Hide x-axis labels
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  // Render the chart with the config

  // Render the chart with the config

  const annualLeaveCategoryId = leaveCategoryList.find(
    (item) => item.leave_category_short_name === "annualleave"
  );
  const sickLeaveCategoryId = leaveCategoryList.find(
    (item) => item.leave_category_short_name === "sickleave"
  );

  useEffect(() => {
    if (
      (userRoleId !== 1 && selectedDepartmentId !== 0) ||
      userRoleId === 1 ||
      userRoleId === 6
    ) {
      getReportData();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId !== 6) {
      getReportData();
    }
  }, [selectedDepartmentId]);

  const getReportData = async () => {
    toggleReportLoader(true);
    if (ClientId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(100);

      try {
        const response = await CALL_API(
          `attendance-service/${ClientId}`,
          "post",
          {
            departmentId:
              userRoleId != 1 && !isHrDashboard ? selectedDepartmentId : [""],
              reportingmanager: !isHrDashboard ? userInfo?.reportingmanager : "",
          }
        );

        const todayAttendenceDetails = response?.todayAttendenceDetails;

        if (!todayAttendenceDetails) {
          console.error("todayAttendenceDetails is undefined or empty.");
          toggleReportLoader(false);
          return;
        }

        let absentList = [];
        let ontimeList = [];
        let lateList = [];
        let leaveList = [];

        todayAttendenceDetails.forEach((value) => {
          const userLeaveList = value?.leaveCount?.map((item) => {
            const department = allDepartmentList?.find(
              (dept) => dept?.department_id === item?.department_id
            );
            const leaveCategory = leaveCategoryList?.find(
              (leave) => leave.leave_category_id === item?.leave_category_id
            );
            const username = userList?.find(
              (user) => user.userid === item?.userid
            );
            return {
              ...item,
              departmentName: department?.department_name,
              leave_category_name: leaveCategory?.leave_category_name,
              name: username?.fullname,
            };
          });
          absentList = absentList.concat(value.absent || []);
          ontimeList = ontimeList.concat(value.onTime || []);
          lateList = lateList.concat(value.late || []);
          leaveList = leaveList.concat(userLeaveList || []);
        });

        let dupeReportData = [...reportData];
        dupeReportData[0].numbers = ontimeList.length;
        dupeReportData[1].numbers = absentList.length;
        dupeReportData[2].numbers = lateList.length;
        dupeReportData[3].numbers = leaveList.length;

        dupeReportData[0].list = ontimeList;
        dupeReportData[1].list = absentList;
        dupeReportData[2].list = lateList;
        dupeReportData[3].list = leaveList;

        setReportData(dupeReportData);
        setAbsentList(absentList);
        setLateListData(lateList);
        setOnTimeList(ontimeList);
        setLeaveList(leaveList);
      } catch (error) {
        console.error("Error fetching report data:", error);
      } finally {
        toggleReportLoader(false);
      }
    }
  };

  useEffect(() => {
    getReportData();
  }, [isCheckedIn]);

  return (
    <>
      <StyledChartContainer>
        <div className="name-title">Today's Overall Attendance</div>
        <Spin spinning={displayReportLoader}>
          <Bar
            {...config}
            onReady={(plot) => {
              plot.on("plot:click", (evt) => {
                if (evt.data && evt.data.data !== null) {
                  const {
                    data: {
                      data: { type, list, name, title },
                      color,
                    },
                  } = evt;
                  setReportList(list);
                  setReportType(type);
                  setReportName(name);

                  // setReportColor(color);
                  if (type === "Absent" || !isHrDashboard) {
                    setAttendanceReportTitle(title ? title : `${type} staff`);
                    toggleDisplayAttendanceStaffModal(true);
                  } else {
                    setAttendanceReportTitle(
                      name === "Leave" ? `${type}` : `${type}- ${name}`
                    );
                    toggleDepartmentAttendanceReportModal(true);
                  }

                  // else if (includes(["2", "3"], userRoleId)) {
                  //   if (type === "Absent") {
                  //     setAttendanceReportTitle(`${type} staff`);
                  //   } else {
                  //     setAttendanceReportTitle(`${type}- ${name}`);
                  //   }
                  //   toggleDisplayAttendanceStaffModal(true);
                  // }
                }
              });
            }}
          />
        </Spin>
      </StyledChartContainer>
      <DepartmentAttendanceReport
        displayModal={displayDepartmentAttendanceReportModal}
        toggleModal={() => toggleDepartmentAttendanceReportModal(false)}
        reportType={reportType}
        reportColor={reportColor}
        list={reportList}
        name={reportName}
        lateListData={lateListData}
        onTimeList={onTimeList}
        leaveList={leaveList}
        absentList={absentList}
      />
      <AttendanceStaffList
        displayModal={displayAttendanceStaffModal}
        toggleModal={() => toggleDisplayAttendanceStaffModal(false)}
        title={attendanceReportTitle}
        reportType={reportType}
        list={reportList}
        name={reportName}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
  isCheckedIn: getIsCheckedIn(state),
  userInfo: getUserInfo(state)
});

export default connect(mapStateToProps, null)(AttendanceBarChart);
