import React, { useEffect, useState } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import styled from "styled-components";
import COLORS from "common/Colors";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Input, Space, Spin } from "antd";
import { SearchOutlined } from "@mui/icons-material";
import Table from "atoms/Table";
import { ButtonFilled } from "reusableComponent/UIButtons";
import { getClientid, getUserList } from "redux/selectors";
import { CALL_API } from "common/API";
import { connect } from "react-redux";
import AddEmployee from "organisms/AddEmployee";
import { bindActionCreators } from "redux";
import { setUserList } from "redux/actions";
import { STATUS_CODE } from "common/Constants";

const EmployeeWrap = styled.div`
  .ant-input-affix-wrapper {
    background-color: #f8f7f7;
    width: 380px;
  }
  .ant-input {
    background-color: #f8f7f7;
    width: 100%;
  }
  .custom-table {
    border: none !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
`;

const PendingEmployee = ({ ClientId, userList, updateUserList}) => {
  console.log('userlist--->',userList)
  const [loader, setloader] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState();
  const [showOnboard, setShowOnboard] = useState(false)
  const [searchText, setSearchText] = useState("");
  const [pendingList, setPendingList] = useState([])
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let pendingUsers = userList?.filter((item) => item.statusid === "2");
    setPendingList(pendingUsers)
    setFilteredData(pendingUsers)
  },[userList])



  const history = useHistory();
  const breadStyle = { color: "#636980", cursor: "pointer" };
  const styledInput = {
    color: `${COLORS.TEXT.PRIMARY}`,
    fontSize: "16px",
    border: "none",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  };
  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );

    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        updateUserList(userList);
      }
    }
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "fullname",
      key: "fullname",
      render: (fullname) => (fullname ? fullname : "-"),
    },
    {
      title: "Email",
      dataIndex: "userid",
      key: "email",
      render: (email) => (email ? email : "-"),
    },
    {
      title: "Phone no.",
      dataIndex: "phoneno",
      key: "phone",
      render: (phone) => (phone ? phone : "-"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <ButtonFilled className="apply-leave-sm" onClick={() => {
          setShowOnboard(true)
          setSelectedDetails(record)
        }}> On Board </ButtonFilled>
      ),
    },
  ];

  const breadcrumbs = [
    <label key="2" style={breadStyle} onClick={() => history.goBack()}>
      Employee
    </label>,
    <Typography key="3" color="text.primary" fontSize="18" fontWeight="500">
      Pending Employee
    </Typography>,
  ];

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    if (value === "") {
      setFilteredData(pendingList);
    } else {
      const filtered = pendingList?.filter(
        (item) =>
          item.fullname.toLowerCase().includes(value) ||
          item.userid.toLowerCase().includes(value)
      );
      setFilteredData(filtered);
    }
  };

  return (
    <EmployeeWrap>
      

      {showOnboard ? <>
        <AddEmployee
          closeModal={() => {setShowOnboard(false)}}
          getEmployeeList={fetchMasterData}
          EmployeeList={userList}
          selectedDetails={selectedDetails}
          defaultStep={4}
        />
      </> :
      
        <div className="mt-3">
          <div className="mt-2 mb-2">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>
          <Input
            style={styledInput}
            prefix={<SearchOutlined style={styledIcon} />}
            placeholder="Search by name & email"
            value={searchText}
            onChange={handleSearchChange}
          />
          <div>
            <Table
              dataSource={filteredData}
              columns={columns}
              className="custom-table"
              pagination={false}
              // onRow={(record, rowIndex) => {
              //   return (
              //     setSelectedDetails(record)
              //    );
              // }}
              rowKey="userid"
              loading={loader}
            />
          </div>
        </div>
      }
    </EmployeeWrap>
  );
};

const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  userList: getUserList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(PendingEmployee);


