import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import { Col, Input, Modal, Row, Typography } from "antd";
import moment from "moment";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import EditIcon from "../../../../assets/Edit_Pencil_01.svg";
import DeleteIcon from "../../../../assets/Trash_Full.svg";

const { TextArea } = Input;

const StyledWrap = styled.div`
  .data {
    font-size: 16px;
    font-weight: 450;
    color: #1f243b;
  }
  .title {
    font-size: 15px;
    color: #636980;
    font-weight: 450;
  }
`;

const StyledIndicator = styled.span`
  color: #77f777;
  padding: 5px 10px;
  background: #edfbf1;
  border-radius: 10px;
  margin: 0 20px;
  font-weight: 500;
`;

const StyledDeclineIndicator = styled.span`
  color: #ee2a3b;
  padding: 5px 10px;
  background: #fcd8db;
  border-radius: 10px;
  margin: 0 20px;
  font-weight: 500;
`;

const StyledDateIndicator = styled.div`
  background: #e3e8fc;
  padding: 5px 5px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;

  @media (max-width: 576px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const ViewBusinessTrip = ({
  setView,
  view,
  selectedItem,
  setDeleteId,
  setDeleteData,
  setApproveData,
  setEdit,
  showModal,
  hrView,
  setComment,
  comment,
  error,
  setError,
  Menulist,
  hasDeletePermission,
  hasEditPermission,
}) => {
  const handleCommentChange = (e) => {
    const value = e.target.value;
    setComment(value);
    if (value) {
      setError("");
    }
  };

  return (
    <Modal
      open={view}
      width={600}
      title={
        <>
          {selectedItem.status !== "Waiting" ? (
            <>
              Travel ID: {selectedItem.travelId}{" "}
              {selectedItem.status === "Approved" ? (
                <StyledIndicator>{selectedItem.status}</StyledIndicator>
              ) : selectedItem.status === "Declined" ? (
                <StyledDeclineIndicator>
                  {selectedItem.status}
                </StyledDeclineIndicator>
              ) : null}{" "}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <>
                Travel ID: {selectedItem.travelId}{" "}
                {!hrView && (
                  <>
                    <span className="d-flex justify-content-end">
                      {hasEditPermission && (
                        <IconButton
                          onClick={() => {
                            setEdit(true);
                            showModal();
                            setView(false);
                          }}
                        >
                          {" "}
                          <img src={EditIcon} alt="edit" />{" "}
                        </IconButton>
                      )}
                      {hasDeletePermission && (
                        <IconButton
                          onClick={() => {
                            setDeleteId(selectedItem?.id);
                            setDeleteData(true);
                          }}
                        >
                          {" "}
                          <img src={DeleteIcon} alt="delete" />{" "}
                        </IconButton>
                      )}
                    </span>
                  </>
                )}
              </>
            </div>
          )}
        </>
      }
      onCancel={() => setView(false)}
      footer={null}
      closable={selectedItem.status === "Waiting" && !hrView ? false : true}
    >
      <StyledWrap>
        <Row>
          <Col xs={24} sm={24} md={selectedItem.status !== "Waiting" ? 24 : 24}>
            <StyledDateIndicator>
              <span className="mx-2 text-nowrap">
                Requested on{" "}
                {moment(selectedItem?.requestedOn).format("Do MMMM, YYYY")}
              </span>
              {selectedItem.status !== "Waiting" && (
                <>
                  {" "}
                  <span className="d-none d-sm-block">|</span>
                  <span className="mx-2">
                    {selectedItem.status} on{" "}
                    {moment(selectedItem?.approvedDate).format("Do MMMM, YYYY")}
                  </span>{" "}
                </>
              )}
            </StyledDateIndicator>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} className="p-2">
            <Typography className="title">Reason for Travel</Typography>
            <Typography className="data">{selectedItem?.reason}</Typography>
          </Col>
          <Col xs={24} sm={12} md={12} className="p-2">
            <Typography className="title">Date of Departure</Typography>
          </Col>
          <Col xs={24} sm={12} md={12} className="p-2">
            <Typography className="data">
              {moment(selectedItem?.departure).format("Do MMMM, YYYY")}
            </Typography>
          </Col>
          <Col xs={24} sm={12} md={12} className="p-2">
            <Typography className="title">Return Date</Typography>
          </Col>
          <Col xs={24} sm={12} md={12} className="p-2">
            <Typography className="data">
              {moment(selectedItem?.arrival).format("Do MMMM, YYYY")}
            </Typography>
          </Col>
          <Col xs={24} sm={12} md={12} className="p-2">
            <Typography className="title">Number of Days</Typography>
          </Col>
          <Col xs={24} sm={12} md={12} className="p-2">
            <Typography className="data">{selectedItem?.days}</Typography>
          </Col>
          <Col xs={24} sm={12} md={12} className="p-2">
            <Typography className="title">Travel Destination</Typography>
          </Col>
          <Col xs={24} sm={12} md={12} className="p-2">
            <Typography className="data">
              {selectedItem?.destination}
            </Typography>
          </Col>
          {selectedItem.status !== "Waiting" && (
            <>
              <Col xs={24} sm={24} md={24} className="p-2">
                <Typography className="title">Comments</Typography>
              </Col>
              <Col xs={24} sm={24} md={24} className="p-2">
                <Typography className="data">
                  {selectedItem?.comments}
                </Typography>
              </Col>
            </>
          )}
          {hrView && selectedItem.status === "Waiting" ? (
            <>
              {" "}
              <Col xs={24} sm={12} md={12} className="p-2">
                <Typography className="title">
                  Comments <span className="error">*</span>
                </Typography>
              </Col>{" "}
            </>
          ) : (
            <></>
          )}
        </Row>
      </StyledWrap>
      {console.log("menulist--->", Menulist)}
      {hrView && selectedItem.status === "Waiting" ? (
        <>
          {Menulist?.find(
            (item) =>
              item?.key === "allapprovals" &&
              item?.subMenu?.find(
                (subItem) =>
                  subItem?.key === "travelexpense" && subItem?.permission.edit
              )
          ) && (
            <>
              <Row>
                <TextArea
                  maxLength={500}
                  style={{
                    height: 120,
                    resize: "none",
                  }}
                  className="mx-2"
                  value={comment}
                  onChange={handleCommentChange}
                />
                {error && <span className="error">{error}</span>}
              </Row>
              <Col xs={24} sm={24} md={24}>
                <div className="d-flex justify-content-end">
                  <div>
                    <ButtonOutlined
                      onClick={() => {
                        if (!comment.trim()) {
                          setError("Comment cannot be empty");
                          return;
                        } else {
                          setView(false);
                          setDeleteData(true);
                        }
                      }}
                    >
                      Decline
                    </ButtonOutlined>
                  </div>
                  <div>
                    <ButtonFilled
                      onClick={() => {
                        if (!comment.trim()) {
                          setError("Comment cannot be empty");
                          return;
                        } else {
                          setView(false);
                          setApproveData(true);
                        }
                      }}
                    >
                      Approve
                    </ButtonFilled>
                  </div>
                </div>
              </Col>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default ViewBusinessTrip;
