import React, { useState, useEffect } from "react";
import { Form, Switch, Row, Col, Checkbox } from "antd";
import { connect } from "react-redux";
import { setBreakTypesList, setRoleList } from "redux/actions";
import {
  getClientid,
  getLocations,
  getAllDepartmentList,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { CALL_API } from "common/API";
import { STATUS_CODE, VALIDATE_CHARACTER } from "common/Constants";
import EditInformation from "molecules/EditInformation";
import EditSelectTable from "molecules/EditSelectTable";
import TabsList from "molecules/TabsList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import FormModal from "./FormModal";

const EditLevels = ({
  isModalVisible,
  closeModal,
  setBreakTypes,
  ClientId,
  getBreakList,
  BreakDetails,
  locationList,
  departmentList,
  //need for delete
  deleteBreak,
  viewOnly = false,
  saveRoleList,
  levelDetails,
}) => {
  const [form] = Form.useForm();
  const filterLocationList = locationList?.filter(
    (item) => item?.login_from_anywhere !== 1
  );

  const fetchMasterData = async (fetch_master_data) => {
    const { code, breakList, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        setBreakTypes(breakList);
        saveRoleList(roleList);
      }
    }
  };

  const [submit, setsubmit] = useState(false);
  const [create, setCreate] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    setValues({
      name: levelDetails.name,
      description: levelDetails?.description,
    });
  }, [levelDetails]);

  useEffect(() => {
    form.setFieldsValue({
      name: levelDetails.name,
      description: levelDetails?.description,
    });
  }, [levelDetails]);

  // const handleSubmit = async () => {
  //   setCreate(true);
  //   const { name } = values;
  //   const containsSpecialCharacters = !VALIDATE_CHARACTER.test(name);
  //   if (containsSpecialCharacters) {
  //     // Handle the case where BreakName contains special characters
  //     return; // Prevent further execution
  //   }
  //   const { code } = await CALL_API(
  //     `owner-break/${ClientId}/${BreakDetails.breakId}`,
  //     "patch",
  //     {
  //       breakname: name,
  //       locationid: selectedLocationId,
  //       departmentid: selectDepartmentId,
  //     }
  //   );
  //   if (code === STATUS_CODE.SUCCESS) {
  //     setCreate(false);
  //     fetchMasterData(true);
  //     getBreakList();
  //   }
  // };

  // useEffect(() => {
  //   if (submit) {
  //     handleSubmit();
  //   }
  // }, [selectDepartmentId, selectedLocationId, values]);

  const items = [
    {
      id: 1,
      label: "Basic",
      component: (
        <>
          {viewOnly ? (
            <Row className="styled-card">
              <Col>
                <b>Level Name</b> : {values?.name}
                <b>Description</b> : {values?.description}
              </Col>
            </Row>
          ) : (
            <EditInformation
              details={levelDetails}
              setValues={setValues}
              create={create}
              title="levels"
              setsubmit={setsubmit}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {isModalVisible && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => {
              closeModal(false);
            }}
          >
            Levels <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className=" pb-0 pt-1">{values?.name}</b>
          <TabsList items={items} />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  locationList: getLocations(state),
  departmentList: getAllDepartmentList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBreakTypes: setBreakTypesList,
      saveRoleList: setRoleList,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps, null)(EditLevels);
