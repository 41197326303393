import {
  USERNAME,
  TOGGLE_DRAWER,
  USER_ROLE,
  USER_ROLE_TYPE,
  USER_PROFILE,
  IS_LOGGED_IN,
  SET_DEPARTMENT_ID,
  SET_SYSTEM_DATE,
  SET_BREAK_TYPES,
  SET_IS_ON_BREAK,
  SET_IS_CHECKED_IN,
  TOGGLE_SUCCESS_MODAL,
  TOGGLE_PROCESSING_MODAL,
  LOADING_DATE_FIRST_TIME,
  SET_DEPARTMENT_LIST,
  SET_ALL_DEPARTMENT_LIST,
  SET_LEAVE_CATEGORY_LIST,
  SET_LOCATIONS,
  SET_PUNCH_ACTIONS,
  SET_ROLE_LIST,
  SET_HOLIDAY_LIST,
  SET_USER_ROLE_LIST,
  CLEAR_STORE,
  SET_CHECK_IN_LOCATION_ID,
  SET_USER_LIST,
  SET_STATUS_LIST,
  SET_PUNCH_ID,
  SET_BREAK_ID,
  SET_SHIFT_TIME_MASTER,
  SET_NOTIFICATION_LIST,
  SET_WEB_SOCKET_STATUS,
  SET_CHAT_SELECTED_STAFF_ID,
  SET_CHAT_MESSAGE_LIST,
  SET_CHAT_MESSAGE_QUEUE,
  SET_UNREAD_MESSAGE_INDICATOR,
  PROFILE_PIC,
  MENU_LIST,
  BAND_LIST,
  USER_MENU_LIST,
  CLIENT_ID,
  TRAIL_PERIOD,
  CURRENT_PLAN,
  SUBSCRIPTION_ID,
  SUBSCRIPTION_STATUS,
  AI_CHAT_HISTORY,
  SET_CHECK_IN_DEPARTMENT_ID,
  CURRENT_AI_MESSAGE_DETAILS,
  FORM_NAME,
  LEAVE_LIST,
  ACTIVE_MENU_LIST,
  SET_FILTER,
  PRODUCT_INFO,
} from "./actionsTypes";

export const setUsernName = (response) => (dispatch) => {
  dispatch({
    type: USERNAME,
    payload: response,
  });
};

export const setToggleDrawer = (response) => (dispatch) => {
  dispatch({
    type: TOGGLE_DRAWER,
    payload: response,
  });
};

export const setIsUserLoggedIn = (response) => (dispatch) => {
  dispatch({
    type: IS_LOGGED_IN,
    payload: response,
  });
};

export const setUserRole = (response) => (dispatch) => {
  window.localStorage.setItem("selectedRoleId", response);
  dispatch({
    type: USER_ROLE,
    payload: response,
  });
};

export const setUserRoleType = (response) => (dispatch) => {
  dispatch({
    type: USER_ROLE_TYPE,
    payload: response,
  });
};

export const setUserProfile = (response) => (dispatch) => {
  dispatch({
    type: USER_PROFILE,
    payload: response,
  });
};

export const setDepartmentId = (response) => (dispatch) => {
  dispatch({
    type: SET_DEPARTMENT_ID,
    payload: response,
  });
};

export const setSystemDate = (response) => (dispatch) => {
  dispatch({
    type: SET_SYSTEM_DATE,
    payload: response,
  });
};

export const setBreakTypesList = (response) => (dispatch) => {
  dispatch({
    type: SET_BREAK_TYPES,
    payload: response,
  });
};

export const setIsOnBreak = (response) => (dispatch) => {
  dispatch({
    type: SET_IS_ON_BREAK,
    payload: response,
  });
};

export const toggleSuccessModal = (response) => (dispatch) => {
  dispatch({
    type: TOGGLE_SUCCESS_MODAL,
    payload: response,
  });
};

export const toggleProcessingModal = (response) => (dispatch) => {
  dispatch({
    type: TOGGLE_PROCESSING_MODAL,
    payload: response,
  });
};

export const setLoadingDataFirstTime = (response) => (dispatch) => {
  dispatch({
    type: LOADING_DATE_FIRST_TIME,
    payload: response,
  });
};

export const setDepartmentList = (response) => (dispatch) => {
  dispatch({
    type: SET_DEPARTMENT_LIST,
    payload: response,
  });
};

export const setLeaveCategoryList = (response) => (dispatch) => {
  dispatch({
    type: SET_LEAVE_CATEGORY_LIST,
    payload: response,
  });
};

export const setLocationList = (response) => (dispatch) => {
  dispatch({
    type: SET_LOCATIONS,
    payload: response,
  });
};

export const setPunchActions = (response) => (dispatch) => {
  dispatch({
    type: SET_PUNCH_ACTIONS,
    payload: response,
  });
};

export const setRoleList = (response) => (dispatch) => {
  dispatch({
    type: SET_ROLE_LIST,
    payload: response,
  });
};

export const setHolidayList = (response) => (dispatch) => {
  dispatch({
    type: SET_HOLIDAY_LIST,
    payload: response,
  });
};

export const setUserRoleList = (response) => (dispatch) => {
  dispatch({
    type: SET_USER_ROLE_LIST,
    payload: response,
  });
};

export const setCheckedIn = (response) => (dispatch) => {
  dispatch({
    type: SET_IS_CHECKED_IN,
    payload: response,
  });
};

export const setCheckInLocationId = (response) => (dispatch) => {
  dispatch({
    type: SET_CHECK_IN_LOCATION_ID,
    payload: response,
  });
};

export const setCheckInDepartmentId = (response) => (dispatch) => {
  dispatch({
    type: SET_CHECK_IN_DEPARTMENT_ID,
    payload: response,
  });
};

export const setUserList = (response) => (dispatch) => {
  dispatch({
    type: SET_USER_LIST,
    payload: response,
  });
};

export const setStatusList = (response) => (dispatch) => {
  dispatch({
    type: SET_STATUS_LIST,
    payload: response,
  });
};

export const setAllDepartmentList = (response) => (dispatch) => {
  dispatch({
    type: SET_ALL_DEPARTMENT_LIST,
    payload: response,
  });
};

export const setPunchId = (response) => (dispatch) => {
  dispatch({
    type: SET_PUNCH_ID,
    payload: response,
  });
};

export const setBreakId = (response) => (dispatch) => {
  dispatch({
    type: SET_BREAK_ID,
    payload: response,
  });
};

export const setShiftTimeMaster = (response) => (dispatch) => {
  dispatch({
    type: SET_SHIFT_TIME_MASTER,
    payload: response,
  });
};

export const setNotificationList = (response) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATION_LIST,
    payload: response,
  });
};

export const setWebSocketConnectionStatus = (response) => (dispatch) => {
  dispatch({
    type: SET_WEB_SOCKET_STATUS,
    payload: response,
  });
};

export const setChatMessageList = (response) => (dispatch) => {
  dispatch({
    type: SET_CHAT_MESSAGE_LIST,
    payload: response,
  });
};

export const setChatMessageQueue = (response) => (dispatch) => {
  dispatch({
    type: SET_CHAT_MESSAGE_QUEUE,
    payload: response,
  });
};

export const setUnreadMessageIndicator = (response) => (dispatch) => {
  dispatch({
    type: SET_UNREAD_MESSAGE_INDICATOR,
    payload: response,
  });
};

export const setChatSelectedStaffId = (response) => (dispatch) => {
  dispatch({
    type: SET_CHAT_SELECTED_STAFF_ID,
    payload: response,
  });
};

export const setProfilePic = (response) => (dispatch) => {
  dispatch({
    type: PROFILE_PIC,
    payload: response,
  });
};

export const setMenuList = (response) => (dispatch) => {
  dispatch({
    type: MENU_LIST,
    payload: response,
  });
};

export const setActiveMenuList = (response) => (dispatch) => {
  dispatch({
    type: ACTIVE_MENU_LIST,
    payload: response,
  });
};

export const setBandList = (response) => (dispatch) => {
  dispatch({
    type: BAND_LIST,
    payload: response,
  });
};

export const setUserMenuList = (response) => (dispatch) => {
  dispatch({
    type: USER_MENU_LIST,
    payload: response,
  });
};

export const setClientid = (response) => (dispatch) => {
  dispatch({
    type: CLIENT_ID,
    payload: response,
  });
};

export const setCurrentPlan = (response) => (dispatch) => {
  dispatch({
    type: CURRENT_PLAN,
    payload: response,
  });
};

export const setTrailPeriod = (response) => (dispatch) => {
  dispatch({
    type: TRAIL_PERIOD,
    payload: response,
  });
};

export const setSubscriptionId = (response) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_ID,
    payload: response,
  });
};

export const setSubscriptionStatus = (response) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_STATUS,
    payload: response,
  });
};

export const setAIChatHistory = (response) => (dispatch) => {
  dispatch({
    type: AI_CHAT_HISTORY,
    payload: response,
  });
};

export const setCurrentAIMessageDetails = (response) => (dispatch) => {
  dispatch({
    type: AI_CHAT_HISTORY,
    payload: response,
  });
};

export const updateFormName = (response) => (dispatch) => {
  dispatch({
    type: FORM_NAME,
    payload: response,
  });
};

export const setLeaveList = (response) => (dispatch) => {
  dispatch({
    type: LEAVE_LIST,
    payload: response,
  });
};

export const setFilter = (menuName, filterName, filterValue) => ({
  type: SET_FILTER,
  payload: { menuName, filterName, filterValue },
});

export const setproductInfo = (response) => (dispatch) => {
  dispatch({
    type: PRODUCT_INFO,
    payload: response,
  });
};

export const clearStore = () => (dispatch) => {
  dispatch({
    type: CLEAR_STORE,
    payload: "",
  });
};
