import { apiInstance } from "config/baseUrl";
import { useQuery } from "react-query";

/**
 * Custom hook for making API requests using axios and react-query.
 *
 * @param {string} queryKey - The key used to identify the query in react-query.
 * @param {string} url - The URL to make the API request to.
 * @param {object} options - Additional options for the useQuery hook.
 * @returns {object} - The result of the API request with additional states.
 */

export const useFetch = (queryKey, url, options, post = false) => {
  const accessToken = window.localStorage.getItem("token");
  // const dispatch = useDispatch()
  // const navigate = useNavigate()

  return useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      return await apiInstance({
        method: post ? "POST" : "GET",
        url: url,
        headers: {
          Tymeplusjwt: accessToken,
        },
        data: post
      })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
    },
    refetchOnWindowFocus: false,
    ...options,
  });
};
