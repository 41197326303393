import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Select,
  Input,
  Typography,
  Button,
  Table,
  message,
} from "antd";
import {
  TYPE_OF_EXPENSE,
  TYPE_OF_CURRENCIES,
  CurrenciesList,
} from "common/Constants";
import uploadIcon from "../../../../assets/File_Upload.svg";
import { IconButton } from "@mui/material";

const AddBills = ({
  setBills,
  bills,
  containerRef,
  setTempFile,
  setUploadView,
  setAddBill,
  tempFile,
  uploadView,
  formData,
  handleCancelEdit,
  setFormData,
  editBills,
  setFileErrors,
  fileErrors,
  setEditBills,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  const uploadRef = useRef();
  const selectRef = useRef(null);

  const updateAllBillsCurrency = (currency) => {
    const updatedBills = bills.map((bill) => ({
      ...bill,
      amountType: currency,
    }));
    setBills(updatedBills);

    setFormData({
      ...formData,
      amountType: currency,
    });
  };

  const handleCurrencyChange = (value) => {
    setSelectedCurrency(value);
    updateAllBillsCurrency(value);
  };

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });

    let newErrors = { ...errors };

    switch (key) {
      case "nameofexpense":
        if (!value) {
          newErrors.nameofexpense = "Please enter the name of the expense";
        } else {
          delete newErrors.nameofexpense;
        }
        break;
      case "totalAmount":
        if (!value) {
          newErrors.totalAmount = "Please enter the total amount spent";
        } else {
          delete newErrors.totalAmount;
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const [errors, setErrors] = useState({
    nameofexpense: "",
    totalAmount: "",
  });
  const handleSave = () => {
    if (
      formData.nameofexpense &&
      formData.totalAmount &&
      formData.files.length
    ) {
      setBills([...bills, formData]);
      setFormData({
        nameofexpense: "",
        totalAmount: "",
        amountType: selectedCurrency,
        files: [],
        fileNames: [],
      });
      setAddBill(false);
      editBills && setEditBills(false);
      setFileErrors({ ...fileErrors, bills: false });
    } else {
      let newErrors = {};
      if (!formData.nameofexpense) {
        newErrors.nameofexpense = "Please enter the name of the expense";
      }
      if (!formData.totalAmount) {
        newErrors.totalAmount = "Please enter the total amount spent";
      }
      if (formData.files.length === 0) {
        message.error("Please upload atleast one file");
      }
      setErrors(newErrors);
    }
  };

  const handleCancel = () => {
    setFormData({
      nameofexpense: "",
      totalAmount: "",
      amountType: selectedCurrency,
      files: [],
      fileNames: [],
    });
    setAddBill(false);
  };

  const clearFileInput = () => {
    uploadRef.current.value = "";
  };

  const fileChange = (e) => {
    const file = e.target.files[0];

    const allowedExtensions = [
      "jpg",
      "png",
      "jpeg",
      "svg",
      "pdf",
      "csv",
      "xlsx",
    ];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (allowedExtensions.includes(fileExtension)) {
      setUploadView(true);
      setFormData({
        ...formData,
        files: [...formData.files, file],
        fileNames: [...formData.fileNames, new Date() + file.name],
      });
      clearFileInput();
    } else {
      message.error("Please upload image, pdf, xlsx or csv files only");
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...formData.files];
    updatedFiles.splice(index, 1);

    const updatedFileNames = [...formData.fileNames];
    updatedFileNames.splice(index, 1);

    setFormData({
      ...formData,
      files: updatedFiles,
      fileNames: updatedFileNames,
    });
  };

  return (
    <div className="bill-container w-100">
      <div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} className="p-2">
            <Typography>
              Name of Expense<span className="error">*</span>
            </Typography>
            <Input
              value={formData.nameofexpense}
              maxLength={30}
              onChange={(e) => handleChange("nameofexpense", e.target.value)}
            />
            {errors.nameofexpense && (
              <span className="error">{errors.nameofexpense}</span>
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="p-2">
            <Typography>
              Total amount spend on this Expense type
              <span className="error">*</span>
            </Typography>
            <div className="d-flex" ref={selectRef}>
              <Select
                style={{ width: 120 }}
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                disabled={editBills}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={() => selectRef.current}
              >
                {CurrenciesList.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.name + " " + option.symbol}
                  </option>
                ))}
              </Select>
              <Input
                value={formData.totalAmount}
                type="number"
                maxLength={10}
                onChange={(e) => handleChange("totalAmount", e.target.value)}
              />
            </div>
            {errors.totalAmount && (
              <span className="error">{errors.totalAmount}</span>
            )}
          </Col>
          <Col xs={24} sm={24} md={24} className="p-2">
            <Typography>
              Upload relevant bills<span className="error">*</span>
            </Typography>

            <input
              className="d-none"
              type="file"
              multiple
              ref={uploadRef}
              accept=".jpg,.jpeg,.png,.pdf, .csv, .xlsx"
              onChange={(e) => fileChange(e)}
            />
            {formData.files.length ? (
              <div className="file-container">
                {formData.files.map((data, index) => (
                  <div key={index} className="file-box m-2">
                    {data.name}
                    <span
                      className="mx-3 pointer"
                      onClick={() => handleRemoveFile(index)}
                    >
                      X
                    </span>
                  </div>
                ))}
                <div
                  onClick={() => uploadRef.current.click()}
                  className="plus-btn center m-2 px-4"
                >
                  +
                </div>
              </div>
            ) : (
              <div
                onClick={() => uploadRef.current.click()}
                className={`center add-bills add-active`}
              >
                <IconButton>
                  <img src={uploadIcon} alt="upload" />
                </IconButton>
                Upload
              </div>
            )}
          </Col>
        </Row>
        <Row justify="end" gutter={[8, 0]} className="p-2">
          <Col>
            <Button
              className="ant-btn-text"
              type="link"
              onClick={editBills ? handleCancelEdit : handleCancel}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button className="ant-btn-text" type="link" onClick={handleSave}>
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddBills;
