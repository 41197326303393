const COLORS = {
  PRIMARY: "#2D53DA",
  TAB: "#2D53DA",
  SIDEBAR: "#e3e8fc",
  SECONDARY: "#E6F5FF",
  SUCCESS: "#00A651",
  WARNING: "#F8B505",
  SELECTED: "#1D4174",
  ERROR: "#FF5353",
  BACKGROUND: "#FFFFFF",
  WHITE: "#FFFFFF",
  BLACK: "#1B1B1B",
  SECONDARY_BLACK: "#131313",
  TEXT: {
    WHITE: "#FFFFFF",
    PRIMARY: "#636980",
    SECONDARY: "#636980",
  },
  LIGHT_PRIMARY: "#FFFFFF",
  DOCUMENT_BACKGROUND: "#e1e1e1",
  DARK_PRIMARY: "#000000",
  GREY: "#a7a7a7",
  DROPDOWN_BORDER: "#9B9B9B",
  PENDING_LEAVE: "#FC9D25",
  APPROVED_LEAVE: "#17AC00",
  CHART: {
    CONSUMED: "#96d17a",
    AVAILABLE: "#52c41a",
  },
  ATTENDANCE: {
    PRESENT: "#0bdc00",
    ABSENT: "#fc9d25",
    POSTCUTOFF: "#fff700",
  },
  EMPLOYEES: {
    ONTIME: "#26A4FF",
    LATE: "#D9D9D9",
  },
  LEAVE: {
    ANNUAL_AVAILABLE: "#12A6F9",
    ANNUAL_CONSUMED: "#B0DFFB",
    SICK_AVAILABLE: "#FC9D25",
    SICK_CONSUMED: "#FDBD73",
    MATERNITY_AVAILABLE: "#04BA8A",
    MATERNITY_CONSUMED: "#7DD4C1",
    PATERNITY_AVAILABLE: "#B48FDC",
    PATERNITY_CONSUMED: "#E4CEFF",
    7: {
      AVAILABLE: "#12A6F9",
      CONSUMED: "#B0DFFB",
    },
    1: {
      AVAILABLE: "#FC9D25",
      CONSUMED: "#FDBD73",
    },
    5: {
      AVAILABLE: "#04BA8A",
      CONSUMED: "#7DD4C1",
    },
    6: {
      AVAILABLE: "#B48FDC",
      CONSUMED: "#E4CEFF",
    },
  },
  USER_REPORT_COLOR: {
    ONTIME: "#03fc98",
    LATE: "#4cadfc",
    LEAVE: "#7e4cfc",
    ABSENT: "#ff9a47",
    EARLYEXIT: "#ff75a1",
  },
};

export default COLORS;
