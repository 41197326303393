import React, { useState } from 'react'
import { ButtonFilled, ButtonOutlined } from 'reusableComponent/UIButtons'
import { Avatar, Checkbox, Modal, Select, Table } from "antd";
import styled from "@emotion/styled";
import COLORS from 'common/Colors';
import { connect } from 'react-redux';
import { getClientid, getRoleList, getUserList } from 'redux/selectors';
import { concat, get } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { CALL_API } from 'common/API';
import { toggleSuccessModal } from "redux/actions";
import { bindActionCreators } from 'redux';
import { STATUS_CODE } from 'common/Constants';

const StyledStaffNameContainer = styled.div`
  text-align: center;
  .staff-name {
    font-weight: bold;
    font-size: 1rem;
    padding-top: 10px;
  }
  .location {
    padding-top: 5px;
    svg {
      vertical-align: sub;
      margin-right: 5px;
    }
  }
`;
const StyledAvatar = styled(Avatar)`
  font-size: 14px;
  height: 25px;
  width: 25px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
  color: #fff;
  border-radius: 50%;
  border: 1px solid black;
  .ant-avatar-string {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledModal = styled(Modal)`
  border-radius: 10px;
  padding-top: 5%;
  p{
    font-size: 16px;
    color: ${COLORS.TEXT.PRIMARY};
    font-weight: 600;
  }
  small{
    font-size: 13px;
    color: ${COLORS.TEXT.PRIMARY};
  }

  .ant-modal-content {
    border-radius: 10px;
  }
  /* .ant-modal-close-x {
    display: none;
  } */
  .ant-modal-footer {
    display: none;
  }
  .stylebtn{
    min-width: 130px;
  }
  .ant-modal-body {
    padding: 24px 24px 0px 24px;
}
.box{
    height: 200px;
    overflow: auto;
}
.confirm-box{
    background-color: #EDF7FF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-box{
    border: 1px solid #ccc;
    border-radius: 5px;
}
.custom-select-box{
    width: 150px;
}
`;

function MovingPeopleModal({ isModalOpen, handleCancel, handleOk, details, roleList, loading, buttonName, userList, ClientId, updateSuccessModal, togglebtnLoader, getRoleList, fetchMasterData }) {
    const [selectedUserId, setSelectedUserId] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState({});

    const handlesubmit = async() =>{
        const transformSelectedRoles = (Roles) => {
            return Object.entries(Roles).map(([userId, roleId]) => ({
              userId,
              roleId: [roleId],
            }));
          };
          togglebtnLoader(true);
          const { code } = await CALL_API(`owner-roles/${ClientId}`, "patch", {
            deletedRoledId: details?.roleId,
            userDetails: transformSelectedRoles(selectedRoles)
          });
          if(code === STATUS_CODE.SUCCESS){
            getRoleList();
            fetchMasterData();
            setSelectedUserId([]);
            setSelectedRoles({});
            handleCancel();
            updateSuccessModal({
            visibility: true,
            message: "Moved Successfully",
          });
          setTimeout(() => {
            updateSuccessModal({
                visibility: false,
                message: "",
              });
            handleOk();
          }, 2000);
          }
          togglebtnLoader(false);
    }  

    const findUser = (userId) => userList.find((item) => item.userid === userId);
  
    const getUserId = (userId, checked) => {
      const newUserId = checked
        ? [...selectedUserId, userId]
        : selectedUserId.filter((item) => item !== userId);
      setSelectedUserId(newUserId);
    };
  
    const handleRoleChange = (userId, value) => {
      setSelectedRoles({
        ...selectedRoles,
        [userId]: value,
      });
    };
  
    const handleSelectAllUserId = (e) => {
      const checked = e.target.checked;
      const allUserIds = details?.userId || [];
      setSelectedUserId(checked ? allUserIds : []);
    };
  
    const handleRoleChangeAllUser = (value) => {
      const newRoles = { ...selectedRoles };
      selectedUserId.forEach((userId) => {
        newRoles[userId] = value;
      });
      setSelectedRoles(newRoles);
    };
  
    const cursorStyle = { cursor: "pointer" };
  
    return (
        <>
      <StyledModal
        title="Moving People"
        width={600}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Checkbox
            checked={selectedUserId.length === details?.userId?.length}
            onChange={handleSelectAllUserId}
          >
            Select All
          </Checkbox>
          <span>{selectedUserId?.length} / {details?.userId?.length} Moved</span>
        </div>
        <div className='card-box'>
          <div className='box p-2'>
            {details?.userId?.map((userId, index) => {
              const user = findUser(userId);
              return (
                <div key={index} className='d-flex justify-content-between align-items-center mb-2'>
                  <div className='d-flex align-items-center gap-2'>
                    <Checkbox
                      checked={selectedUserId.includes(userId)}
                      onChange={(e) => getUserId(userId, e.target.checked)}
                    />
                    <StyledStaffNameContainer>
                      {user?.profileurl ? (
                        <StyledAvatar src={user.profileurl} size="default" />
                      ) : (
                        <StyledAvatar size="default">
                          {user?.fullname.charAt(0)}
                        </StyledAvatar>
                      )}
                    </StyledStaffNameContainer>
                    <p className='mb-0 ml-2'>{user?.fullname}</p>
                  </div>
                  <Select
                    className="custom-select-box"
                    showSearch
                    disabled={selectedUserId.includes(userId)}
                    optionFilterProp="children"
                    placeholder="Search role"
                    options={roleList.map(role => ({ label: role.roleName, value: role.roleId }))}
                    value={selectedRoles[userId]}
                    onChange={(value) => handleRoleChange(userId, value)}
                  />
                </div>
              );
            })}
          </div>
          {selectedUserId.length > 0 && (
            <div className="mt-3 p-2 confirm-box">
              <div>
                <span className='px-1'>{selectedUserId.length} Selected</span>
                <Select
                  className="custom-select-box ml-2 px-1"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Apply role to selected"
                  options={roleList.map(role => ({ label: role.roleName, value: role.roleId }))}
                  onChange={handleRoleChangeAllUser}
                />
              </div>
              <div>
                {selectedUserId.length > 0 && Object?.keys(selectedRoles)?.length > 0 && (
                  <span className='text-primary mx-2' style={cursorStyle} onClick={() => handleRoleChangeAllUser(selectedRoles[selectedUserId[0]])}>Done</span>
                )}
                <CloseIcon style={cursorStyle} onClick={() => { setSelectedRoles({}); setSelectedUserId([]); }} />
              </div>
            </div>
          )}
        </div>
  
        <div className='text-end mt-4 mb-2'>
          <ButtonOutlined key="back" onClick={() => { setSelectedRoles({}); setSelectedUserId([]); handleCancel(); }} className='stylebtn'>
            Cancel
          </ButtonOutlined>
          <ButtonFilled
            key="submit"
            disabled={details?.userId?.length != Object?.keys(selectedRoles)?.length}
            loading={loading}
            onClick={handlesubmit}
            className='stylebtn'
          >
            {buttonName ? buttonName : "Confirm"}
          </ButtonFilled>
        </div>
      </StyledModal>
      
      </>
    );
  }
  
  const mapStateToProps = (state) => ({
    roleList: getRoleList(state),
    userList: getUserList(state),
    ClientId: getClientid(state),
  });

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        updateSuccessModal: toggleSuccessModal,
      },
      dispatch
    );
  
  export default connect(mapStateToProps,mapDispatchToProps)(MovingPeopleModal);