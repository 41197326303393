import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form } from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
  getUserList,
  getClientid,
} from "redux/selectors";
import { utils, writeFileXLSX } from "xlsx";
import { CALL_API } from "common/API";
import { MONTH_LIST, STATUS_CODE } from "common/Constants";

import {
  addDaysinDate,
  dateInDetail,
  getKenyanDateTime,
  getMonthDateRange,
  getMySqlDate,
  momentDate,
  numberOfDaysBetweenDates,
} from "utils/Date";
import COLORS from "common/Colors";
import {
  concat,
  find,
  get,
  includes,
  orderBy,
  flatMap,
  intersection,
} from "lodash";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;

function MonthlyAttendanceReport({
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
  ClientId,
  userList,
}) {
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);
  const [CSVAttendanceList, setCSVAttendanceList] = useState([]);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  var filterList = [];
  const [monthList, setMonthList] = useState([]);
  const containerRef = useRef(null);

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
    }
    setMonthList(month_list);
  };

  const STAFF_LIST_ALL = attendanceList.map((val) => {
    return { userId: val.userId, fullName: val.fullName };
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map((a) => JSON.stringify(a)))].map(
    (a) => JSON.parse(a)
  );

  const filterattentancereport = attendanceList.filter((val) => {
    if (form.getFieldValue("staff_name") === "") {
      return val;
    } else if (val?.userId === form.getFieldValue("staff_name")) {
      return val;
    }
  });

  const [holidayList, setHolidayList] = useState([]);

  const calculateSundays = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);

    let sundays = 0;
    let currentDay = start.clone();

    while (currentDay.isSameOrBefore(end, "day")) {
      if (currentDay.day() === 0) {
        sundays++;
      }
      currentDay.add(1, "day");
    }

    return sundays;
  };

  // const workingdays = async (startDate, endDate, userId, holidayLists) => {
  //   const totalDay = numberOfDaysBetweenDates(startDate, endDate) + 1
  //   const filterUser = userList?.filter((val) => val?.userid === userId)
  //   const holidayPerUser = holidayLists?.filter((item) => item?.location_id?.includes(filterUser[0]?.baselocationid))
  //   let totalHolidays = 0;
  //   holidayPerUser.forEach(holiday => {
  //     const removeSundays = calculateSundays(holiday.start_date, holiday.end_date);
  //     totalHolidays += numberOfDaysBetweenDates(holiday.start_date, holiday.end_date) + 1 - removeSundays;
  //   });

  //   const sundayCount = calculateSundays(startDate, endDate);

  //   return totalDay - (sundayCount + totalHolidays)

  // };

  const workingdays = async (startDate, endDate, userId, holidayLists) => {
    const totalDay = numberOfDaysBetweenDates(startDate, endDate) + 1;

    const filterUser = userList?.filter((val) => val?.userid === userId);
    const holidayPerUser = holidayLists?.filter((item) =>
      item?.location_id?.includes(filterUser[0]?.baselocationid)
    );

    let totalHolidays = 0;
    holidayPerUser.forEach((holiday) => {
      const removeWeekends = calculateWeekends(
        holiday.start_date,
        holiday.end_date
      );
      totalHolidays +=
        numberOfDaysBetweenDates(holiday.start_date, holiday.end_date) +
        1 -
        removeWeekends;
    });

    const weekendCount = calculateWeekends(startDate, endDate);

    return totalDay - (weekendCount + totalHolidays);
  };

  // Helper function to calculate the number of Saturdays and Sundays between two dates
  const calculateWeekends = (start, end) => {
    let weekendCount = 0;
    const currentDate = new Date(start);

    while (currentDate <= new Date(end)) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek === 0) {
        // 0 = Sunday, 6 = Saturday
        weekendCount++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return weekendCount;
  };

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "User ID" },
    { label: "First Name" },
    { label: "Last Name" },
    // Note : this code need in future
    // { label: "Department" },
    { label: "Total Working Days" },
    { label: "Worked days" },
    { label: "On-time" },
    { label: "Late" },
    { label: "Leave" },
    { label: "Absent" },
  ];

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      key: "employeeId",
      width: "7%",
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      width: "7%",
      render: (userid) => {
        if (userid !== ("" || null || undefined)) {
          return userid;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      width: "10%",
      render: (fullname) => {
        if (fullname !== ("" || null || undefined)) {
          return fullname;
        } else {
          return "-";
        }
      },
    },
    // Note : this code need in future
    // {
    //   title: "Department",
    //   dataIndex: "department_name",
    //   key: "department_name",
    //   width: "10%",
    //   render: (department) => {
    //     if (department !== ("" || null || undefined)) {
    //       return department;
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    {
      title: "Total Working days",
      dataIndex: "totalWorkingDays",
      key: "totalWorkingDays",
      width: "10%",
    },
    {
      title: "Worked days",
      dataIndex: "workingDays",
      key: "workingDays",
      width: "10%",
      render: (_, record) => {
        return record.onTimeCount + record.lateCount;
      },
    },

    {
      title: "On-Time",
      dataIndex: "onTimeCount",
      key: "onTimeCount",
      width: "10%",
      render: (onTimeCount) => {
        if (onTimeCount === ("" || null || undefined)) {
          return "0";
        } else {
          return onTimeCount;
        }
      },
    },
    {
      title: "Late",
      dataIndex: "lateCount",
      key: "lateCount",
      width: "10%",
      render: (lateCount) => {
        if (lateCount === ("" || null || undefined)) {
          return "0";
        } else {
          return lateCount;
        }
      },
    },

    {
      title: "Leave",
      dataIndex: "leaveCount",
      key: "leaveCount",
      width: "10%",
      render: (leaveCount) => {
        if (leaveCount === ("" || null || undefined)) {
          return "0";
        } else {
          return leaveCount;
        }
      },
    },
    {
      title: "Absent",
      dataIndex: "absentCount",
      key: "absentCount",
      width: "10%",
      render: (absentCount) => {
        if (absentCount === ("" || null || undefined)) {
          return "0";
        } else {
          return absentCount;
        }
      },
    },
  ];

  useEffect(() => {
    getAttendanceReport();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("duration_type"),
    form.getFieldValue("start_date"),
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("department"),
  ]);

  // Function to generate report
  const getAttendanceReport = async () => {
    list();
    setAttendanceList([]);
    const { month, department } = form.getFieldsValue();
    setDisplayTableLoader(true);

    const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
      dateInDetail(getKenyanDateTime()).year,
      get(find(MONTH_LIST, { value: month }), "index", 0)
    );

    const holidayLists = await CALL_API(`holiday-list/${ClientId}`, "post", {
      start_date: getMySqlDate(startDateOfMonth),
      end_date: getMySqlDate(lastDateOfMonth),
    });

    if (holidayLists) {
      setHolidayList(holidayLists);
    }

    const { code, monthlyAttendanceList } = await CALL_API(
      `attendance-report-monthly/${ClientId}`,
      "post",
      {
        departmentId: selectedDepartmentId,
        startDate: getMySqlDate(startDateOfMonth),
        endDate: getMySqlDate(lastDateOfMonth),
      }
    );

    if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
      const userIdExistsInData1 = (userId) => {
        return monthlyAttendanceList?.some(
          (item) =>
            item.userId === userId || item.userId === userId.toLowerCase()
        );
      };

      const allUserList = userList
        ?.filter((item) => !userIdExistsInData1(item.userid))
        .map((item) => ({
          employeeId: item.employeeid,
          lateCount: 0,
          monthEndDate: getMySqlDate(lastDateOfMonth),
          leaveCount: 0,
          userId: item.userid,
          monthStartDate: getMySqlDate(startDateOfMonth),
          absentCount: 0,
          earlyCheckOutCount: 0,
          fullName: item.fullname,
          onTimeCount: 0,
        }));
      const monthlyAttendanceReport = concat(
        monthlyAttendanceList,
        allUserList
      );

      await Promise.all(
        monthlyAttendanceReport?.map(async (entry) => {
          const filterUser = userList?.filter(
            (val) => val?.userid === entry?.userId
          );
          const concatenatedArray = flatMap(filterUser[0]?.userdepartmentid);
          const totalWorkingDays = await workingdays(
            entry.monthStartDate,
            entry.monthEndDate,
            entry.userId,
            holidayLists
          );
          entry.totalWorkingDays = totalWorkingDays;
          if (
            intersection(concatenatedArray, selectedDepartmentId).length > 0
          ) {
            return entry;
          } else {
            return null;
          }
        })
      );

      // const updatedList = monthlyAttendanceReport?.map(entry => ({
      //   ...entry,
      //   date: new Date(entry.date),
      // }));

      setAttendanceList(monthlyAttendanceReport);
    }
    setDisplayTableLoader(false);
  };

  // Function to validate date range
  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 100,
    showSizeChanger: false,
  };

  const xport = React.useCallback(() => {
    const elt = document.getElementById("Table");
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "attendance-report.xlsx");
  });

  return (
    <>
      <Row className="page-title">
        <Col
          xs={24}
          sm={19}
          md={19}
          className="page-title-head"
          ref={containerRef}
        >
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                staff_name: "",
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(getKenyanDateTime()),
                end_date: momentDate(addDaysinDate(getKenyanDateTime(), 7)),
                department: "",
              }}
              onFinish={getAttendanceReport}
            >
              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Form.Item
                        name="start_date"
                        rules={[
                          () => ({
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="Start date"
                          format="DD-MM-YYYY"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date"
                        rules={[
                          () => ({
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="End date"
                          format="DD-MM-YYYY"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Form.Item
                        name="month"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select..."
                          options={monthList}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="staff_name"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.fullName ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) => {
                    if (optionA?.userid === "" || optionB?.userid === "") {
                      return 0;
                    }
                    return (optionA?.fullname ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.fullname ?? "").toLowerCase());
                  }}
                  placeholder="Select Employee"
                  options={concat(
                    {
                      userId: "",
                      fullName: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "fullName",
                    value: "userId",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
              {(userRoleId === 1 || userRoleId === 6) && (
                <Form.Item name="department">
                  <Select
                    placeholder="Select Department"
                    options={concat(
                      {
                        department_id: "",
                        department_name: "All Departments",
                      },
                      allDepartmentList
                    )}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              )}
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className="align-right">
          <ButtonOutlined
            onClick={xport}
            disabled={filterattentancereport.length === 0}
          >
            Export XLSX file
          </ButtonOutlined>
          <CSVLink
            header={CSVHeaders}
            data={CSVAttendanceList}
            filename={"attendance-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={filterattentancereport}
          columns={columns}
          // Note : First parameter is Needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="checkin_id"
          pagination={
            filterattentancereport.length > 100 ? paginationOptions : false
          }
          id="Table"
        />
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  userList: getUserList(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state),
  ClientId: getClientid(state),
});

export default connect(mapStateToProps, null)(MonthlyAttendanceReport);
