import React from "react";
import { Button} from "antd";
import Table from 'atoms/Table';
import styled from "@emotion/styled";
import { getRoleList } from "redux/selectors";
import { connect } from "react-redux";

const StyledButton = styled(Button)`
  background: #26A4FF;
  border-radius: 5px;
  color: #FFFFFF;
  width: 100px;
  font-weight: bold;
`;

const StyledButtons = styled(Button)`
  background: #26A4FF;
  border-radius: 5px;
  color: #FFFFFF;
  width: 150px;
  font-weight: bold;
`;
const SelectTable = ({
  step,
  setStep,
  columns,
  tabledata,
  submit,
  setCreate,
  setOpenModal,
  Name,
  selectedRoleId,
  selectedBaseLocation,
  selectedBaseDepartment,
  isEdit,
  updateClick
}) => {
  const customLocale = {
    emptyText: (<StyledButtons type="primary" className="m-3" onClick={() => setOpenModal(true)}>
      {Name}
    </StyledButtons>),
  };

  return (
    <>
      <Table
        className="mb-3"
        dataSource={tabledata}
        columns={columns}
        rowKey="role_id"
        pagination={false}
        locale={customLocale}
      />
      <div className="text-end w-100">
        {!isEdit &&(
          <StyledButton type="primary" onClick={() => setStep(step - 1)} className="mx-2">
          Back
        </StyledButton>
        )}
        {(submit || isEdit) ? (
          <StyledButton type="primary" onClick={() => {isEdit ? updateClick() : setCreate(true)}}>{isEdit ? "Update" : " Create"}</StyledButton>
        ) : (
          <StyledButton
            type="primary"
            disabled={selectedRoleId?.length === 0 || selectedBaseLocation === "" || selectedBaseDepartment?.length === 0 || selectedBaseDepartment === ""}
            onClick={() => setStep(step + 1)}
          >
            Next
          </StyledButton>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  roleList: getRoleList(state),
});
export default connect(mapStateToProps, null)(SelectTable);