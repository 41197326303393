import { CheckCircleFilled } from "@ant-design/icons";
import styled from "@emotion/styled";
import { SearchOutlined } from "@mui/icons-material";
import {
  Col,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Typography,
  message,
} from "antd";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import COLORS from "common/Colors";
import { CurrenciesList, EXPENSE_STATUS, STATUS_CODE } from "common/Constants";
import { useEffect, useRef, useState } from "react";
import Filter from "../../../../assets/Filter.svg";
import { ViewReimbursementDetails } from "./viewReimbursementDetails";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-cell:nth-last-child(2) {
    white-space: normal;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const Reimbursement = ({
  ClientId,
  userInfo,
  userList,
  updateSuccessModal,
  Menulist,
}) => {
  const columns = [
    {
      title: "Reimb.ID",
      dataIndex: "reimbursementId",
      key: "reimbursementId",
    },
    {
      title: "Name",
      dataIndex: "userId",
      key: "userId",
      width: "10%",
      render: (userId) => {
        const user = userList.find((user) => user.userid === userId);
        return user ? user.fullname : userId;
      },
    },
    {
      title: "Employee ID",
      dataIndex: "userId",
      key: "userId",
      render: (userId) => {
        const user = userList.find((user) => user.userid === userId);
        return user ? user.employeeid : userId;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Type of Expense",
      dataIndex: "typeofExpense",
      key: "typeofExpense",
      render: (text, record) => {
        return record.typeofExpense === "travel" ? "Travel" : "Others";
      },
    },
    {
      title: "Total Expense",
      dataIndex: "billProofs",
      key: "expense",
      render: (billProofs, record) => {
        let totalExpense = 0;

        for (let i = 0; i < billProofs.length; i++) {
          const bill = billProofs[i];
          if (typeof bill.totalAmount === "string") {
            const amount = parseInt(bill.totalAmount);
            if (!isNaN(amount)) {
              totalExpense += amount;
            } else {
              console.error("Invalid totalAmount value:", bill.totalAmount);
            }
          } else if (typeof bill.totalAmount === "number") {
            totalExpense += bill.totalAmount;
          } else {
            console.error("Invalid totalAmount value:", bill.totalAmount);
          }
        }
        const currency = CurrenciesList.find(
          (currency) => currency.code === billProofs[0].amountType
        );
        const symbol = currency ? currency.symbol : "";

        return symbol + " " + totalExpense;
      },
    },
  ];

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 100,
    showSizeChanger: false,
  };

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "16px" };
  const containerRef = useRef(null);

  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [view, setView] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [bills, setBills] = useState([]);
  const [approveData, setApproveData] = useState(false);
  const [declineData, setDeclineData] = useState(false);
  const [traveldata, setTraveldata] = useState([]);
  const [showLoader, toggleLoader] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [selected, setselected] = useState("");
  const [active, setActive] = useState("");
  const [filteredData, setFilteredData] = useState(traveldata);

  useEffect(() => {
    const filtered = traveldata.filter((item) => item.status === "Waiting");
    setFilteredData(filtered);
  }, [traveldata]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    lastEvaluatedKey: null,
  });
  const [searchString, setSearchString] = useState("");

  const handleSearchChange = (e) => {
    const searchString = e.target.value;
    setSearchString(searchString);
    if (searchString.length) {
      const filtered = filteredData.filter((item) =>
        String(item?.reimbursementId)
          .toLowerCase()
          .includes(String(searchString.toLowerCase()))
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(traveldata);
    }
  };
  const handleSelectChange = (selectedValue) => {
    if (selectedValue !== "All") {
      const filtered = traveldata.filter(
        (item) => item.status === selectedValue
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(traveldata);
    }
  };

  const handleRowClick = (record) => {
    setSelectedItem(record);
    setView(true);
    setselected(record.billProofs[0]);
    getFilesId(record?.id);
  };

  const handleNextPage = () => {
    if (pagination.lastEvaluatedKey !== null) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: prevPagination.current + 1,
      }));
    }
  };

  useEffect(() => {
    if (pagination.lastEvaluatedKey !== null) {
      getMoreData();
    }
  }, [pagination.current]);

  const getMoreData = async () => {
    setDisplayTableLoader(true);
    const { code, data, LastEvaluatedKey } = await CALL_API(
      `travel-expense/reimbursement?LastEvaluatedKey=${
        pagination.lastEvaluatedKey || 0
      }&clientId=${ClientId}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      setTraveldata([...traveldata, ...data]);
      setDisplayTableLoader(false);
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: LastEvaluatedKey,
      }));
    } else {
      toggleLoader(false);
      message.error("Error");
      setDisplayTableLoader(false);
    }
  };

  const getData = async () => {
    setDisplayTableLoader(true);

    const { code, data, LastEvaluatedKey } = await CALL_API(
      `travel-expense/reimbursement?LastEvaluatedKey=0&clientId=${ClientId}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      setTraveldata(data);
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: LastEvaluatedKey,
      }));
      setDisplayTableLoader(false);
    } else {
      toggleLoader(false);
      message.error("Error");
      setDisplayTableLoader(false);
    }
  };

  const getFilesId = async (id) => {
    const { code, url } = await CALL_API(
      `travel-expense/reimbursement/billproofs?clientId=${ClientId}&id=${id}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (url) {
        setBills(url);
        setselected(url[0]);
      }
    } else {
      message.error("Error loading Files!");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmitedit = async (status) => {
    if (!comment.trim()) {
      setError("Comment cannot be empty");
      return;
    }
    toggleLoader(true);
    const { code } = await CALL_API(
      `travel-expense/reimbursement?status=${status}`,
      "patch",
      {
        comments: comment,
        id: selectedItem.id,
        clientId: ClientId,
        userId: userInfo.userid,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      toggleLoader(false);
      updateSuccessModal({
        visibility: true,
        message: status + "d",
        Sucessicon:
          status === "Decline" ? (
            <div className="d-flex justify-content-center align-itemw-center">
              <CheckCircleFilled style={{ color: "red" }} />
            </div>
          ) : (
            ""
          ),
      });
      setDeclineData(false);
      setComment("");
      setError(false);
      setApproveData(false);
      getData();
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: 0,
      }));
    } else {
      toggleLoader(false);
      message.error("Error");
    }
  };

  const sortDataByStatus = (data) => {
    return data.sort((a, b) => {
      if (a.status === "Waiting" && b.status !== "Waiting") {
        return -1;
      }
      if (a.status !== "Waiting" && b.status === "Waiting") {
        return 1;
      }
      return 0;
    });
  };

  return (
    <div>
      <>
        <Row className="page-title">
          <Col
            xs={24}
            sm={8}
            md={5}
            className="page-title-head pe-3"
            ref={containerRef}
          >
            <Input
              style={styledInput}
              prefix={<SearchOutlined style={styledIcon} />}
              placeholder="Search "
              onChange={handleSearchChange}
              value={searchString}
              className="me-3"
            />
          </Col>
          <Col
            xs={24}
            sm={8}
            md={5}
            className="page-title-head"
            ref={containerRef}
          >
            <Select
              placeholder={
                <>
                  <img src={Filter} alt="filter" />{" "}
                  <span className="Status-placeholder">Status</span>
                </>
              }
              options={EXPENSE_STATUS}
              defaultValue={"Waiting"}
              getPopupContainer={() => containerRef.current}
              className="w-100"
              onChange={handleSelectChange}
            />
          </Col>
        </Row>

        <Row className="page-title">
          <Col
            xs={24}
            sm={19}
            md={19}
            className="page-title-head"
            ref={containerRef}
          ></Col>
        </Row>
        <Spin spinning={displayTableLoader}>
          <div style={{ overflowX: "auto" }}>
            <StyledTable
              dataSource={sortDataByStatus(filteredData)}
              columns={columns}
              rowKey="user_leave_id"
              pagination={filteredData.length > 100 ? paginationOptions : false}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
            />
          </div>
          {pagination.lastEvaluatedKey !== null && (
            <div className="d-flex justify-content-center align-items-center my-3">
              <Button
                type="primary"
                onClick={handleNextPage}
                disabled={displayTableLoader}
              >
                Load More
              </Button>
            </div>
          )}
        </Spin>
      </>

      <Modal
        open={approveData}
        onOk={() => handleSubmitedit("Approve")}
        okText={"Yes"}
        cancelText={"No"}
        onCancel={() => setApproveData(false)}
        closable={false}
      >
        <Typography.Title className="p-2" level={5}>
          Are you sure you want to approve this reimbursement request?
        </Typography.Title>
      </Modal>

      <Modal
        open={declineData}
        onOk={() => handleSubmitedit("Decline")}
        okText={"Yes"}
        cancelText={"No"}
        onCancel={() => setDeclineData(false)}
        closable={false}
      >
        <Typography.Title className="p-2" level={5}>
          Are you sure you want to decline this reimbursement request?
        </Typography.Title>
      </Modal>

      <ViewReimbursementDetails
        view={view}
        setView={setView}
        selectedItem={selectedItem}
        setApproveData={setApproveData}
        setDeclineData={setDeclineData}
        comment={comment}
        setComment={setComment}
        handleSubmitedit={handleSubmitedit}
        error={error}
        setError={setError}
        bills={bills}
        setselected={setselected}
        selected={selected}
        setActive={setActive}
        active={active}
        Menulist={Menulist}
      />
    </div>
  );
};

export default Reimbursement;
