import BasicInformation from "molecules/BasicInformation";
import InReview from "molecules/InReview";
import React, { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StepsProgress from "organisms/StepsProgress";

export const AddLevels = ({ isAddLevels, setIsAddLevels }) => {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState({});
  console.log("values--->", values);
  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Review" },
  ];
  return (
    <div className="styled-card">
      <>
        <label
          className="bg-transparent border-0 mb-3 text-left styled-cursor"
          onClick={() => setIsAddLevels(false)}
        >
          Levels <ArrowForwardIosIcon className="px-1" />
        </label>{" "}
        <b className=" pb-0 pt-1">Add Levels</b>
        <StepsProgress stepdata={stepdata} title="Add Break" step={step} />
      </>
      {isAddLevels && step === 0 && (
        <BasicInformation
          step={step}
          setStep={setStep}
          setValues={setValues}
          title={"levels"}
          values={values}
          alldatas={""}
        />
      )}

      {step === 1 && (
        <InReview
          step={step}
          setStep={setStep}
          BasicInformation={{
            "Level Name": values?.name,
            Description: values?.description,
          }}
          //   create={onFinish}
          //   loading={loading}
        />
      )}
    </div>
  );
};
