import React, { useEffect, useRef } from "react";
import { Form, Input, Button, Row, Col, DatePicker, Select } from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import { momentDate } from "utils/Date";
import {
  NON_EMPTY_REGEX,
  NON_CHARACTER,
  VALIDATE_CHARACTER,
  TIMEZONELIST,
  countryList,
} from "common/Constants";
import { connect } from "react-redux";
import { getHolidayList } from "redux/selectors";

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  margin-top: 20px;
  font-weight: bold;
`;
const Styleddiv = styled.div`
  .ant-form-item-label > label {
    font-weight: bold;
  }
`;

const BasicInformation = ({
  step,
  setStep,
  title,
  setValues,
  values,
  alldatas,
  holidayList,
}) => {
  const [form] = Form.useForm();
  const containerRef = useRef(null);

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const onFinish = (values) => {
    setValues(values);
    setStep(step + 1);
  };

  useEffect(() => {
    form.setFieldsValue(values);
  }, [values]);

  return (
    <Styleddiv>
      <Form
        form={form}
        name="AddDepartment"
        layout="vertical"
        initialValues={{
          name: "",
          latitude: "",
          longtitude: "",
          start_date: "",
          end_date: "",
          usertimezone: "",
          country: "",
          description: "",
        }}
        onFinish={onFinish}
      >
        {title === "Add Location" && (
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: "Please select country" }]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
            >
              {countryList?.map((country, index) => (
                <Select.Option key={index} value={country} label={country}>
                  {country}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={
            title === "Add Location"
              ? "Location Name"
              : title === "holiday"
              ? "Holiday Name"
              : title === "break"
              ? "Break Name"
              : title === "levels"
              ? "Level Name"
              : "Department Name"
          }
          name="name"
          rules={[
            {
              required: true,
              message:
                title === "Add Location"
                  ? "Please enter location name"
                  : title === "holiday"
                  ? "Please enter holiday name"
                  : title === "break"
                  ? "Please enter break name"
                  : title === "levels"
                  ? "Please enter the level name"
                  : "Please enter department name",
            },
            {
              validator: (rule, value) => {
                if (
                  value &&
                  !VALIDATE_CHARACTER.test(value) &&
                  title !== "holiday" &&
                  title !== "department"
                ) {
                  return Promise.reject(
                    title === "Add Location"
                      ? "Location Name should not contain special characters"
                      : title === "break"
                      ? "Break Name should not contain special characters"
                      : title === "levels"
                      ? "Level Name should not contain special characters"
                      : "Department Name should not contain special characters"
                  );
                }
                return Promise.resolve();
              },
            },
            {
              validator: (rule, value) => {
                if (value) {
                  const trimmedValue = value.trim();
                  if (title === "holiday") {
                    if (
                      alldatas.some(
                        (data) => data.holiday_name === trimmedValue
                      )
                    ) {
                      return Promise.reject("Holiday already exists");
                    }
                  } else if (title === "break") {
                    if (
                      alldatas.some(
                        (data) => data.breakName.trim() === trimmedValue
                      )
                    ) {
                      return Promise.reject("Break already exists");
                    }
                  } else if (title === "Add Location") {
                    if (
                      alldatas.some(
                        (data) => data.location_name === trimmedValue
                      )
                    ) {
                      return Promise.reject("Location already exists");
                    }
                    // } else if (title === "levels") {
                    //   if (
                    //     alldatas.some((data) => data.level_name === trimmedValue)
                    //   ) {
                    //     return Promise.reject("Level already exists");
                    //   }
                    // } else {
                    if (
                      alldatas.some(
                        (data) => data.department_name === trimmedValue
                      )
                    ) {
                      return Promise.reject("Department already exists");
                    }
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder={
              title === "Add Location"
                ? "Location Name"
                : title === "holiday"
                ? "Holiday Name"
                : title === "break"
                ? "Break Name"
                : title === "levels"
                ? "Level Name"
                : "Department Name"
            }
            maxLength={25}
          />
        </Form.Item>

        {title === "Add Location" && (
          <>
            <Form.Item
              name="usertimezone"
              label="Time Zone"
              rules={[{ required: true, message: "Please select Time zone" }]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
              >
                {TIMEZONELIST?.map((item, index) => (
                  <Select.Option key={index} value={item} label={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Latitude"
              name="location_latitude"
              rules={[
                {
                  required: true,
                  pattern: NON_CHARACTER,
                  message: "Please enter latitude!",
                },
              ]}
            >
              <Input placeholder="Enter latitude" maxLength={20} />
            </Form.Item>
            <Form.Item
              label="Longitude"
              name="location_longitude"
              rules={[
                {
                  required: true,
                  pattern: NON_CHARACTER,
                  message: "Please enter longitude!",
                },
              ]}
            >
              <Input placeholder="Enter longitude" maxLength={20} />
            </Form.Item>
          </>
        )}

        {title === "holiday" && (
          <>
            <Row>
              <Col xs={24} xl={24} ref={containerRef}>
                <Form.Item
                  label="Start date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Please enter start date!",
                    },
                    () => ({
                      validator(rule, value) {
                        const date = moment(value);
                        // if (!date.isValid()) {
                        //   return Promise.reject("Invalid date");
                        // }

                        if (title === "holiday" && holidayList?.length > 0) {
                          const isHolidayOverlap = holidayList.some((item) => {
                            const itemStartDate = moment(item.start_date);
                            const itemEndDate = moment(item.end_date);
                            return (
                              date.isBetween(
                                itemStartDate,
                                itemEndDate,
                                undefined,
                                "[]"
                              ) ||
                              date.isSame(itemStartDate, "day") ||
                              date.isSame(itemEndDate, "day")
                            );
                          });

                          if (isHolidayOverlap) {
                            return Promise.reject("Holiday Date already exist");
                          }
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={validateDateRange}
                    placeholder="Select start date"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} ref={containerRef}>
                <Form.Item
                  label="End date"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Please enter end date!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const date = moment(value);
                        const startDate = moment(getFieldValue("start_date"));

                        // if (!date.isValid()) {
                        //   return Promise.reject("Invalid date");
                        // }

                        if (date.isBefore(startDate, "day")) {
                          return Promise.reject(
                            "End date cannot be before start date"
                          );
                        }

                        if (title === "holiday" && holidayList?.length > 0) {
                          const isHolidayOverlap = holidayList.some((item) => {
                            const itemStartDate = moment(item.start_date);
                            const itemEndDate = moment(item.end_date);
                            return (
                              date.isBetween(
                                itemStartDate,
                                itemEndDate,
                                undefined,
                                "[]"
                              ) ||
                              date.isSame(itemStartDate, "day") ||
                              date.isSame(itemEndDate, "day")
                            );
                          });

                          if (isHolidayOverlap) {
                            return Promise.reject("Holiday Date already exist");
                          }
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    placeholder="Select end date"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {title === "levels" && (
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter Description",
              },
            ]}
          >
            <Input placeholder="Enter Description" maxLength={100} />
          </Form.Item>
        )}
        <div className="text-end w-100">
          <StyledButton type="primary" htmlType="submit">
            Next
          </StyledButton>
        </div>
      </Form>
    </Styleddiv>
  );
};
const mapStateToProps = (state) => ({
  holidayList: getHolidayList(state),
});

export default connect(mapStateToProps, null)(BasicInformation);
