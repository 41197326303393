import React from 'react'
import { ButtonFilled, ButtonOutlined } from 'reusableComponent/UIButtons'
import { Modal } from "antd";
import styled from "@emotion/styled";
import COLORS from 'common/Colors';

const StyledModal = styled(Modal)`
  border-radius: 10px;
  padding-top: 5%;
  p{
    font-size: 16px;
    color: ${COLORS.TEXT.PRIMARY};
    font-weight: 600;
  }
  small{
    font-size: 13px;
    color: ${COLORS.TEXT.PRIMARY};
  }

  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }
  .stylebtn{
    min-width: 130px;
  }
  .ant-modal-body {
    padding: 24px 24px 0px 24px;
}
`;

function ConformationModal({ isModalOpen, handleCancel, handleOk, loading, name, note, buttonName, cancelButtonName }) {

  return (
    <div>
      <StyledModal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
        footer={[]}
      >
        
          <p >{name}</p>
          
          <div className='text-end'>
            <ButtonOutlined key="back" onClick={handleCancel} className='stylebtn'>
              {cancelButtonName? cancelButtonName :"Cancel"}
            </ButtonOutlined>
            <ButtonFilled
              key="submit"
              loading={loading}
              onClick={handleOk}
              className='stylebtn'
            >
              {buttonName ? buttonName : "Yes"}
              
            </ButtonFilled>
          </div>
          <br/>
          {note && <small>{note}</small>}


      </StyledModal>
    </div>
  )
}

export default ConformationModal