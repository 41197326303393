import React, { useState } from 'react'
import { ButtonFilled, ButtonOutlined } from 'reusableComponent/UIButtons'
import { Modal } from "antd";
import styled from "@emotion/styled";
import COLORS from 'common/Colors';

const StyledModal = styled(Modal)`
  border-radius: 10px;
  padding-top: 5%;
  p{
    font-size: 13px;
    color: ${COLORS.TEXT.PRIMARY};
    font-weight: 600;
  }
  small{
    font-size: 13px;
    color: ${COLORS.TEXT.PRIMARY};
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-footer {
    display: none;
  }
  .stylebtn{
    min-width: 130px;
  }
`;

function PublishLeaveModal({ isModalOpen, handleCancel, handleOk, loading, name, note }) {
 const [active, setActive] = useState("")

  return (
    <div>
      <StyledModal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}

      >
        <div className='mt-3 text-start'>
        
          <h6>Note</h6>
          <br/>
          <p>Publish :  After publishing the leave, you cannot modify the leave</p>
          <p>Draft : You can modify later, but the leave will not be allocated to the users</p>
          <br/>
          <div className='text-center'>
            <ButtonOutlined key="back" onClick={() => {setActive("draft"); handleOk(false)}}  loading={loading  && active === "draft"} className='stylebtn'>
              Draft
            </ButtonOutlined>
            <ButtonFilled
              key="submit"
              loading={loading && active === "publish"}
              onClick={() => {setActive("publish");handleOk(true)}}
              className='stylebtn'
            >
              Publish
            </ButtonFilled>
          </div>
          <br/>
          
        </div>

      </StyledModal>
    </div>
  )
}

export default PublishLeaveModal