import { SearchOutlined } from "@mui/icons-material";
import { Input } from "antd";
import Icon from "atoms/Icon";
import Table from "atoms/Table";
import { CALL_API } from "common/API";
import COLORS from "common/Colors";
import { find } from "lodash";
import moment from "moment";
import AlertModal from "organisms/AlertModal";
import React from "react";

function TalentPool({
  data,
  jobList,
  setShowComponent,
  getData,
  isloading,
  setIndex,
  MenuList,
  searchTerm,
  setSearchTerm,
}) {
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e?.target?.value);
  };

  const filteredDataSource = data?.filter((item) =>
    item?.firstName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const deleteData = async () => {
    setLoading(true);
    await CALL_API(`recruitment/candidate/${deleteId}`, "patch", {
      deleteStatus: true,
    });
    getData();
    setLoading(false);
    setDeleteOpen(false);
  };

  const columns = [
    {
      title: "Candidate Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, record) => {
        return record?.firstName + " " + record?.lastName;
      },
    },
    {
      title: "Applied For",
      dataIndex: "jobId",
      key: "jobId",
      render: (_, record) => {
        const job = find(jobList, { jobId: record?.jobId });
        return job?.title;
      },
    },
    {
      title: "Date Applied",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        const date = moment(record?.createdAt).format("DD MMM YYYY");
        return date;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (val, record) => {
        return (
          <div className="d-flex gap-5">
            {MenuList?.find(
              (item) =>
                item?.key === "HRResources" &&
                item?.subMenu?.find(
                  (subItem) =>
                    subItem?.key === "Recruitment" && subItem?.permission.delete
                )
            ) && (
              <Icon
                name="trash"
                onClick={(e) => {
                  setDeleteId(record?.candidateId);
                  setDeleteOpen(true);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "16px" };
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <Input
            style={styledInput}
            value={searchTerm}
            prefix={<SearchOutlined style={styledIcon} />}
            placeholder="Search People"
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <Table
        dataSource={filteredDataSource}
        columns={columns}
        pagination={false}
        rowKey="candidateId"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              const isDeleteIcon = event.target.closest(".d-flex.gap-5");

              if (!isDeleteIcon) {
                setShowComponent("candidateDetails");
                setIndex(rowIndex);
              }
            },
          };
        }}
        loading={isloading}
      />

      <AlertModal
        isModalOpen={deleteOpen}
        handleCancel={() => setDeleteOpen(false)}
        handleOk={deleteData}
        name={"Are you sure you want to delete this candidate?"}
        loading={loading}
      />
    </div>
  );
}

export default TalentPool;
