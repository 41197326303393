import { Button, Card, Modal, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { PaymentWrap } from "./style";
import { ReactComponent as CircleErrorIcon } from "assets/Circle_Warning.svg";
import { getClientid, getCurrentPlan, getTrailPeriod } from "redux/selectors";
import { useSelector } from "react-redux";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { publicStripeKey } from "common/Constants";
import { Elements } from "@stripe/react-stripe-js";
import Pricing from "molecules/Pricing";
import Subscribe from "molecules/Subscribe";
import { ReactComponent as CheckedIcon } from "assets/checked_tick.svg";
import { ReactComponent as UnCheckedIcon } from "assets/unchecked_tick.svg";
import { ReactComponent as TrashIcon } from "assets/Trash_Full.svg";
import AddIcon from "@mui/icons-material/Add";
import Table from "atoms/Table";
import { CALL_API } from "common/API";
import AddCard from "molecules/AddCard";
import styled from "styled-components";

const paymentHistoryColumns = [
  {
    title: "Payment Method",
    dataIndex: "paymentMethod",
    key: "paymentMethod",
  },
  {
    title: "Date of Payment",
    dataIndex: "dateOfPayment",
    key: "dateOfPayment",
  },
  {
    title: "Time of Payment",
    dataIndex: "timeOfPayment",
    key: "timeOfPayment",
  },
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    key: "amountPaid",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const Payment = () => {
  // TODO: subscription status, payment cards && payment history

  const currentPlan = useSelector(getCurrentPlan);

  const trailEndDate = useSelector(getTrailPeriod);

  const trialPeriodEndsIn = moment(trailEndDate, "DD/MM/YYYY").diff(
    moment(),
    "days"
  );

  const clientId = useSelector(getClientid);
  const [plan, setPlan] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [showSubscribeFormModal, setShowSubscribeFormModal] = useState(false);
  const [showAddAtmCardModal, setShowAddAtmCardModal] = useState(false);

  const [atmCards, setAtmCards] = useState([]);

  const isFreeTrialEnded = trialPeriodEndsIn < 0;

  const [stripePromise] = useState(() => loadStripe(publicStripeKey));

  const handleCancelSubscribeFormModal = () => {
    setShowSubscribeFormModal(false);
    setPlan("");
    setClientSecret("");
  };

  const hasActivePlan =
    currentPlan?.planType?.toLowerCase() === "standard" ||
    // true || //? for testing
    currentPlan?.planType?.toLowerCase() === "premium" ||
    currentPlan?.planType?.toLowerCase() === "enterprise";

  const currentPlanStatus = currentPlan?.subscriptionStatus;

  const isCurrentPlanInActive = currentPlanStatus !== "active";

  const handleCancelAtmCardsModal = () => {
    setShowAddAtmCardModal(false);
    setClientSecret("");
  };

  const [addCardLoading, setAddCardLoading] = useState(false);

  const addCard = async () => {
    try {
      setAddCardLoading(true);
      const response = await CALL_API("card/generate", "post", {});

      const clientSecretKey = response;
      console.log("addCard  clientSecretKey----->", clientSecretKey);

      setShowAddAtmCardModal(true);
      setClientSecret(clientSecretKey);
      setAddCardLoading(false);
    } catch (error) {
      setAddCardLoading(false);
      notification.error({
        message: "Failed to Add Card Details",
        description: error,
      });
      console.log("addCard  error----->", error);
    }
  };

  const [cardsLoading, setCardsLoading] = useState(false);

  const getCardDetails = async () => {
    try {
      setCardsLoading(true);
      const response = await CALL_API(
        `card/details?clientId=${clientId}`,
        "get",
        {}
      );

      let cards = response;

      const activeCard = cards?.find((card) => card?.status === "active");

      if (activeCard) {
        // shift it to the first index
        const activeCardIndex = cards.indexOf(activeCard);
        cards.splice(activeCardIndex, 1);
        cards.unshift(activeCard);
      }

      setAtmCards(cards || []);
      setCardsLoading(false);
      console.log("getCardDetails  response----->", response);
    } catch (error) {
      notification.error({
        message: "Failed to get Card Details",
        description: error,
      });
      setCardsLoading(false);
      console.log("getCardDetails  error----->", error);
    }
  };

  const [planDetails, setPlanDetails] = useState({});

  const getPlanDetails = async () => {
    try {
      const response = await CALL_API(
        `plan/details?clientId=${clientId}`,
        "get"
      );
      console.log("getPlanDetails  response----->", response);
      setPlanDetails(response);
    } catch (error) {
      notification.error({
        message: "Failed to get plan Details",
        description: error,
      });
      console.log("getPlanDetails  error----->", error);
    }
  };

  const [paymentHistoryData, setPaymentHistoryData] = useState([{}]);

  const getPaymentHistory = async () => {
    try {
      const response = await CALL_API(
        `plan/history?clientId=${clientId}`,
        "get"
      );

      setPaymentHistoryData(
        response.map((payment) => {
          return {
            paymentMethod: payment?.cardDetails?.brand,
            dateOfPayment: moment(payment?.createdDate).format("Do MMMM YYYY"),
            timeOfPayment: moment(payment?.createdDate).format("h:mm a"),
            amountPaid: `$${payment?.amount}`,
            status: payment?.paymentStatus,
          };
        })
      );
      console.log("getPaymentHistory  response----->", response);
    } catch (error) {
      notification.error({
        message: "Failed to get Payment History Details",
        description: error,
      });
      console.log("getPaymentHistory  error----->", error);
    }
  };

  useEffect(() => {
    getCardDetails();
    getPlanDetails();
    getPaymentHistory();
  }, []);

  return (
    <PaymentWrap>
      <div className="title">Subscription</div>
      <div className="card-section">
        <Card>
          <div className="left">
            {hasActivePlan ? (
              <div className="current-plan">
                {currentPlan?.planType}
                <BilledMonthly />
              </div>
            ) : (
              <div className="active-employees">
                {planDetails?.totalActiveUsers} Active Employees
              </div>
            )}

            <div className="content">
              <div className={`left-content ${hasActivePlan ? "present" : ""}`}>
                {hasActivePlan ? (
                  <>
                    <div className="plan-cost">
                      ${planDetails?.perUser} per user, per month
                    </div>
                    <div className="plan-validthru">
                      (Valid till{" "}
                      {moment(planDetails?.nextPaymentDate).format(
                        "Do MMMM YYYY"
                      )}
                      )
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="right-content">
                {hasActivePlan ? (
                  <>
                    <div className="plan-employees">
                      {planDetails?.totalActiveUsers} Active Employees
                    </div>
                    <div className="plan-amount">
                      Amount: ${planDetails?.amount}
                      <span className="upcoming-month"> (Upcoming Month)</span>
                    </div>
                  </>
                ) : (
                  <div className="free-trial-warning">
                    <div className="warning">
                      <CircleErrorIcon /> {trialPeriodEndsIn} days Left
                    </div>
                    <div className="note">Free Trial Ending soon</div>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex gap-3">
              <Button
                onClick={() => {
                  setShowSubscribeFormModal(true);
                }}
              >
                Explore Subscription
              </Button>

              {hasActivePlan && isCurrentPlanInActive && (
                <Button type="primary">Renew</Button>
              )}
            </div>
          </div>
        </Card>
        <Card>
          <div className="right">
            {atmCards.length > 0 ? (
              <AtmCards
                atmCards={atmCards}
                addCard={addCard}
                addCardLoading={addCardLoading}
                getCardDetails={getCardDetails}
                cardsLoading={cardsLoading}
              />
            ) : (
              <div
                className="add-card"
                onClick={() => {
                  addCard();
                }}
              >
                <AddBoxIcon /> Add Card Details
              </div>
            )}
          </div>
        </Card>
      </div>

      {!hasActivePlan && (
        <>
          <div className="note-1">
            Subscribe now to keep enjoying personalized features, unlimited
            access, and premium support.
          </div>

          <div className="note-2">
            Need any help? Please contact our support team at{" "}
            <a href="mailto:info@tymeplus.co">info@tymeplus.co</a>
          </div>
        </>
      )}

      {paymentHistoryData?.length > 0 && (
        <>
          <div className="title mb-0">Payment History</div>
          <Table
            dataSource={paymentHistoryData}
            columns={paymentHistoryColumns}
            rowKey="department_id"
            pagination={false}
          />
        </>
      )}

      <Modal
        open={showSubscribeFormModal}
        footer={false}
        onCancel={handleCancelSubscribeFormModal}
        destroyOnClose={true}
        width={1150}
      >
        {plan !== "" ? (
          clientSecret &&
          stripePromise && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
                appearance: {},
              }}
            >
              <Subscribe
                data={{
                  clientSecret: clientSecret,
                }}
                setShowSubscribeFormModal={setShowSubscribeFormModal}
                plan={plan}
                setPlan={setPlan}
              />
            </Elements>
          )
        ) : (
          <Pricing
            setClientSecret={setClientSecret}
            setPlan={setPlan}
            currentPlan={currentPlan}
            setShowSubscribeFormModal={setShowSubscribeFormModal}
          />
        )}
      </Modal>

      <Modal
        open={showAddAtmCardModal}
        footer={false}
        onCancel={handleCancelAtmCardsModal}
      >
        {clientSecret && stripePromise && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
            }}
          >
            <AddCard
              data={{
                clientSecret: clientSecret,
              }}
              setShowAddAtmCardModal={setShowAddAtmCardModal}
              getCardDetails={getCardDetails}
            />
          </Elements>
        )}
      </Modal>
    </PaymentWrap>
  );
};

export default Payment;

const AtmCards = ({
  atmCards,
  addCard,
  addCardLoading,
  getCardDetails,
  cardsLoading,
}) => {
  const hasMoreThanTwoCards = atmCards.length > 2;

  const [showAtmCards, setShowAtmCards] = useState(false);

  const handleCancelAtmCardsModal = () => {
    setShowAtmCards(false);
  };

  return cardsLoading ? (
    <Spin spinning={true} />
  ) : (
    <div>
      <div className="title">
        <span>Cards</span>
        {addCardLoading ? (
          <Spin spinning={true} />
        ) : (
          <span
            className="add"
            onClick={() => {
              addCard();
            }}
          >
            <AddIcon fontSize="small" /> Add
          </span>
        )}
      </div>

      <div className="atm-cards">
        {atmCards.map((card, index) => {
          return (
            index < 2 && (
              <AtmCard
                key={card.id}
                card={card}
                getCardDetails={getCardDetails}
                setShowAtmCards={setShowAtmCards}
              />
            )
          );
        })}
      </div>

      {hasMoreThanTwoCards && (
        <div
          className="view-all"
          onClick={() => {
            setShowAtmCards(true);
          }}
        >
          View All
        </div>
      )}

      <Modal
        open={showAtmCards}
        footer={false}
        onCancel={handleCancelAtmCardsModal}
        width={700}
      >
        <div>
          <h4>Choose a Card</h4>
          <ModalCardsWrap>
            {atmCards.map((card, index) => {
              return (
                <AtmCard
                  key={card.id}
                  card={card}
                  getCardDetails={getCardDetails}
                  setShowAtmCards={setShowAtmCards}
                />
              );
            })}
          </ModalCardsWrap>
        </div>
      </Modal>
    </div>
  );
};

export const AtmCard = ({ card, getCardDetails, setShowAtmCards }) => {
  const clientId = useSelector((state) => getClientid(state));
  const [loading, setLoading] = useState(false);

  const activateCard = async () => {
    if (card?.status === "active") {
      return;
    }

    try {
      setLoading(true);
      const data = {
        clientId,
        payment_method: card,
      };

      const response = await CALL_API(`card/change`, "post", data);

      if (response) {
        notification.success({
          message: response,
        });
        getCardDetails();
        setShowAtmCards && setShowAtmCards(false);
      }
      setLoading(false);

      console.log("activateCard  response----->", response);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Failed to activate Card",
        description: error,
      });
      console.log("activateCard  error----->", error);
    }
  };

  return (
    <Card className="atm-card" onClick={activateCard}>
      <div className="card-brand">{card?.brand}</div>
      <div className="card-number">**** **** **** {card?.cardNumber}</div>

      {/* expMonth */}
      {/* expYear */}
      <div className="card-expiry">
        {card?.expMonth}/{card?.expYear}
      </div>

      {loading ? (
        <Spin spinning={true} />
      ) : (
        <div className="d-flex jusitify-content-center align-items-center gap-1">
          {card?.status === "active" ? <CheckedIcon /> : <UnCheckedIcon />}{" "}
          Primary Card
        </div>
      )}
    </Card>
  );
};

const BilledMonthly = () => {
  return <div className="billed-monthly">Billed Monthly</div>;
};

const ModalCardsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  justify-content: center;

  .atm-card {
    border: 1px solid #d9d9d9;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 27px 0px #00000017;
    }
  }
`;
