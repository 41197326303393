import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Spin, Button } from "antd";
import LeaveDonutChart from "./LeaveDonutChart";
import styled from "@emotion/styled";
import { LEAVE_ICON } from "common/Constants";
import { CALL_API } from "common/API";
import { dateInDetail, getKenyanDateTime } from "utils/Date";
import {
  getUserRoleId,
  getClientid,
  getLeaveCategoryList,
  getUserList,
  getUserInfo,
  getRoleList,
} from "redux/selectors";
import { connect } from "react-redux";
import { intersection, isEmpty } from "lodash";
import COLORS from "common/Colors";
import {
  find,
  get,
  includes,
  reduce,
  concat,
  map,
  result,
  filter,
  orderBy,
} from "lodash";
import { CSVLink } from "react-csv";

const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledRow = styled(Row)`
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 5px;
  height: 100%;
  // margin-top: 20px;
`;

const ButtonOutlined = styled(Button)`
  padding: 5px 20px;
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 5px;
  background: transparent;
  font-weight: normal;
  color: ${COLORS.PRIMARY};
  white-space: nowrap;

  &:hover {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  &:focus {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  @media (max-width: 900px) {
    margin: 10px 0px;
  }
`;

function UserLeaveReport({
  ClientId,
  leaveCategoryList,
  userRoleId,
  userList,
  userInfo,
  roleList,
}) {
  const [loading, setLoading] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [userLeaves, setUserLeaves] = useState([]);
  const [CSVDepartmentLeave, setCSVDepartmentLeave] = useState([]);
  const csvLinkDepartment = useRef();
  const getCheckinData = async () => {
    setLoading(true);
    const response = await CALL_API(
      `employee-leave-details/${ClientId}`,
      "post",
      {
        year: dateInDetail(getKenyanDateTime()).year,
      }
    );
    if (!isEmpty(response)) {
      setLeaveData(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCheckinData();
  }, [ClientId]);

  const filterrolelist = roleList?.filter((val) => val?.roleId === userRoleId);
  const filterdepartmentid = filterrolelist[0]?.department;
  const filterlocationid = filterrolelist[0]?.locationId;

  const filterleaves = [];
  leaveCategoryList?.forEach((item) => {
    if (item?.locationId?.length > 0) {
      if (intersection(item?.locationId, filterlocationid).length > 0) {
        filterleaves.push(item);
      }
    }
  });

  console.log("filterleaves--->", filterleaves);

  const filteruser = userList?.filter(
    (item) => item.userid === userInfo?.userid
  );
  const filtergender = filteruser[0]?.gender;
  const filteredCategoriesbygender =
    filtergender?.toLowerCase() === "male"
      ? filterleaves?.filter(
          (item) => item.eligible?.toLowerCase() !== "female"
        )
      : filterleaves?.filter((item) => item.eligible?.toLowerCase() !== "male");

  const CSVHeaders = [
    { label: "Leave Category Name" },
    { label: "Available Leaves" },
    { label: "Consumed Leaves" },
    { label: "Total Leaves" },
  ];
  const prepareCSV = async (event, done) => {
    if (leaveData.length > 0) {
      setCSVDepartmentLeave(
        reduce(
          leaveData,
          function (result, leave) {
            const leaveType = Object.keys(leave)[0];
            const availableLeave = leave[leaveType].find(
              (item) => item.type === "available"
            ).value;
            const consumedLeave = leave[leaveType].find(
              (item) => item.type === "consumed"
            ).value;

            result.push({
              "Leave Category Name": leaveType,
              "Available Leaves": availableLeave - consumedLeave,
              "Consumed Leaves": consumedLeave,
              "Total Leaves": availableLeave,
            });
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkDepartment.current.link.click();
      });
    } else {
      console.log("No data available to download");
    }
  };

  return (
    <StyledRow>
      <Row gutter={16}>
        <Col span={12}>
          <div>
            <StyledTitle>LEAVE REPORT</StyledTitle>
          </div>
        </Col>
        <Col span={12} style={{ justifyContent: "flex-end", display: "flex" }}>
          <ButtonOutlined
            onClick={prepareCSV}
            disabled={leaveData.length === 0}
          >
            Export CSV file
          </ButtonOutlined>
          <CSVLink
            header={CSVHeaders}
            data={CSVDepartmentLeave}
            filename={"Department Leave List.csv"}
            asyncOnClick={true}
            ref={csvLinkDepartment}
          />
        </Col>
        {console.log("leavedata-->", leaveData)}
        {filteredCategoriesbygender.map((data) => {
          return (
            <Col
              md={24}
              sm={24}
              xs={24}
              xl={12}
              key={data.leave_category_short_name}
            >
              <Spin spinning={loading}>
                <LeaveDonutChart
                  iconName={LEAVE_ICON[7]}
                  title={`${data.leave_category_name} - ${new Date()
                    .getFullYear()
                    .toString()}`}
                  data={
                    leaveData?.find(
                      (item) => item[data?.leave_category_short_name]
                    )?.[data?.leave_category_short_name] ?? []
                  }
                />
              </Spin>
            </Col>
          );
        })}
      </Row>
    </StyledRow>
  );
}
const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  userList: getUserList(state),
  userInfo: getUserInfo(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps, null)(UserLeaveReport);
