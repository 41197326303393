import React, { useState, useEffect } from 'react';
import { CALL_API } from 'common/API'
import UITabs from 'atoms/UITabs';
import Styled from "styled-components";
import { Table } from "antd";
import moment from 'moment';

const StyledContainer = Styled.div`
.margin_space{
    margin-bottom: 10px;
    font-weight:400;
}
.tab_space{
    padding:0 10px 0 10px; 
}
.ant-table-thead{
    background: #FAFAFA;
    font-weight:500;
    font-size:16px;
}

.ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius:8px;
}
.ant-table-container table > thead > tr:first-child th:last-child{
    border-top-right-radius:8px;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    height:0;
}
.ant-table{
    border: 1px solid #E2E2E8;
    border-radius: 8px;
}
.ant-table-tbody > tr > td{
    color:#636980;
font-size: 14px;
font-weight: 400;
line-height: 18.23px;

}

`;

const BackOffice = () => {
    const [activeTab, setActiveTab] = useState(1);
    const handleTabChange = (newValue) => {
        setActiveTab(newValue);
    };

    const [activeData, setActiveData] = useState([]);
    const [inActiveData, setInActiveData] = useState([]);
    const [paymentFailedData, setPaymentFailedData] = useState([]);

    const getFullTableData = async () => {
        const response = await CALL_API(`/backoffice/clients`, "get");
        const activeClients = response?.data?.getClientDetails?.activeClients || [];
        const inActiveClient = response?.data?.getClientDetails?.inactiveClients
        const paymentFailClient = response?.data?.failedPaymentDetails

        let customiseArr = [];
        let inactiveCust = [];
        let paymentFail = [];

        activeClients.forEach(client => {
            let customisedClient = {
                name: client.personName ? client.personName : "-",
                clientId: client.clientId ? client.clientId : "-",
                userid: client.userId ? client.userId : "-",
                companyName: client.companyName ? client.companyName : "-",
                employees: client.usersCount,
                subscription: client.subscriptionPlan ? client.subscriptionPlan : "-"
            };
            customiseArr.push(customisedClient);
        });
        inActiveClient.forEach(client => {
            let customisedInactClient = {
                name: client.personName ? client.personName : "-",
                clientId: client.clientId ? client.clientId : "-",
                userid: client.userId ? client.userId : "-",
                companyName: client.companyName ? client.companyName : "-",
                employees: client.usersCount,
                subscription: client.subscriptionPlan ? client.subscriptionPlan : "-",
                lastpaid: moment(client.trailperiodEndOn).format("DD MMM YYYY")
            }
            inactiveCust.push(customisedInactClient);
        })
        paymentFailClient.forEach(client => {
            let paymentClientFail = {
                name: client.personName ? client.personName : "-",
                clientId: client.clientId ? client.clientId : "-",
                userid: client.userId ? client.userId : "-",
                companyName: client.companyName ? client.companyName : "-",
                employees: client.usersCount,
                subscription: client.subscriptionPlan ? client.subscriptionPlan : "-",
                lastpaid: `${moment(client.trailperiodEndOn).format("DD MMM YYYY") + "; " + moment(client.trailperiodEndOn).format('LT')}`
            }
            paymentFail.push(paymentClientFail);

        })


        setInActiveData(inactiveCust)
        setActiveData(customiseArr);
        setPaymentFailedData(paymentFail)
    }

    useEffect(() => {
        getFullTableData();
    }, [])

    const inpaymentcolumns = [

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "10%",
        },
        {
            title: "Client ID",
            dataIndex: "clientId",
            key: "clientId",
            width: "5%",
        },
        {
            title: "User/Email ID",
            dataIndex: "userid",
            key: "userid",
            width: "10%",
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName",
            width: "5%",

        },
        {
            title: "Employees",
            dataIndex: "employees",
            key: "employees",
            width: "5%",
        },
        {
            title: "Subscription",
            dataIndex: "subscription",
            key: "subscription",
            width: "7%",
        },
        {
            title: "Failed on",
            dataIndex: "lastpaid",
            key: "lastpaid",
            width: "12%",
        }
    ];
    const inactivecolumns = [

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "10%",
        },
        {
            title: "Client ID",
            dataIndex: "clientId",
            key: "clientId",
            width: "5%",
        },
        {
            title: "User/Email ID",
            dataIndex: "userid",
            key: "userid",
            width: "10%",
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName",
            width: "5%",

        },
        {
            title: "Employees",
            dataIndex: "employees",
            key: "employees",
            width: "5%",
        },
        {
            title: "Subscription",
            dataIndex: "subscription",
            key: "subscription",
            width: "7%",
        },
        {
            title: "Lastly Paid on",
            dataIndex: "lastpaid",
            key: "lastpaid",
            width: "7%",
        }
    ];
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "7%",
        },
        {
            title: "Client ID",
            dataIndex: "clientId",
            key: "clientId",
            width: "5%",
        },
        {
            title: "User/Email ID",
            dataIndex: "userid",
            key: "userid",
            width: "10%",
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName",
            width: "5%",

        },
        {
            title: "Employees",
            dataIndex: "employees",
            key: "employees",
            width: "5%",
        },
        {
            title: "Subscription",
            dataIndex: "subscription",
            key: "subscription",
            width: "7%",
        }
    ];

    const items = [{
        id: 1,
        label: "Active",
        component: <div className='tab_space'>
            <div className='margin_space'>Total: {activeData.length}</div>
            <Table
                dataSource={activeData}
                columns={columns}
                rowClassName={(record, index) => (index % 2 === 0 ? "" : "#FAFAFA")}
                pagination={false}
            />
        </div>
    },
    {
        id: 2,
        label: "InActive",
        component: <div className='tab_space'>
            <div className='margin_space'>Total: {inActiveData.length}</div>
            <Table
                dataSource={inActiveData}
                columns={inactivecolumns}
                rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
                pagination={false}
            />
        </div>
    },
    {
        id: 3,
        label: "Payment Failed",
        component: <div className='tab_space'>
            <div className='margin_space'>Total: {paymentFailedData.length}</div>
            <Table
                dataSource={paymentFailedData}
                columns={inpaymentcolumns}
                rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
                pagination={false}
            />
        </div>
    }];
    return (
        <StyledContainer>
            <h4>Clients</h4>
            <UITabs items={items}
                activeTab={activeTab} onTabChange={handleTabChange}
            />
        </StyledContainer>
    )
}

export default BackOffice