const queryKeys = {
  getActivityLog: (queryparams) => ({
    key: `get-activity-log-${queryparams}`,
    url: `activitylog${queryparams}`,
  }),
  getPermissionById: (clientId, roleId) => ({
    key: `get-roles-by-name-${clientId}-${roleId}`,
    url: `permissions/${clientId}/${roleId}?LastEvaluatedKey=0`,
  }),
 
};

export default queryKeys;
