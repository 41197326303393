import { Col, Modal, Row, Switch } from "antd";
import Icon from "atoms/Icon";
import React from "react";
import { connect } from "react-redux";
import {
  getAllDepartmentList,
  getLocations,
  getRoleList,
} from "redux/selectors";
import { ButtonFilled } from "reusableComponent/UIButtons";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .title {
    font-size: 20px;
  }
  .headtext {
    color: #7b8093;
    font-size: 16px;
  }
  .valuetext {
    color: #000;
    font-size: 14px;
  }
`;
const StyledCol = styled(Col)`
  margin: 10px;
  .normal-column {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .info_heading {
    font-size: 14px;
    font-weight: 600;
    color: #636980;
  }
  .info_datas {
    font-weight: 500;
    color: #1f243b;
  }
  .inreview_title {
    font-size: 16px;
    font-weight: 600;
    width: 26%;
  }
  .inreview_boxes_container {
    display: flex;
    gap: 1rem;
  }
  .roles_location_review {
    width: max-content;
    min-height: 28px;
    font-size: 14px;
    font-weight: 400;
    color: #1f243b;
    padding: 5px 12px;
    border-radius: 4px;
    color: #1f243b;
    background-color: #f4f4f4;
    margin-bottom: 0;
  }
  .roles_location_review_section {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .roles_location_review_wrap {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .inreview_col_section {
      width: 30%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .inreview_col_section {
      width: 50%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .inreview_col_section {
      width: 50%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .inreview_boxes_container {
      flex-direction: column;
    }
    .inreview_col_section {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
`;

const DetailsPopup = ({
  isopen,
  isCancel,
  title,
  department,
  location,
  departmentList,
  locationList,
  employeeCount,
  onEdit,
  onDelete,
  details,
  isDisable
}) => {
  const departmentdata =
    departmentList?.filter((item) =>
      department?.includes(item.department_id)
    ) || null;

  const locationdata =
    locationList?.filter((item) => location?.includes(item.location_id)) ||
    null;
    

    const cursorstyle = {cursor: "pointer"}

  return (
    <StyledModal
      title={
        <div className="d-flex justify-content-between align-items-center">
          <span className="title">{title}</span>
          <div className="d-flex justify-content-between align-items-center gap-3 px-4">
            <Icon name="trash" style={cursorstyle} onClick={()=>onDelete(details)}/>
            <Switch size="small" checked={details?.roleStatus === "Enabled"} onChange={()=>{isCancel();isDisable()}}></Switch>
            <Icon name="edit" style={cursorstyle} onClick={onEdit}/>
          </div>
        </div>
      }
      width={700}
      open={isopen}
      onCancel={isCancel}
      footer={[]}
      centered
    >
      <div className="">
        <p className="headtext mb-0">
          No of Employee: <span className="valuetext">{employeeCount}</span>
        </p>
        <p className="headtext mb-0">
          No of department:{" "}
          <span className="valuetext">{department?.length}</span>
        </p>
        <Row className="w-100">
          {departmentdata?.length > 0 && (
            <StyledCol>
              <Row className="roles_location_review_wrap">
                {departmentdata?.map((item, key) => {
                  return (
                    <>
                      <Col className="roles_location_review_section" key={key}>
                        <p className="roles_location_review" key={item}>
                          {item?.department_name}
                        </p>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </StyledCol>
          )}
        </Row>
        <p className="headtext mb-0">
          No of location: <span className="valuetext">{location?.length}</span>
        </p>
        {locationdata?.length > 0 && (
          <StyledCol>
            <Row className="roles_location_review_wrap">
              {locationdata?.map((item, key) => {
                return (
                  <>
                    <Col className="roles_location_review_section" key={key}>
                      <p className="roles_location_review" key={item}>
                        {item?.location_name}
                      </p>
                    </Col>
                  </>
                );
              })}
            </Row>
          </StyledCol>
        )}
      </div>
    </StyledModal>
  );
};
const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  departmentList: getAllDepartmentList(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps)(DetailsPopup);
