import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Checkbox,
  Switch,
  Upload,
} from "antd";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import moment from "moment";
import {
  NON_EMPTY_REGEX,
  EMAIL_REGEX,
  STATUS_CODE,
  countryList,
  TIMEZONELIST,
  AwardList,
  CountryData,
  VALIDATE_CHARACTER,
} from "common/Constants";
import SelectTable from "../molecules/SelectTable";
import InReview from "../organisms/FormReview";
import { bindActionCreators } from "redux";
import {
  getAllDepartmentList,
  getLocations,
  getClientid,
  getLeaveCategoryList,
  getRoleList,
  getUserList,
  getBandList,
  getUserMenuList,
  getProductInfo,
  getUserInfo,
} from "redux/selectors";
import { setRoleList, setUserList } from "redux/actions";
import { chain, get, includes, isEmpty } from "lodash";
import { useDropzone } from "react-dropzone";
import StepsProgress from "./StepsProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormModal from "./FormModal";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { CloseOutlined } from "@mui/icons-material";
import RolesAndPermissions from "pages/RolesAndPermissions";
import { jwtDecode } from "jwt-decode";

const StyledModal = styled(Form)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1b1b1b;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #26a4ff;
    color: #ffffff;
  }
  .check-box {
    font-size: 14px;
    font-weight: 500;
  }
  .add_employee_col {
    padding: 5px;
  }
  .review_data_box_container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: wrap;
  }
  .review_data_box {
    width: auto;
    padding: 1rem 1.4rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000015;
  }
  .review_datas {
    display: flex;
    justify-content: space-between;
    text-align: left;
    gap: 1rem;
  }
  .review_datas_key {
    text-align: left;
    font: normal normal bold 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .review_datas_value {
    text-align: left;
    font: normal normal normal 16px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
  .review_title {
    border-bottom: 1.5px solid #d9d9d9;
    width: fit-content;
    text-align: left;
    font: normal normal Bold 18px/20px Circular Std;
    letter-spacing: 0px;
    color: #000000;
  }
`;

const StyleRow = styled(Row)`
  /* padding: 10px; */
  /* box-shadow: 0px 3px 6px #00000015; */
  .ant-form-item-label {
    text-align: left;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      position: relative;
      height: 40px !important;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      color: #397ff4;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;
const StyledDropzone = styled.div`
  text-align: center;
  width: 100%;
  height: 40px;
  background: #e3e3e3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    padding-top: 10px;
  }
`;

function AddEmployee({
  closeModal,
  getEmployeeList,
  EmployeeList,
  ClientId,
  updateUserList,
  roleList,
  bandList,
  locationList,
  allDepartmentList,
  userMenuList,
  clientId,
  saveRoleList,
  productInfo,
  userList,
  userInfo,
  selectedDetails = {},
}) {
  const [showLoader, toggleLoader] = useState(false);
  const [filename, setFileName] = useState("");
  const [form] = Form.useForm();
  const { Option } = Select;
  const containerRef = useRef(null);
  const [profileUploaded, setProfileUploaded] = useState(false);
  const [uniqueId, setUniqueId] = useState(generateUniqueId());
  const [storeId, setStoreId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [baseLocation, setBaseLocation] = useState("");
  const [baseDepartment, setBaseDepartment] = useState("");
  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);
  const [menuList, setMenuList] = useState(userMenuList);

  useEffect(() => {
    const {
      userid = "",
      startdate = "",
      dateofbirth = "",
      enddate = "",
      bandname = "",
      bandid = "",
      overtimestatus = "",
      salary = "",
      password = "",
      profileurl = "",
      gender = "",
      maritalstatus = "",
      nationality = "",
      roleid = "",
      nssf = "",
      national_id = "",
      address = "",
      phoneno = "",
      companyname = "",
      jobtitle = "",
      professionalsince = "",
      hireddate = "",
      probationstartdate = "",
      probationenddate = "",
      contractstartdate = "",
      contractenddate = "",
      relevant = "",
      employeesponser = "",
      statusname = "",
      jobdescription = "",
      emergencycontactname = "",
      emergencycontactnumber = "",
      employementtype = "",
      employeeid = "",
      initial,
      firstname,
      lastname,
      salutation,
      // competencyrole,
      // industry,
      // sector,
      // industryrole,
      // grade,
      educationdetails,
      workexperience,
      professionalcertifications,
      practicaltrainings,
      lastpromotionlevel,
      lastpromotiondate,
      reportingmanager,
      // supervisor,
    } = selectedDetails;

    setFormValues({
      user_id: userid,
      gender: gender,
      date_of_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      password: password,
      marital_status: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandName: bandname,
      bandid: bandid,
      overtime: overtimestatus,
      salary: salary,
      employementtype: employementtype,
      profile_url: profileurl !== ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      nssf: nssf,
      national_id: national_id,
      address: address,
      phone: phoneno,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      employementtype: employementtype ? employementtype : "",
      probationstartdate: probationstartdate
        ? moment(probationstartdate, "YYYY-MM-DD")
        : null,
      probationenddate: probationenddate
        ? moment(probationenddate, "YYYY-MM-DD")
        : null,
      contractstartdate: contractstartdate
        ? moment(contractstartdate, "YYYY-MM-DD")
        : null,
      contractenddate: contractenddate
        ? moment(contractenddate, "YYYY-MM-DD")
        : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      status_id: employeeStatusId,
      description: jobdescription,
      emergencycontactname: emergencycontactname,
      emergencycontactnumber: emergencycontactnumber,
      employeeid: employeeid,
      initial: initial,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      lastpromotionlevel: lastpromotionlevel,
      reportingmanager: reportingmanager,
      lastpromotiondate: lastpromotiondate
        ? moment(startdate, "YYYY-MM-DD")
        : null,
      // competencyrole: competencyrole,
      // industry: industry,
      // sector: sector,
      // industryrole: industryrole,
      educationdetails:
        educationdetails?.length !== 0
          ? educationdetails
          : [
              {
                institutename: "",
                fromdate: "",
                todate: "",
                exambody: "",
                awarded: "",
              },
            ],
      workexperience:
        workexperience?.length !== 0
          ? workexperience
          : [
              {
                companyname: "",
                fromdate: "",
                todate: "",
                jobtitle: "",
                relevnt: "",
                jobdescription: "",
              },
            ],
      professionalcertifications:
        professionalcertifications?.length !== 0
          ? professionalcertifications
          : [
              {
                certificationtype: "",
                fromdate: "",
                todate: "",
                remarks: "",
              },
            ],
      practicaltrainings:
        practicaltrainings?.length !== 0
          ? practicaltrainings
          : [
              {
                organisationname: "",
                fromdate: "",
                todate: "",
                positionheld: "",
                tasksperformed: "",
              },
            ],
    });
    const transformedDetails = (data) =>
      data?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = moment(fromdate);
        const formattedToDate = moment(todate);
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
    form.setFieldsValue({
      user_id: userid,
      password: password,
      gender: gender,
      date_of_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      marital_status: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandid: bandid,
      overtime: overtimestatus,
      salary: salary,
      profile_url: profileurl != ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      nssf: nssf,
      national_id: national_id,
      address: address,
      employementtype: employementtype,
      phone: phoneno,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      probationstartdate: probationstartdate
        ? moment(probationstartdate, "YYYY-MM-DD")
        : null,
      probationenddate: probationenddate
        ? moment(probationenddate, "YYYY-MM-DD")
        : null,
      contractstartdate: contractstartdate
        ? moment(contractstartdate, "YYYY-MM-DD")
        : null,
      contractenddate: contractenddate
        ? moment(contractenddate, "YYYY-MM-DD")
        : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      description: jobdescription,
      emergencycontactnumber: emergencycontactnumber,
      emergencycontactname: emergencycontactname,
      employeeid: employeeid,
      initial: initial,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      lastpromotionlevel: lastpromotionlevel,
      lastpromotiondate: lastpromotiondate
        ? moment(startdate, "YYYY-MM-DD")
        : null,
      reportingmanager: reportingmanager,
      educationdetails: educationdetails
        ? transformedDetails(educationdetails)
        : [
            {
              institutename: "",
              fromdate: "",
              todate: "",
              exambody: "",
              awarded: "",
            },
          ],
      workexperience: workexperience
        ? transformedDetails(workexperience)
        : [
            {
              companyname: "",
              fromdate: "",
              todate: "",
              jobtitle: "",
              relevnt: "",
              jobdescription: "",
            },
          ],
      professionalcertifications: professionalcertifications
        ? transformedDetails(professionalcertifications)
        : [
            {
              certificationtype: "",
              fromdate: "",
              todate: "",
              remarks: "",
            },
          ],
      practicaltrainings: practicaltrainings
        ? transformedDetails(practicaltrainings)
        : [
            {
              organisationname: "",
              fromdate: "",
              todate: "",
              positionheld: "",
              tasksperformed: "",
            },
          ],
    });
  }, []);

  const jwtToken = window.localStorage.getItem("token");

  const getCountryCodeByName = (countryName) => {
    const country = CountryData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    return country ? country.code : null;
  };

  const getMaxLengthByCountry = (countryName) => {
    const country = CountryData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    return country ? country.phone_length : null;
  };

  const locationId = chain(roleList)
    .filter((role) => selectedRoleId.includes(role.roleId))
    .flatMap((role) => role.locationId)
    .uniq()
    .value();

  const locationBasedOnRole = locationList?.filter((val) =>
    locationId.includes(val?.location_id)
  );
  const filterLocationList = locationList?.filter(
    (item) => item?.login_from_anywhere !== 1
  );

  const departmentId = chain(roleList)
    .filter((role) => selectedRoleId.includes(role.roleId))
    .flatMap((role) => role.department)
    .uniq()
    .value();

  const departmentBasedOnRole = allDepartmentList?.filter((val) =>
    departmentId.includes(val?.department_id)
  );

  const [employeeStatusId, setEmployeeStatusId] = useState("1");
  const filterDepartment = roleList?.find((item) =>
    form.getFieldValue("role_id")?.includes(item?.roleId)
  )?.department;

  const [emailsList, setEmailList] = useState([]);

  const getEmailList = async () => {
    const response = await CALL_API(`add-users/email-list`, "get");
    if (response.code === STATUS_CODE.SUCCESS) setEmailList(response.response);
  };

  useEffect(() => {
    setSelectAllRoleChecked(selectedRoleId?.length === roleList?.length);
  }, [selectedRoleId, selectAllRoleChecked, roleList]);

  useEffect(() => {
    setProfileUploaded(false);
    getEmailList();
  }, []);

  const emailList = EmployeeList?.map((user) => user.userid);

  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({});

  const [reviewBasicData, setReviewBasicData] = useState({});
  const [reviewEducationData, setReviewEducationData] = useState({});
  const [reviewWorkData, setReviewWorkData] = useState({});

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
      saveRoleList(roleList);
    }
  };
  const validateEmployeeId = (rule, value) => {
    if (value && emailsList.includes(value) && isEmpty(selectedDetails)) {
      return Promise.reject("User ID already exist");
    }
    return Promise.resolve();
  };

  // Note: params needed
  const handlesubmit = async (roleId, roleName) => {
    try {
      setUniqueId(generateUniqueId());

      const {
        user_id,
        password,
        gender,
        date_of_birth,
        nationality,
        start_date,
        address,
        phone,
        overtime,
        salary,
        selectedRoleId,
        institute_Name,
        degree,
        specialization,
        company_Name,
        job_Title,
        relevent,
        employee_status,
        marital_status,
        employee_sponser,
        description,
        emergencycontactname,
        emergencycontactnumber,
        employementtype,
        bandid,
        usertimezone,
        salutation,
        initial,
        firstname,
        lastname,
        nssf,
        national_id,
        educationdetails,
        professionalcertifications,
        workexperience,
        practicaltrainings,
        probationstartdate,
        probationenddate,
        contractstartdate,
        contractenddate,
        reportingmanager,
        lastpromotionlevel,
        lastpromotiondate,
      } = formValues;

      const roleNames = roleList
        .filter((item) => selectedRoleId?.includes(item.roleId))
        .map((item) => item.roleName);

      let userId = form.getFieldValue("user_id");
      let splitUserId = userId.split("@");
      let userIdWithoutDomain = splitUserId[0];
      let updatedUserId = userIdWithoutDomain + storeId;
      const dobDate = new Date(date_of_birth);
      const currentDate = new Date();
      const agecalculte = currentDate.getFullYear() - dobDate.getFullYear();
      const bandNameList = bandList?.filter((item) => item?.bandId === bandid);

      const workexperienceDetails = workexperience?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
        const formattedToDate = moment(todate).format("YYYY-MM-DD");
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });

      const transformedEducationDetails = educationdetails?.map((item) => {
        const { attachment, fromdate, todate, ...rest } = item;
        const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
        const formattedToDate = moment(todate).format("YYYY-MM-DD");
        const updatedAttachment = attachment?.map((att) => ({
          name: att?.name,
          uniqueId: att?.response?.uniqueId,
        }));
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
          attachment: updatedAttachment,
        };
      });

      const practicaltrainingsDetails = practicaltrainings?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
        const formattedToDate = moment(todate).format("YYYY-MM-DD");
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
      const professionalcertificationsDetails = professionalcertifications?.map(
        (item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
          const formattedToDate = moment(todate).format("YYYY-MM-DD");
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        }
      );

      const response = await CALL_API(`add-users/${ClientId}`, "post", {
        user_id: user_id?.toLowerCase(),
        full_name: `${firstname} ${lastname}`,
        password: password,
        start_date: start_date
          ? moment(start_date?._d).format("YYYY-MM-DD")
          : "",
        dateofbirth: date_of_birth
          ? moment(date_of_birth?._d).format("YYYY-MM-DD")
          : "",
        gender: gender,
        age: agecalculte,
        maritalstatus: marital_status,
        nationality: nationality,
        role_id: [roleId],
        bandname: bandNameList?.length !== 0 ? bandNameList[0]?.bandName : "",
        bandid: bandid,
        employementtype: employementtype ? employementtype : "",
        role_name: [roleName],
        institutename: institute_Name,
        degree: degree,
        specialization: specialization,
        yearofcompletion: formValues?.year_of_completion
          ? moment(formValues?.year_of_completion?._d).format("YYYY-MM-DD")
          : "",
        companyname: company_Name,
        jobtitle: job_Title,
        professionalsince: formValues?.professional_since
          ? moment(formValues?.professional_since._d).format("YYYY-MM-DD")
          : "",
        hireddate: formValues?.hired_date
          ? moment(formValues?.hired_date._d).format("YYYY-MM-DD")
          : "",
        relevant: relevent,
        employeesponser: employee_sponser,
        statusname: employee_status,
        jobdescription: description,
        status_id: employeeStatusId,
        staff_department_id:
          filterDepartment && filterDepartment?.length > 0
            ? filterDepartment[0]
            : "",
        overtime_status: overtime,
        salary: salary ? salary : "",
        address: address,
        phoneno: phone,
        profile_url: profileUploaded
          ? `https://tymeplushr-profiledev.s3.ap-south-1.amazonaws.com/${ClientId}/${updatedUserId}/${storeId}.jpg`
          : null,
        presentaddress: address,
        permanentaddress: address,
        emergencycontactname: emergencycontactname,
        emergencycontactnumber: emergencycontactnumber,
        baselocationid: baseLocation,
        basedepartmentid: baseDepartment,
        usertimezone: usertimezone,
        salutation: salutation,
        initial: initial,
        firstname: firstname,
        lastname: lastname,
        nssf: nssf,
        national_id: national_id,
        educationdetails: transformedEducationDetails,
        professionalcertifications: professionalcertificationsDetails,
        workexperience: workexperienceDetails,
        practicaltrainings: practicaltrainingsDetails,
        reportingmanager: reportingmanager,
        lastpromotionlevel: lastpromotionlevel,
        lastpromotiondate: moment(lastpromotiondate).format("DD-MM-YYYY"),
        probationstartdate: probationstartdate
          ? moment(probationstartdate?._d).format("YYYY-MM-DD")
          : "",
        probationenddate: probationenddate
          ? moment(probationenddate?._d).format("YYYY-MM-DD")
          : "",
        contractstartdate: contractstartdate
          ? moment(contractstartdate?._d).format("YYYY-MM-DD")
          : "",
        contractenddate: contractenddate
          ? moment(contractenddate?._d).format("YYYY-MM-DD")
          : "",
        onboardingemployee: isEmpty(selectedDetails) ? "" : "onboarding",
      });

      toggleLoader(false);
      form.resetFields();
      setFileName("");
      closeModal(true);

      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
          // handlePermissionSubmit();
        }
      }
      getEmployeeList();
      fetchMasterData(true);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId?.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  const Reset = () => {
    form.resetFields();
    setFileName("");
  };

  const onFinish = (step) => {
    const currentFormValues = form.getFieldValue();

    const updatedFormValues = {
      ...currentFormValues,
      selectedRoleId,
    };

    setFormValues(updatedFormValues);
    setStep(step);
    if (step === 5) {
      setReviewBasicData({
        initial: formValues?.initial,
        firstname: formValues?.firstname,
        lastname: formValues?.lastname,
        user_id: formValues?.user_id,
        date_of_birth: formValues?.date_of_birth
          ? moment(formValues?.date_of_birth?._d).format("YYYY-MMMM-DD")
          : "",
        password: formValues?.password
          ? "*".repeat(formValues.password?.length - 2) +
            formValues.password.slice(-2)
          : "",
        gender: formValues?.gender,
        phone: formValues?.phone,
        marital_status: formValues?.marital_status,
        nationality: formValues?.nationality,
        address: formValues?.address,
        overtime: formValues?.overtime,
        start_date: formValues?.start_date
          ? moment(formValues?.start_date?._d).format("YYYY-MMMM-DD")
          : "-",
        EmergencyContactName: formValues?.emergencycontactname,
        EmergencyContactNumber: formValues?.emergencycontactnumber,
        employementtype: formValues?.employementtype,
      });

      setReviewEducationData({
        institute_Name: formValues?.institute_Name,
        degree: formValues?.degree,
        specialization: formValues?.specialization,
        year_of_completion: formValues?.year_of_completion
          ? moment(formValues?.year_of_completion?._d).format("YYYY-MMMM-DD")
          : "-",
      });
    }
  };

  useEffect(() => {
    setReviewWorkData({
      company_Name: formValues?.company_Name,
      job_Title: formValues?.job_Title,
      employee_sponser: formValues?.employee_sponser,
      description: formValues?.description,
      relevent: formValues?.relevent,
      professional_since: formValues?.professional_since
        ? moment(formValues?.professional_since?._d).format("YYYY")
        : "",
      hired_date: formValues?.hired_date
        ? moment(formValues?.hired_date?._d).format("YYYY-MMMM-DD")
        : "",
      employee_status: formValues?.employee_status,
    });
  }, [step]);

  //Note: need to future

  // const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  // useEffect(() => {
  //   console.log('form.getFieldValue("user_id")',form.getFieldValue("user_id"))
  //   if (form.getFieldValue("user_id")) {
  //     acceptedFiles.map((file) => {
  //       const reader = new FileReader();
  //       reader.onload = async function (e) {
  //         if (
  //           includes(
  //             [
  //               "image/jpeg",
  //               "image/jpg",
  //               "image/jpeg",
  //               "image/png",
  //               "image/tif",
  //               "image/tiff",
  //               "png",
  //               "jpg",
  //               "tif",
  //             ],
  //             get(file, "type", "")
  //           )
  //         ) {
  //           setFileName(acceptedFiles[0].path);
  //           setProfileUploaded(true);
  //           setUniqueId(generateUniqueId());
  //           let userId = form.getFieldValue("user_id");
  //           let splitUserId = userId.split("@");
  //           let userIdWithoutDomain = splitUserId[0];
  //           let updatedUserId = userIdWithoutDomain + uniqueId;
  //           const { code } = await CALL_API(
  //             `profile-upload/${ClientId}`,
  //             "post",
  //             {
  //               userid: updatedUserId,
  //               filetype: get(file, "type", ""),
  //               content: e.target.result,
  //               filename: uniqueId,
  //               emailid: "",
  //             }
  //           );
  //           if (code === STATUS_CODE.SUCCESS) {
  //             setStoreId(uniqueId);
  //           }
  //         } else {
  //           setProfileUploaded(false);
  //           message.error("Please upload valid image file.");
  //         }
  //       };
  //       reader.readAsDataURL(file);
  //       return file;
  //     });
  //   }
  // }, [acceptedFiles]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      // Check if user ID is present
      const userId = form.getFieldValue("user_id");
      if (!userId) {
        message.error("Please Enter User ID");
        return;
      }

      // Process accepted files
      acceptedFiles.forEach(async (file) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          if (
            includes(
              [
                "image/jpeg",
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/tif",
                "image/tiff",
                "png",
                "jpg",
                "tif",
              ],
              get(file, "type", "")
            )
          ) {
            setFileName(file.path);
            setProfileUploaded(true);
            setUniqueId(generateUniqueId());
            const splitUserId = userId.split("@");
            const userIdWithoutDomain = splitUserId[0];
            const updatedUserId = userIdWithoutDomain + uniqueId;
            const { code } = await CALL_API(
              `profile-upload/${ClientId}`,
              "post",
              {
                userid: updatedUserId,
                filetype: get(file, "type", ""),
                content: e.target.result,
                filename: uniqueId,
                emailid: "",
              }
            );
            if (code === STATUS_CODE.SUCCESS) {
              setStoreId(uniqueId);
            }
          } else {
            setProfileUploaded(false);
            message.error("Please upload a valid image file.");
          }
        };
        reader.readAsDataURL(file);
      });
    },
  });

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  //Note:  Email Id Validation required in future
  const validateEmail = (rule, value) => {
    if (value && emailList.includes(value.trim())) {
      return Promise.reject("This Email is already registered");
    }
    return Promise.resolve();
  };

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        return (
          <Switch
            size="small"
            checked={selectAllRoleChecked || selectedRoleId.includes(roleId)}
            onChange={(checked) => getRoleId(roleId, checked)}
          />
        );
      },
    },
  ];

  const baseLocationColumn = [
    {
      title: "Select Base Location",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Timezone",
      dataIndex: "usertimezone",
      key: "usertimezone",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        return (
          <Switch
            size="small"
            checked={baseLocation === location_id}
            onChange={(checked) => setBaseLocation(checked ? location_id : "")}
          />
        );
      },
    },
  ];

  const baseDepartmentColumn = [
    {
      title: "Select Base Department",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id) => {
        return (
          <Switch
            size="small"
            checked={baseDepartment === department_id}
            onChange={(checked) =>
              setBaseDepartment(checked ? department_id : "")
            }
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
  };
  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Department" },
    { id: 3, title: "Location" },
    { id: 4, title: "Education" },
    { id: 5, title: "Work" },
    { id: 6, title: "Permission" },
    { id: 7, title: "Review" },
  ];

  const uploadFile = async (value) => {
    const { file } = value;
    try {
      if (!isEmpty(file)) {
        const { user_id } = formValues;
        await CALL_API(`upload-userdocuments/${ClientId}`, "post", {
          userId: user_id?.toLowerCase(),
          name: file.name,
          type: file.type,
          uniqueId: file.response.uniqueId,
          content: file.response.base64Data,
        });
      }
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

  const handleFileRemove = async (file) => {
    const userId = form.getFieldValue("user_id");
    let splitUserId = userId.split("@");
    let userIdWithoutDomain = splitUserId[0];
    let updatedUserId = userIdWithoutDomain;
    let fileKey = `${updatedUserId}_${file?.response?.uniqueId}_${file?.name
      .split(" ")
      .join("_")}`;
    try {
      await CALL_API(
        `delete-userdocuments?clientid=${ClientId}&filekey=${fileKey}`,
        "delete"
      );
      console.error(
        "[handleFileRemove] success -->",
        "File deleted successfully"
      );
    } catch (error) {
      console.error("[handleFileRemove] error -->", error);
    }
  };

  // const handlePermissionSubmit = async () => {
  //   await CALL_API(`owner-roles/${clientId}`, "post", {
  //     rolename: `${formValues?.firstname} ${formValues?.lastname}`,
  //     location: [baseLocation],
  //     menuList: menuList,
  //   });
  //   fetchMasterData(true);
  //   setStep(0);
  // };

  const handlePermissionSubmit = async () => {
    toggleLoader(true);
    try {
      // Call the API and store the response
      const response = await CALL_API(`owner-roles/${clientId}`, "post", {
        rolename: `${formValues?.firstname} ${formValues?.lastname}`,
        location: [baseLocation],
        menuList: menuList,
        department: [baseDepartment],
      });

      // Check if the API call was successful and handle the response
      if (response?.code === STATUS_CODE.SUCCESS) {
        // API success, handle the next steps
        const roleId = response?.roleData?.roleId;
        const roleName = response?.roleData?.roleName;
        handlesubmit(roleId, roleName);
        fetchMasterData(true);
        // setStep(0);

        // Optionally, you can log the response or display a success message
        console.log("API Response:", response);
      } else {
        // Handle API errors (optional)
        console.error("Error in API response:", response);
        // You can show an error message here if needed
      }
    } catch (error) {
      // Catch and handle any unexpected errors during the API call
      console.error("Error in handlePermissionSubmit:", error);
      // You can show an error message here if needed
    }
  };

  let token = null;
  try {
    if (jwtToken) {
      token = jwtDecode(jwtToken);
    }
  } catch (error) {
    console.error("Invalid token:", error);
  }

  return (
    <>
      <label
        className="bg-transparent border-0 mb-3 text-left styled-cursor"
        onClick={() => closeModal(false)}
      >
        Employee <ArrowForwardIosIcon className="px-1" />
      </label>{" "}
      <b className=" pb-0 pt-1">Add Employee</b>
      <StepsProgress stepdata={stepdata} title="" step={step} />
      <div className="styled-card mb-5">
        {step === 0 && (
          <StyledModal>
            <Form
              className=""
              form={form}
              name="addemployeeform"
              layout="vertical"
              initialValues={{
                initial: "",
                firstname: "",
                lastname: "",
                salutation: "",
                user_id: "",
                department_id: "",
                password: "",
                gender: "",
                date_of_birth: "",
                marital_status: "",
                nationality: "",
                address: "",
                phone: "",
                overtime: "",
                start_date: "",
                halfpay: false,
                bandName: "",
                emergencycontactname: "",
                emergencycontactnumber: "",
                employementtype: "",
                bandid: "",
                national_id: "",
                nssf: "",
                reportingmanager: "",
              }}
              onFinish={() => onFinish(1)}
            >
              <Row gutter={20}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="salutation"
                    label="Salutation"
                    placeholder="Select Salutation"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Salutation is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Salutation"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="Mr.">Mr.</Option>
                      <Option value="Dr.">Dr.</Option>
                      <Option value="Prof.">Prof.</Option>
                      <Option value="Ms.">Ms.</Option>
                      <Option value="Mrs.">Mrs.</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="initial"
                    label="Initial"
                    rules={[
                      {
                        required: false,
                        pattern: NON_EMPTY_REGEX,
                        message: "Initial is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Initial"
                      maxLength={5}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="firstname"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "First name is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="First name"
                      maxLength={50}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="lastname"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Last name is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last name"
                      maxLength={50}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="user_id"
                    label="Email ID"
                    rules={[
                      {
                        required: true,
                        pattern: EMAIL_REGEX,
                        message: "Email ID is required",
                      },
                      { validator: validateEmployeeId },
                    ]}
                  >
                    <Input placeholder="Email ID" maxLength={60} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required.",
                      },
                      {
                        validator: (_, value) => {
                          if (!value || value.trim()?.length === 0) {
                            return Promise.reject("");
                          } else if (value?.length < 8 || value?.length > 15) {
                            return Promise.reject(
                              "Password should be min 8 to max 15 characters."
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      autoComplete="new-password"
                      maxLength={15}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Gender is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select gender"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    label="Date of Birth"
                    name="date_of_birth"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Date of birth is required",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      format="DD-MM-YYYY"
                      placeholder="Select Date of Birth"
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="marital_status"
                    label="Marital Status"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Marital Status is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="single">Single</Option>
                      <Option value="married">Married</Option>
                      <Option value="widow">Widow</Option>
                      <Option value="divorced">Divorced</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="reportingmanager"
                    label="Reporting Manager"
                    rules={[
                      {
                        required: userList?.length !== 0 ? true : false,
                        pattern: NON_EMPTY_REGEX,
                        message: "Reporting Manager is required",
                      },
                    ]}
                  >
                    <Select
                      className="selectbox mb-2"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.name ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.name ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.name ?? "").toLowerCase())
                      }
                      placeholder="Select Manager"
                      options={userList?.filter(
                        (item) =>
                          item?.userid !== userInfo?.userid &&
                          item?.statusid === "1"
                      )}
                      fieldNames={{
                        label: "fullname",
                        value: "userid",
                      }}
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="nationality"
                    label="Country"
                    rules={[{ required: true, message: "Country is required" }]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      getPopupContainer={() => containerRef.current}
                    >
                      {countryList?.map((country, index) => (
                        <Select.Option
                          key={index}
                          value={country}
                          label={country}
                        >
                          {country}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="phone" label="Phone Number">
                    <PhoneInput
                      type="text"
                      defaultCountry={
                        form.getFieldValue("nationality")
                          ? getCountryCodeByName(
                              form.getFieldValue("nationality")
                            )
                          : ""
                      }
                      placeholder="Phone number"
                      maxLength={
                        form.getFieldValue("nationality")
                          ? Number(
                              getMaxLengthByCountry(
                                form.getFieldValue("nationality")
                              )
                            ) + 2
                          : 15
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="emergencycontactname"
                    label="Emergency Contact Name"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Emergency Contact Name is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Emergency Contact Name"
                      maxLength={15}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="emergencycontactnumber"
                    label="Emergency Contact Number"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Emergency Contact Number is required",
                      },
                    ]}
                  >
                    <PhoneInput
                      type="text"
                      defaultCountry={
                        form.getFieldValue("nationality")
                          ? getCountryCodeByName(
                              form.getFieldValue("nationality")
                            )
                          : ""
                      }
                      placeholder="Emergency Contact Number"
                      maxLength={
                        form.getFieldValue("nationality")
                          ? Number(
                              getMaxLengthByCountry(
                                form.getFieldValue("nationality")
                              )
                            ) + 2
                          : 15
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="nssf" label="NSSF">
                    <Input
                      placeholder="NSSF"
                      maxLength={20}
                      onKeyDown={(e) => {
                        const value =
                          /^[0-9]$/?.test(e.key) ||
                          /^[a-zA-Z\s]+$/?.test(e.key);
                        if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="national_id" label="National ID">
                    <Input
                      placeholder="National ID"
                      maxLength={20}
                      onKeyDown={(e) => {
                        const value =
                          /^[0-9]$/?.test(e.key) ||
                          /^[a-zA-Z\s]+$/?.test(e.key);
                        if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Address is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Address"
                      maxLength={60}
                      onKeyDown={(e) => {
                        const value =
                          /^[0-9,/]$/.test(e.key) ||
                          /^[a-zA-Z\s,/]+$/.test(e.key);
                        if (!value && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                {/* // Note: need for future */}

                {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    label="Band Name"
                    name="bandid"
                    rules={[
                      {
                        required: false,
                        pattern: NON_EMPTY_REGEX,
                        message: "Please Select",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Band"
                      name="bandid"
                      options={bandList}
                      fieldNames={{
                        label: "bandName",
                        value: "bandId",
                      }}
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="overtime"
                    label="Overtime Eligible"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Overtime Eligible is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    label="Start Date"
                    name="start_date"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Start Date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      format="DD-MM-YYYY"
                      placeholder="Select Start date"
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="salary" label=" Salary">
                    <Input
                      placeholder="Salary"
                      type="text"
                      maxLength={10}
                      onKeyDown={(e) => {
                        const value = /^[0-9]$/?.test(e.key);
                        if (
                          !value &&
                          e.key !== "Backspace" &&
                          e.key !== "Tab" &&
                          /^[0-9]$/
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="employementtype"
                    label="Employment Type"
                    rules={[
                      {
                        required: true,
                        pattern: NON_EMPTY_REGEX,
                        message: "Employment Type is required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      getPopupContainer={() => containerRef.current}
                    >
                      <Option value="Casual">Casual</Option>
                      <Option value="Contract">Contract</Option>
                      <Option value="Full time">Full time</Option>
                      <Option value="Paid Internship">Paid Internship</Option>
                      <Option value="Part time">Part time</Option>
                      <Option value="Training">Training</Option>
                      <Option value="Unpaid Internship">
                        Unpaid Internship
                      </Option>
                      <Option value="Probation">Probation </Option>
                    </Select>
                  </Form.Item>
                </Col>
                {form?.getFieldValue("employementtype") === "Probation" && (
                  <>
                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                      <Form.Item
                        label="Probation Start Date"
                        name="probationstartdate"
                        rules={[
                          {
                            required: true,
                            pattern: NON_EMPTY_REGEX,
                            message: "Probation Start Date is required",
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-100"
                          format="DD-MM-YYYY"
                          placeholder="Select Probation start date"
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                      <Form.Item
                        label="Probation End Date"
                        name="probationenddate"
                        rules={[
                          {
                            required: true,
                            pattern: NON_EMPTY_REGEX,
                            message: "Probation End Date is required",
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-100"
                          format="DD-MM-YYYY"
                          placeholder="Select Probation End date"
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {form?.getFieldValue("employementtype") === "Contract" && (
                  <>
                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                      <Form.Item
                        label="Contract Start Date"
                        name="contractstartdate"
                        rules={[
                          {
                            required: true,
                            pattern: NON_EMPTY_REGEX,
                            message: "Contract Start Date is required",
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-100"
                          format="DD-MM-YYYY"
                          placeholder="Select Contract start date"
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                      <Form.Item
                        label="Contract End Date"
                        name="contractenddate"
                        rules={[
                          {
                            required: true,
                            pattern: NON_EMPTY_REGEX,
                            message: "Contract End Date is required",
                          },
                        ]}
                      >
                        <DatePicker
                          className="w-100"
                          format="DD-MM-YYYY"
                          placeholder="Select Contract End date"
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

                <Col xs={24} sm={24} md={24}>
                  <Form.Item name="profile" label="Profile Picture">
                    <StyledDropzone {...getRootProps()}>
                      <input {...getInputProps()} disabled />
                      <p>
                        {filename !== "" ? <>{filename}</> : <> choose file</>}
                      </p>
                    </StyledDropzone>
                  </Form.Item>
                </Col>
              </Row>
              <div className="text-end">
                <StyledButton type="primary" onClick={() => Reset()}>
                  Reset
                </StyledButton>
                <StyledButton
                  loading={showLoader}
                  type="primary"
                  htmlType="submit"
                >
                  Next
                </StyledButton>
              </div>
            </Form>
          </StyledModal>
        )}

        {step === 1 && (
          <>
            <SelectTable
              step={step}
              setStep={setStep}
              columns={baseDepartmentColumn}
              tabledata={allDepartmentList}
              editRole="edit"
              selectedBaseDepartment={baseDepartment}
            />
          </>
        )}

        {step === 2 && (
          <>
            <SelectTable
              step={step}
              setStep={setStep}
              columns={baseLocationColumn}
              tabledata={filterLocationList}
              editRole="edit"
              selectedBaseLocation={baseLocation}
            />
          </>
        )}

        {step === 3 && (
          <>
            <StyledModal>
              <Form
                form={form}
                name="dynamic_form_nest_item"
                onFinish={() => onFinish(4)}
                autoComplete="off"
                initialValues={{
                  educationdetails: [
                    {
                      institutename: "",
                      fromdate: "",
                      todate: "",
                      exambody: "",
                      awarded: "",
                      attachment: [],
                    },
                  ],
                  practicaltrainings: [
                    {
                      organisationname: "",
                      fromdate: "",
                      todate: "",
                      positionheld: "",
                      tasksperformed: "",
                    },
                  ],
                  professionalcertifications: [
                    {
                      certificationtype: "",
                      fromdate: "",
                      todate: "",
                      remarks: "",
                      attachment: [],
                    },
                  ],
                }}
                labelCol={{ xs: 24, sm: 24, md: 24 }}
                style={{ textAlign: "left" }}
              >
                <b>Education Details:</b>
                <Form.List name="educationdetails">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <StyleRow gutter={16}>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Institute Name"
                              name={[name, "institutename"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Institute Name required",
                              //   },
                              // ]}
                            >
                              <Input
                                placeholder="Institute Name"
                                // onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              {...restField}
                              label="From:"
                              name={[name, "fromdate"]}
                              // rules={[
                              //   { required: true, message: "Date required" },
                              // ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                                placeholder="From"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              {...restField}
                              label="To:"
                              name={[name, "todate"]}
                              // rules={[
                              //   { required: true, message: "Date required" },
                              // ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                                placeholder="To"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              {...restField}
                              label="Exam Body:"
                              name={[name, "exambody"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Exam Body required",
                              //   },
                              // ]}
                            >
                              <Input
                                placeholder="Exam Body"
                                // onChange={onFinish}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              {...restField}
                              label="Awarded:"
                              name={[name, "awarded"]}
                              // rules={[
                              //   { required: true, message: "Awarded required" },
                              // ]}
                            >
                              <Select
                                placeholder="Select"
                                getPopupContainer={() => containerRef.current}
                              >
                                <Option value="Doctorate">Doctorate</Option>
                                <Option value="Master">Master</Option>
                                <Option value="Bachelor">Bachelor</Option>
                                <Option value="Diploma">Diploma</Option>
                                <Option value="Certificate">Certificate</Option>
                              </Select>
                            </Form.Item>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Attachment"
                              name={[name, "attachment"]}
                              valuePropName="fileList"
                              getValueFromEvent={(e) => {
                                if (Array.isArray(e)) {
                                  return e;
                                }
                                return e && e.fileList;
                              }}
                            >
                              <Upload
                                customRequest={({ file, onSuccess }) => {
                                  const reader = new FileReader();
                                  reader.readAsDataURL(file);

                                  reader.onload = () => {
                                    const base64Data =
                                      reader.result.split(",")[1];
                                    const uniqueId = generateUniqueId();
                                    onSuccess({ file, base64Data, uniqueId });
                                  };

                                  reader.onerror = (error) => {
                                    console.error("File read error:", error);
                                  };
                                }}
                                name="logo"
                                // action="/upload.do"
                                onChange={(value) => {
                                  //  onFinish();
                                  uploadFile(value);
                                }}
                                onRemove={(file) => {
                                  handleFileRemove(file);
                                }}
                                listType="picture"
                                style={{ width: "100%" }}
                              >
                                <div style={{ width: "100%" }}>
                                  <Button
                                    className="w-100 text-start"
                                    size="large"
                                  >
                                    Upload File
                                  </Button>
                                </div>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </StyleRow>
                      ))}
                      <Form.Item>
                        <u
                          className="bg-transparent border-0 text-start"
                          type="dashed"
                          onClick={() => add()}
                          block
                        >
                          +Add Another
                        </u>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <b>Practical Trainings:</b>
                <Form.List name="practicaltrainings">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <StyleRow gutter={16} className="mt-2">
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Organisation Name"
                              name={[name, "organisationname"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Organisation Name required",
                              //   },
                              // ]}
                            >
                              <Input
                                placeholder="Organisation Name"
                                // onChange={() => {
                                //   // onFinish();
                                // }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              {...restField}
                              label="From:"
                              name={[name, "fromdate"]}
                              // rules={[
                              //   { required: true, message: "Date required" },
                              // ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                // onChange={() => {
                                //   // onFinish();
                                // }}
                                placeholder="From"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              {...restField}
                              label="To:"
                              name={[name, "todate"]}
                              // rules={[
                              //   { required: true, message: "Date required" },
                              // ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                // onChange={() => {
                                //   // onFinish();
                                // }}
                                placeholder="To"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Position Held"
                              name={[name, "positionheld"]}
                              // rules={[
                              //   {
                              //     required: false,
                              //     pattern:VALIDATE_CHARACTER,
                              //     message: "Invalid Position",
                              //   },
                              // ]}
                            >
                              <Input
                                placeholder="Position Held"
                                onKeyDown={(e) => {
                                  const allowedChars = /^[a-zA-Z\s]+$/;
                                  if (!allowedChars.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                // onChange={() => {
                                //   // onFinish();
                                // }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Nature of work & Tasks performed or completed"
                              name={[name, "tasksperformed"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message:
                              //       "Nature of work & Tasks performed or completed required",
                              //   },
                              // ]}
                            >
                              <Input.TextArea
                                placeholder="Nature of work & Tasks performed or completed"
                                // onChange={() => {
                                //   // onFinish();
                                // }}
                              />
                            </Form.Item>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </StyleRow>
                      ))}
                      <Form.Item>
                        <u
                          className="bg-transparent border-0 text-start"
                          type="dashed"
                          onClick={() => add()}
                          block
                        >
                          +Add Another
                        </u>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <b>Professional Certifications:</b>
                <Form.List name="professionalcertifications">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <StyleRow gutter={16} className="mt-2">
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Certification Type"
                              name={[name, "certificationtype"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Certification Type required",
                              //   },
                              // ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select"
                                optionFilterProp="children"
                                getPopupContainer={() => containerRef.current}
                                defaultValue={AwardList[0]}
                                // onChange={onFinish}
                              >
                                {AwardList?.map((value, index) => (
                                  <Select.Option
                                    key={index}
                                    value={value}
                                    label={value}
                                  >
                                    {value}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              {...restField}
                              label="From:"
                              name={[name, "fromdate"]}
                              // rules={[
                              //   { required: true, message: "Date required" },
                              // ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                                placeholder="From"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              {...restField}
                              label="To:"
                              name={[name, "todate"]}
                              // rules={[
                              //   { required: true, message: "Date required" },
                              // ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                                placeholder="To"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Remarks"
                              name={[name, "remarks"]}
                              // rules={[
                              //   { required: true, message: "Remarks required" },
                              // ]}
                            >
                              <Input
                                placeholder="Remarks"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Attachment"
                              name={[name, "attachment"]}
                              valuePropName="fileList"
                              getValueFromEvent={(e) => {
                                if (Array.isArray(e)) {
                                  return e;
                                }
                                return e && e.fileList;
                              }}
                            >
                              <Upload
                                customRequest={({ file, onSuccess }) => {
                                  const reader = new FileReader();
                                  reader.readAsDataURL(file);

                                  reader.onload = () => {
                                    const base64Data =
                                      reader.result.split(",")[1];
                                    const uniqueId = generateUniqueId();
                                    onSuccess({ file, base64Data, uniqueId });
                                  };

                                  reader.onerror = (error) => {
                                    console.error("File read error:", error);
                                  };
                                }}
                                name="logo"
                                // action="/upload.do"
                                onChange={(value) => {
                                  //  onFinish();
                                  uploadFile(value);
                                }}
                                onRemove={(file) => {
                                  handleFileRemove(file);
                                }}
                                listType="picture"
                                style={{ width: "100%" }}
                              >
                                <div style={{ width: "100%" }}>
                                  <Button
                                    className="w-100 text-start"
                                    size="large"
                                  >
                                    Upload File
                                  </Button>
                                </div>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </StyleRow>
                      ))}
                      <Form.Item>
                        <u
                          className="bg-transparent border-0 text-start"
                          type="dashed"
                          onClick={() => add()}
                          block
                        >
                          +Add Another
                        </u>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <div className="text-end">
                  <StyledButton
                    type="primary"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </StyledButton>
                  <StyledButton
                    loading={showLoader}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </StyledButton>
                </div>
              </Form>
            </StyledModal>
          </>
        )}

        {step === 4 && (
          <>
            <StyledModal>
              <Form
                className=""
                form={form}
                name="addemployeeidentityform"
                layout="vertical"
                initialValues={{
                  company_Name: "",
                  job_Title: "",
                  professional_since: "",
                  hired_date: "",
                  employee_sponser: "",
                  employee_status: "",
                  relevent: "",
                  marital_status: "",
                  description: "",
                  workexperience: [
                    {
                      companyname: "",
                      fromdate: "",
                      todate: "",
                      jobtitle: "",
                      relevnt: "",
                      jobdescription: "",
                    },
                  ],
                }}
                onFinish={() => onFinish(5)}
              >
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name="company_Name"
                      label="Company Name"
                      rules={[
                        {
                          required: true,
                          message: "Company Name is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Company name"
                        maxLength={50}
                        onKeyDown={(e) => {
                          const allowedChars = /^[a-zA-Z\s]+$/;
                          if (!allowedChars.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="job_Title"
                      label="Job Title"
                      rules={[
                        {
                          required: true,
                          message: "Job Title is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Job Title"
                        maxLength={50}
                        onKeyDown={(e) => {
                          const allowedChars = /^[a-zA-Z\s]+$/;
                          if (!allowedChars.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      label="Last Promotion Date"
                      name="lastpromotiondate"
                      rules={[
                        {
                          required: false,
                          pattern: NON_EMPTY_REGEX,
                          message: "Last Promotion Date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        format="DD-MM-YYYY"
                        placeholder="Select Last Promotion Date"
                        getPopupContainer={() => containerRef.current}
                        // onChange={onFinish}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="lastpromotionlevel"
                      label="Last Promotion Level"
                      rules={[
                        {
                          required: false,
                          message: "Last Promotion is required",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Last Promotion"
                        maxLength={100}
                        // onChange={onFinish}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      label="Professional Since"
                      name="professional_since"
                      rules={[
                        {
                          required: false,
                          pattern: NON_EMPTY_REGEX,
                          message: "Professional Since is required",
                        },
                        // () => ({
                        //   // Note : First parameter is Needed
                        //   validator(rule, value) {
                        //     const date = moment(value);
                        //     return date.isValid()
                        //       ? Promise.resolve()
                        //       : Promise.reject();
                        //   },
                        // }),
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        format="DD-MM-YYYY"
                        onChange={validateDateRange}
                        placeholder="Select start date"
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      label="Hired Date"
                      name="hired_date"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Hired Date is required",
                        },
                        () => ({
                          // Note : First parameter is Needed
                          validator(rule, value) {
                            const date = moment(value);
                            return date.isValid()
                              ? Promise.resolve()
                              : Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        format="DD-MM-YYYY"
                        onChange={validateDateRange}
                        placeholder="Select start date"
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      name="employee_sponser"
                      label="Employee Sponsor"
                      rules={[
                        {
                          required: false,
                          pattern: NON_EMPTY_REGEX,
                          message: "Employee Sponsor is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        getPopupContainer={() => containerRef.current}
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      name="employee_status"
                      label="Status"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Status is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        getPopupContainer={() => containerRef.current}
                        onChange={(value) =>
                          setEmployeeStatusId(value === "active" ? "1" : "0")
                        }
                      >
                        <Option value="active">Active</Option>
                        <Option value="in active">In active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} ref={containerRef}>
                    <Form.Item
                      name="relevent"
                      label="Relevant"
                      rules={[
                        {
                          required: false,
                          pattern: NON_EMPTY_REGEX,
                          message: "Relevant is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        getPopupContainer={() => containerRef.current}
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item name="description" label="Description">
                      <Input.TextArea placeholder="description" />
                    </Form.Item>
                  </Col>
                </Row>
                <b>Work Experience:</b>
                <Form.List name="workexperience">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <StyleRow gutter={16} className="mt-2">
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              {...restField}
                              label="Company name"
                              name={[name, "companyname"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Company name required",
                              //   },
                              // ]}
                            >
                              <Input
                                placeholder="Company name"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item
                              {...restField}
                              label="Job Title"
                              name={[name, "jobtitle"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Job Title required",
                              //   },
                              // ]}
                            >
                              <Input
                                placeholder="Job Title"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              {...restField}
                              label="From:"
                              name={[name, "fromdate"]}
                              // rules={[
                              //   { required: true, message: "Date required" },
                              // ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                                placeholder="From"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} ref={containerRef}>
                            <Form.Item
                              {...restField}
                              label="To:"
                              name={[name, "todate"]}
                              // rules={[
                              //   { required: true, message: "Date required" },
                              // ]}
                            >
                              <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                                placeholder="To"
                                getPopupContainer={() => containerRef.current}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Relevant"
                              name={[name, "relevant"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Relevant required",
                              //   },
                              // ]}
                            >
                              <Select
                                placeholder="Select"
                                getPopupContainer={() => containerRef.current}
                                // onChange={onFinish}
                              >
                                <Option value="yes">Yes</Option>
                                <Option value="no">No</Option>
                              </Select>
                            </Form.Item>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                          <Col xs={24} sm={24} md={24}>
                            <Form.Item
                              {...restField}
                              label="Job Description"
                              name={[name, "jobdescription"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Job Description required",
                              //   },
                              // ]}
                            >
                              <Input.TextArea
                                placeholder="Job Description"
                                // onChange={() => {
                                //   onFinish();
                                // }}
                              />
                            </Form.Item>
                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          </Col>
                        </StyleRow>
                      ))}
                      <Form.Item>
                        <u
                          className="bg-transparent border-0 text-start"
                          type="dashed"
                          onClick={() => add()}
                          block
                        >
                          +Add Another
                        </u>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <div className="text-end">
                  <StyledButton
                    type="primary"
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </StyledButton>
                  <StyledButton
                    loading={showLoader}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </StyledButton>
                </div>
              </Form>
            </StyledModal>
          </>
        )}
        {step === 5 && (
          <RolesAndPermissions
            step={step}
            setStep={setStep}
            setMenuList={setMenuList}
            menuList={menuList}
            setsubmit={() => {}}
            paydisabled={!productInfo?.tymeplusPayStatus}
          />
        )}
        {step === 6 && (
          <>
            <InReview
              title="Basic Information"
              setStep={setStep}
              step={step}
              BasicInformation={reviewBasicData}
              role={selectedRoleId}
              EducationInformation={reviewEducationData}
              baseLocation={baseLocation}
              baseDepartment={baseDepartment}
              WorkInformation={reviewWorkData}
              handleCreate={handlePermissionSubmit}
              loading={showLoader}
            />
          </>
        )}
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
      saveRoleList: setRoleList,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  allDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  roleList: getRoleList(state),
  userList: getUserList(state),
  bandList: getBandList(state),
  userMenuList: getUserMenuList(state),
  clientId: getClientid(state),
  productInfo: getProductInfo(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(AddEmployee);
