import React from "react";
import { Modal, Table } from "antd";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { dateInDetail } from "utils/Date";

const StyledModal = styled(Modal)`
  width: 600px !important;

  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;
const StyleLocation = styled.div`
  a {
    font-size: 12px;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
    border: 1px solid #00000024;
    border-radius: 10px;
  }
  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    white-space: normal;
  }
  .ant-table-thead > tr > th::before {
    display: none !important;
  }

  .ant-table-cell-row-hover {
    background: #e3e8fc !important;
  }
`;

function LocationBasedAttendanceList({
  displayModal,
  toggleModal,
  title,
  list,
  reportType,
}) {
  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      // width: '30%',
      // align: 'center'
    },
    // {
    //     title: 'Email',
    //     dataIndex: 'EmailId',
    //     key: 'EmailId',
    //     // width: '30%',
    // },
    {
      title: "Department",
      dataIndex: "departmentName",
      key: "departmentName",
      // width: '50%',
      // align: 'center'
    },
    {
      title: "Check-In Time",
      dataIndex: "checkintime",
      key: "checkintime",
      // width: '50%',
      align: "start",
      render: (checkintime) => {
        return checkintime.slice(0, 5);
      },
    },
  ];

  if (reportType === "Others" || reportType === "Other") {
    columns.push({
      title: <div>Location Coordinate</div>,
      dataIndex: "location",
      key: "loginlatitude",
      className: "normal-column",
      align: "start",
      // width: '50%',
      render: (_, record) => {
        const url1 =
          "http://maps.google.com/maps/?q=" +
          record.loginlatitude +
          "," +
          record.loginlongitude +
          "";
        return (
          <div>
            {record.loginlatitude !== "" && record.loginlongitude !== "" ? (
              <>
                <StyleLocation className="location">
                  <a href={url1} target="_blank">
                    {record.loginlatitude}, {record.loginlongitude}
                  </a>
                </StyleLocation>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    });
  }

  return (
    <>
      <StyledModal
        title={title}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
        destroyOnClose
      >
        <StyledTable
          dataSource={list}
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="name"
          pagination={false}
        />
      </StyledModal>
    </>
  );
}

export default LocationBasedAttendanceList;
