import Styled from "styled-components";

const PricingWrap = Styled.div`
.context {
    font-size: 18px;
    font-weight: 400;
    color: #636980;
    margin-bottom: 2rem;
    text-align: center;
}
.card-sub{
    font-size: 18px;
    font-weight: 500;
}
.card-price{
    font-size: 30px;
    font-weight: 700;
    color: #1F243B;
}
.card-month{
    font-size: 16px;
    font-weight: 500;
    color: #636980;
}
.card-title{
    font-size: 24px;
    font-weight: 700;
    color: #2D53DA;
}
.pricing-contain{

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  
  .pricing-card{
    border-radius:15px;
    box-shadow: 0px 4px 26px 8px #2D53DA1F;
    transition: 0.5s ease;
  }
}

.atm-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;

  .atm-card {
    cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 26px 8px #2D53DA1F;
    }
  }
}

.renewalDeadline {
  text-align: center;
  font-weight: 500;
}

.custom-button {
  color: #2D53DA;
  border-radius: 10px;
  border-color: #2D53DA;
  padding: 0.4rem 2rem 0.5rem 2rem;
  font-weight: 600;
  
  &:hover {
    background-color: rgba(45, 83, 218, 0.1);
  }

  &.active {
    background-color: #2D53DA;
    color: #fff;
    &:hover {
      background-color: rgba(45, 83, 218, 0.8);
  }
}
}


`;

export default PricingWrap;
