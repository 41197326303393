import React, { useEffect, useState } from "react";
import { Area } from "@ant-design/plots";

const LineChart = () => {
    const [data, setData] = useState([

        {
            "symbol": "MSFT",
            "month": "Jan",
            "price": 39.81
        },
        {
            "symbol": "MSFT",
            "month": "Feb",
            "price": 36.35
        },
        {
            "symbol": "MSFT",
            "month": "Mar",
            "price": 43.22
        },
        {
            "symbol": "MSFT",
            "month": "Apr",
            "price": 28.37
        },
        {
            "symbol": "MSFT",
            "month": "May",
            "price": 25.45
        },
        {
            "symbol": "MSFT",
            "month": "Jun",
            "price": 32.54
        },
        {
            "symbol": "MSFT",
            "month": "Jul",
            "price": 28.4
        },
        {
            "symbol": "MSFT",
            "month": "Aug",
            "price": 28.4
        },
        {
            "symbol": "MSFT",
            "month": "Sep",
            "price": 24.53
        },
        {
            "symbol": "MSFT",
            "month": "Oct",
            "price": 28.02
        },
        {
            "symbol": "MSFT",
            "month": "Nov",
            "price": 23.34
        },
        {
            "symbol": "MSFT",
            "month": "Dec",
            "price": 17.65
        }
    ]);



    const configData = {
        data,
        xField: "month",
        yField: "price",
        style: {
            fill: "linear-gradient(-90deg, white 0%, #20DDAF 100%)",
        },
        axis: {
            y: { labelFormatter: "~s" },
        },
        line: {
            style: {
                stroke: "#20DDAF",
                strokeWidth: 2,
            },
        },
    };

    return <Area {...configData} style={{
        maxHeight: "290px"
    }} />;
};

export default LineChart;
