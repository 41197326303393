import { message } from "antd";
import { CALL_API } from "common/API";
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import EachFormSection from "reusableComponent/formBuilder/EachFormSection";
import FormBasicDetail from "reusableComponent/formBuilder/formBasicDetails";

const EditForm = ({
  pageView,
  selectedItem,
  setPageView,
  fetchData,
  ClientId,
  updateProcessingModal,
  getFormData,
  formId,
  access = true,
}) => {
  const [formDetailshow, setFormDetailshow] = useState(true);
  const [fullFormDetail, setFullFormDetail] = useState(selectedItem.questions);
  const [formbasicDetail, setFormBasicDetail] = useState({
    title: selectedItem.title,
    description: selectedItem.description,
  });
  const [savedForm, setSavedForm] = useState(false);

  useEffect(() => {
    if (selectedItem) {
      setFormBasicDetail({
        title: selectedItem.title,
        description: selectedItem.description,
      });
      setFullFormDetail(selectedItem.questions);
    }
  }, [selectedItem]);

  const addEditFormBuilder = async (payload, id = "") => {
    if (formId) {
      getFormData(payload, formId);
    }
    if (payload?.title === "" || payload?.description === "") {
      message.error("Title and description is required");
      return;
    }
    if (id) {
      updateProcessingModal(true);
      const { code } = await CALL_API(
        `formbuilder/${ClientId}/${id}`,
        "patch",
        payload
      );
      if (code === "SUCCESS") {
        setSavedForm(true);
        fetchData();
        setTimeout(() => {
          updateProcessingModal(false);
          message.success("Form Updated successfully");
          setPageView("list");
        }, 3000);
      }
    } else {
      updateProcessingModal(true);
      const { code } = await CALL_API(
        `formbuilder/${ClientId}`,
        "post",
        payload
      );
      if (code === "SUCCESS") {
        setSavedForm(true);
        updateProcessingModal(false);
        fetchData();
        message.success("Form created successfully");
        setPageView("list");
      }
    }
  };

  return (
    <div>
      <Row className="page-title">
        <section className="form_builder main_container">
          <Row className="d-flex justify-content-center">
            <Col md={10} lg={7} sm={12}>
              <Container className="bg_light my-3 rounded-2">
                <FormBasicDetail
                  setFormDetailshow={setFormDetailshow}
                  formDetailshow={formDetailshow}
                  setFormBasicDetail={setFormBasicDetail}
                  setFullFormDetail={setFullFormDetail}
                  formbasicDetail={formbasicDetail}
                />
              </Container>

              <div className="my-3">
                <EachFormSection
                  pageView={pageView}
                  formbasicDetail={formbasicDetail}
                  fullFormDetail={fullFormDetail}
                  setFullFormDetail={setFullFormDetail}
                  setSavedForm={setSavedForm}
                  selectedItem={selectedItem}
                  setPageView={setPageView}
                  fetchData={fetchData}
                  isFormBuilder={true}
                  addEditFormBuilder={addEditFormBuilder}
                  access={access}
                />
              </div>
            </Col>
          </Row>
        </section>
      </Row>
    </div>
  );
};

export default EditForm;
