import React from "react";
import { Modal, Table } from "antd";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { dateInDetail } from "utils/Date";

const StyledModal = styled(Modal)`
  width: 100% !important;
  max-width: 700px;
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
    border: 1px solid #00000024;
    border-radius: 10px;
  }
  .ant-table-cell {
    padding: 10px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .ant-table-thead > tr > th::before {
    display: none !important;
  }

  .ant-table-cell-row-hover {
    background: #e3e8fc !important;
  }
`;

function AttendanceStaffList({
  displayModal,
  toggleModal,
  title,
  list,
  name,
  reportType,
}) {
  const columns = [
    {
      title: "Name",
      dataIndex:
        reportType === "Leave"
          ? "name"
          : reportType === "Absent"
          ? "fullname"
          : "fullname",
      key: "fullName",
      // width: '50%',
    },
  ];

  if (reportType === "leave") {
    columns.push({
      title: "Leave Category",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      align: "center",
    });
    columns.push({
      title: "Start date",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      render: (start_date) => {
        if (start_date === "") return "-";
        const { date: day_th, shortMonthName, year } = dateInDetail(start_date);
        return `${day_th} ${shortMonthName} ${year}`;
      },
    });
    columns.push({
      title: "End date",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      render: (end_date) => {
        if (end_date === "") return "-";
        const { date: day_th, shortMonthName, year } = dateInDetail(end_date);
        return `${day_th} ${shortMonthName} ${year}`;
      },
    });
    columns.push({
      title: "No.of leave days",
      dataIndex: "number_of_days",
      key: "number_of_days",
      align: "center",
    });
  } else if (reportType === "Present") {
    if (name === "onTime") {
      // columns.push({
      //   title: 'Email ID',
      //   dataIndex: 'email_id',
      //   key: 'email_id',
      //   width: '50%',
      // });
      columns.push({
        title: "Station Name",
        dataIndex: "loginlocationname",
        key: "loginlocationname",
        // width: '50%',
        align: "center",
      });

      columns.push({
        title: "Check-In Time",
        dataIndex: "checkintime",
        key: "checkintime",
        // width: '50%',
        align: "center",
      });
    } else if (name === "Late") {
      // columns.push({
      //   title: 'Email ID',
      //   dataIndex: 'email_id',
      //   key: 'email_id',
      //   width: '30%',
      // });
      columns.push({
        title: "Station Name",
        dataIndex: "loginlocationname",
        key: "loginlocationname",
        // width: '50%',
        align: "center",
      });
      columns.push({
        title: "Check-In Time",
        dataIndex: "checkintime",
        key: "checkintime",
        // width: '50%',
        align: "center",
        // render: (checkinTime) => {
        //   console.log("checkinTime-->", checkinTime)
        //   const { time24 } = dateInDetail(checkinTime);
        //   return `${time24}`;
        // }
      });
    } else {
      // columns.push({
      //   title: 'Email ID',
      //   dataIndex: 'email_id',
      //   key: 'email_id',
      //   width: '30%',
      // });
      columns.push({
        title: "Check-In Time",
        dataIndex: "checkintime",
        key: "checkintime",
        width: "50%",
        align: "center",
        render: (checkinTime) => {
          const { time24 } = dateInDetail(checkinTime);
          return `${time24}`;
        },
      });
      columns.push({
        title: <div>Google Location</div>,
        dataIndex: "location",
        key: "loginlatitude",
        width: "50%",
        align: "center",
        render: (_, record) => {
          const url1 =
            "http://maps.google.com/maps/?q=" +
            record.loginlatitude +
            "," +
            record.logoutlongitude +
            "";
          return (
            <div>
              {record.loginlatitude !== "" && record.logoutlongitude !== "" ? (
                <>
                  <div id="loc">
                    <a href={url1} style={{ fontSize: "12px" }} target="_blank">
                      {record?.loginlatitude}, {record?.logoutlongitude}
                    </a>
                  </div>
                </>
              ) : (
                "-"
              )}
            </div>
          );
        },
      });
    }
  } else {
    columns.push({
      title: "Email ID",
      dataIndex: "userid",
      key: "userid",
      // width: '50%',
      align: "center",
    });
  }

  return (
    <>
      <StyledModal
        title={name === "onTime" ? "On time check-in report" : title}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
        destroyOnClose
      >
        <StyledTable
          dataSource={list}
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="user_id"
          pagination={false}
        />
      </StyledModal>
    </>
  );
}

export default AttendanceStaffList;
