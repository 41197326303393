import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { CustomTextField } from "../common/common";
import {
  Typography,
  Paper,
  Box,
  Radio,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import {
  getMenuList,
  getUserInfo,
  getUserRoleId,
} from "redux/selectors";
import styled from "styled-components";
import { Spin, Empty, message, Button } from "antd";
import { CALL_API } from "common/API";
import { connect } from "react-redux";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const StylesWrap = styled.div`
  .back-btn {
    cursor: pointer;
    color: #26a4ff;
  }
  .name_card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: 100%;
    background: #fff;
  }
  .card-selected {
    border: 2px solid #26a4ff;
  }
  .MuiFormControl-root {
    width: 100%;
  }
`;

const AttendFormSection = ({
  fullFormDetail,
  setFullFormDetail,
  formbasicDetail,
  setSavedForm,
  pageView,
  selectedItem,
  setPageView,
  fetchData,
  myappraisalData,
  userList,
  userInfo,
  manuList,
  userRoleId
}) => {

  const Menulist = manuList[userRoleId] || Object.values(manuList)[0];
  const [receivedResponse, setReceivedResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const questionStyle = {
    fontSize: "16px",
    fontWeight: "medium",
    display: "inline-block",
  };

  React.useEffect(() => {
    answeredForm();
  }, []);

  const answeredForm = () => {
    const emptyArray = [];
    const userResponse = fullFormDetail;
    userResponse?.forEach((element) => {
      emptyArray?.push({
        question: element.question,
        formType: element.formType,
        id: element.id,
        requiredSwitch: element.requiredSwitch,
        answer: element.answer,
        options:
          element.formType === "MULIPLE_CHOICE" ||
          element.formType === "CHECKBOX" ||
          element.formType === "DROPDOWN"
            ? element.options
            : "",
      });
    });
    setReceivedResponse(emptyArray);
  };

  const handleInputChange = (event, questionId, type) => {
    const updatedQuestions = receivedResponse.map((question) => {
      if (question.id === questionId) {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[questionId];
          return updatedErrors;
        });

        if (type === "CHECKBOX") {
          const updatedAnswer = Array.isArray(question.answer)
            ? question.answer
            : [];
          if (event.target.checked) {
            updatedAnswer.push(event.target.value);
          } else {
            const index = updatedAnswer.indexOf(event.target.value);
            if (index !== -1) updatedAnswer.splice(index, 1);
          }
          return { ...question, answer: updatedAnswer };
        } else {
          return { ...question, answer: event.target.value };
        }
      }
      return question;
    });
    setReceivedResponse(updatedQuestions);
  };

  let selectedAppraisal = myappraisalData.find(
    (data) => data.appraisalFormId === selectedItem.appraisalFormId
  );
  const saveForm = async (id) => {
    const formErrors = {};
    receivedResponse.forEach((form) => {
      if (form.requiredSwitch && !form.answer) {
        formErrors[form.id] = "This field is required";
      }
      if (
        form.requiredSwitch &&
        form.formType === "CHECKBOX" &&
        (!form.answer || form.answer.length === 0)
      ) {
        formErrors[form.id] = "This field is required";
      }
      if (
        form.requiredSwitch &&
        form.formType === "MULIPLE_CHOICE" &&
        !form.answer
      ) {
        formErrors[form.id] = "This field is required";
      }
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);
    const data = {
      answer: receivedResponse,
      departmentId: selectedAppraisal?.departmentId,
      assignId: selectedAppraisal?.assignId,
      usersCompleted: [...selectedAppraisal?.usersCompleted, userInfo?.userid],
    };
    const { code } = await CALL_API(
      `appraisalform/response/${id}`,
      "post",
      data
    );

    if (code === "SUCCESS") {
      setLoading(false);
      setPageView(false);
      message.success("Form submitted successfully");
      fetchData();
    }
  };

  return (
    <div>
      <StylesWrap>
        {fullFormDetail && fullFormDetail.length ? (
          <>
            <section className="form_builder main_container">
              <Row>
                <Container>
                  <>
                    <div className="mt-4">
                      {receivedResponse?.map((form, idx) => {
                        return (
                          <Paper elevation={0} className="mb-3">
                            <div className="p-3">
                              <Typography variant="h6" sx={questionStyle}>
                                {form?.question}
                              </Typography>
                              {form?.requiredSwitch && (
                                <span className="text-danger">*</span>
                              )}

                              <Box
                                key={`selectedOption-${form.id}`}
                                sx={{ mt: 2 }}
                              >
                                <>
                                  {form?.formType === "PARAGRAPH" && (
                                    <>
                                      <CustomTextField
                                        value={form?.answer}
                                        type="text"
                                        multiline
                                        maxRows={4}
                                        variant="standard"
                                        sx={{
                                          "& .MuiInputBase-input.Mui-": {
                                            WebkitTextFillColor: "black",
                                          },
                                        }}
                                        onChange={(e) =>
                                          handleInputChange(e, form.id)
                                        }
                                        required={form?.requiredSwitch}
                                        error={errors[form.id]}
                                      />
                                      {errors[form.id] && (
                                        <div className="text-danger">
                                          {errors[form.id]}
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {form?.formType === "SHORT_ANS" && (
                                    <>
                                      <CustomTextField
                                        value={form?.answer}
                                        type="text"
                                        variant="standard"
                                        sx={{
                                          "& .MuiInputBase-input.Mui-": {
                                            WebkitTextFillColor: "black",
                                          },
                                        }}
                                        onChange={(e) =>
                                          handleInputChange(e, form.id)
                                        }
                                        required={form?.requiredSwitch}
                                        error={errors[form.id]}
                                      />
                                      {errors[form.id] && (
                                        <div className="text-danger">
                                          {errors[form.id]}
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {form?.formType === "TIME" && (
                                    <>
                                      <CustomTextField
                                        value={form?.answer}
                                        type="time"
                                        variant="standard"
                                        sx={{
                                          "& .MuiInputBase-input.Mui-": {
                                            WebkitTextFillColor: "black",
                                          },
                                        }}
                                        onChange={(e) =>
                                          handleInputChange(e, form.id)
                                        }
                                        required={form?.requiredSwitch}
                                        error={errors[form.id]}
                                      />
                                      {errors[form.id] && (
                                        <div className="text-danger">
                                          {errors[form.id]}
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {form?.formType === "DATE" && (
                                    <>
                                      <CustomTextField
                                        value={form?.answer}
                                        type="date"
                                        variant="standard"
                                        sx={{
                                          "& .MuiInputBase-input.Mui-": {
                                            WebkitTextFillColor: "black",
                                          },
                                        }}
                                        onChange={(e) =>
                                          handleInputChange(e, form.id)
                                        }
                                        required={form?.requiredSwitch}
                                        error={errors[form.id]}
                                      />
                                      {errors[form.id] && (
                                        <div className="text-danger">
                                          {errors[form.id]}
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {form?.formType === "DROPDOWN" && (
                                    <Box
                                      sx={{
                                        maxWidth: 280,
                                        minWidth: 250,
                                        mt: 2,
                                      }}
                                    >
                                      <FormControl fullWidth>
                                        <Select
                                          labelId="select"
                                          id="select-filled"
                                          value={form?.answer}
                                          sx={{
                                            "& .MuiInputBase-input.Mui-": {
                                              WebkitTextFillColor: "black",
                                            },
                                          }}
                                          onChange={(e) =>
                                            handleInputChange(e, form?.id)
                                          }
                                          required={form?.requiredSwitch}
                                          error={errors[form.id]}
                                        >
                                          {form?.options.map((val) => (
                                            <MenuItem value={val.optionText}>
                                              {val.optionText}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      {errors[form.id] && (
                                        <div className="text-danger">
                                          {errors[form.id]}
                                        </div>
                                      )}
                                    </Box>
                                  )}

                                  {form?.formType === "MULIPLE_CHOICE" && (
                                    <>
                                      <FormControl>
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          name="radio-buttons-group"
                                          value={form?.answer}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              form?.id,
                                              "RADIO"
                                            )
                                          }
                                          required={form?.requiredSwitch}
                                          error={errors[form.id]}
                                        >
                                          {form?.options.map(
                                            (option, index) => (
                                              <FormControlLabel
                                                key={index}
                                                value={option?.optionText}
                                                control={<Radio />}
                                                label={option?.optionText}
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                      {errors[form.id] && (
                                        <div className="text-danger">
                                          {errors[form.id]}
                                        </div>
                                      )}
                                    </>
                                  )}

                                  {form?.formType === "CHECKBOX" && (
                                    <>
                                      {form?.options.map(
                                        (option, checkBoxindex) => {
                                          return (
                                            <div>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    value={option?.optionText}
                                                    inputProps={{
                                                      "aria-label":
                                                        "controlled",
                                                    }}
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        e,
                                                        form?.id,
                                                        "CHECKBOX"
                                                      )
                                                    }
                                                  />
                                                }
                                                label={option?.optionText}
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                      {errors[form.id] && (
                                        <div className="text-danger">
                                          {errors[form.id]}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              </Box>
                            </div>
                          </Paper>
                        );
                      })}
                    </div>
                  </>
                </Container>
              </Row>
            </section>
          </>
        ) : (
          <Empty description={<span>Currently no response found!</span>} />
        )}
        {
          Menulist?.find(item => item?.key === "myappraisal" &&  item?.permission.edit)&& (
            <div className="text-end mt-3">
          <ButtonOutlined
            className="btn-style"
            type="submit"
            onClick={() => saveForm(selectedItem.appraisalFormId)}
          >
            <Spin spinning={loading}>Submit</Spin>
          </ButtonOutlined>
        </div>
          )
        }
        
      </StylesWrap>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
  manuList: getMenuList(state),
  userRoleId: getUserRoleId(state)
});
export default connect(
  mapStateToProps,
  null
)(AttendFormSection);
