
import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

const StyledDropzoneContainer = styled.div`
  border: 2px dashed #ccc;
  padding: 20px;
  cursor: pointer;
`;
const MainStyledDropzoneText = styled.p`
  margin: 0;
`;

const MainStyledDropzone = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: {
    'image/*': [] // This allows all image types
  } });

  return (
    <StyledDropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <MainStyledDropzoneText>Drop the files here ...</MainStyledDropzoneText>
      ) : (
        <MainStyledDropzoneText>Drag 'n' drop some files here, or click to select files</MainStyledDropzoneText>
      )}
    </StyledDropzoneContainer>
  );
};

export default MainStyledDropzone;
