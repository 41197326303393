import React, { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import Button from '../../atoms/Button';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Details from './Details';
import AddEditPolicy from './AddEditPolicy';
import Table from 'atoms/Table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RoundIcon from 'atoms/RoundIcon';
import { connect } from 'react-redux';
import { getClientid, getUserInfo, getMenuList, getUserRoleId } from 'redux/selectors';
import { CALL_API } from 'common/API';
import COLORS from 'common/Colors';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { STATUS_CODE } from "common/Constants";


function HRPolicy({ ClientId, userInfo, menuList, userRoleId }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [showComponent, setShowComponent] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];


  const filterbyrole = dataSource?.filter((item)=>item?.roleId?.includes(userRoleId))


  const goBack = () => {
    setIsEdit(false);
    setShowComponent(1);
  };

  const deleteData = async (id) => {
    // deletePolicy(id);
    const {code} = await CALL_API(`hrpolicy/${ClientId}?policyId=${id}`, 'delete', {});
    if(code === STATUS_CODE.SUCCESS){
      message.success('Policy Deleted Successfully');
      getPolicyData();
    }
    
  };

  const iconStyle = { color: '#fff', fontSize: '18px' };

  const columns = [
    {
      title: "Policy Name",
      dataIndex: "policyTitle",
      key: "policyName",
    },
    {
      title: "Type",
      dataIndex: "policyType",
      key: "policyType",
    },
    {
      title: "Effective date",
      dataIndex: "policyEffectiveDate",
      key: "policyEffectiveDate",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
  ];
  if (Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.edit)) ||
  Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.delete))
) {
  columns.push(
    {
      title: "Action",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      render: (_, record) => (
       
        <div className="d-flex gap-2">
          {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.edit)) && (
        <RoundIcon >
              <EditIcon style={iconStyle} onClick={(e) => { e.stopPropagation(); setSelectedRow(record); setIsEdit(true); }}/>
            </RoundIcon>
          )}
          {Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.delete)) && (
            <RoundIcon >
            <DeleteIcon style={iconStyle} onClick={(e) => { e.stopPropagation(); deleteData(record?.policyId); }}/>
            </RoundIcon>
    )}
        
        </div>
      ),
    },
  );
}
  

  const getPolicyData = async () => {
    setLoading(true);
    const { response } = await CALL_API(`hrpolicy/${ClientId}`, "get", {});
    setLoading(false);
    setDataSource(response);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDataSource = filterbyrole?.filter((item) =>
    item?.policyTitle?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  useEffect(() => {
    getPolicyData();
  }, []);
  const titleStyle = { color: `${COLORS.BLACK}`, cursor: "pointer" };

  const deletePolicy = (policyId) => {
    const updatedDataSource = dataSource?.filter(
      (policy) => policy?.policyId !== policyId
    );
    setDataSource(updatedDataSource);
  };
  

  return (
    <div>
      {!isEdit && showComponent === 1 && <h6 className="mb-2">HR Policies</h6>}
      {!isEdit && showComponent === 1 && (
        <>
          <Row gutter={16}>
            <Col md={6} className="">
            {/* <b>Filter by</b> */}
              <Input
                className="mb-2"
                allowClear
                value={searchTerm}
                placeholder="Search"
                onChange={handleSearchChange}
                prefix={<SearchOutlined />}
              />
            </Col>
            {
              Menulist?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "Policies" && subItem?.permission.create)) && (
                <Col className='text-end' md={18}>
                  <Button type="primary" onClick={() => setShowComponent(3)}>Add New Policy</Button>
                </Col>
              )}
          </Row>
          <br />
          <Table
            dataSource={filteredDataSource}
            columns={columns}
            pagination={false}
            loading={loading}
            // Note: Second params needed
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                    setSelectedRow(record);
                    setShowComponent(2)
                },
              };
            }}
          />
        </>
      )}
      {!isEdit && showComponent === 2 && (
        <Details
          pagetitle="HR Policies"
          selectedRow={selectedRow}
          ClientId={ClientId}
          userInfo={userInfo}
          goBack={goBack}
          getPolicyData={getPolicyData}
          deletePolicy={deletePolicy}
          Menulist={Menulist}
        />
      )}
      {!isEdit && showComponent === 3 && (
        <>
          <h6>
            <strong style={titleStyle} onClick={() => goBack()}>
              HR Policies
            </strong>{" "}
            <span> {">"} Add New Policy</span>
          </h6>
          <AddEditPolicy
            ClientId={ClientId}
            userInfo={userInfo}
            goBack={goBack}
            getPolicyData={getPolicyData} 
          />
        </>
      )}
      {isEdit && showComponent && (
        <div>
          <AddEditPolicy ClientId={ClientId} userInfo={userInfo} value={selectedRow} goBack={() => goBack()} getPolicyData={() => getPolicyData()} />
        </div>
      )}
    </div>
  );
}

export const BackButton = ({ goBack }) => {
  const iconStyle = { fontSize: "16px", color: "white" };
  return (
    <RoundIcon>
      <ArrowBackIcon style={iconStyle} onClick={goBack} />
    </RoundIcon>
  );
};

const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  userInfo: getUserInfo(state),
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps, null)(HRPolicy);
