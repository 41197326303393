import React, { useEffect, useState, useMemo } from "react";
import Wrapper, { CardImage } from "./style";
import { Card, Empty, Spin } from "antd";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import moment from "moment";
import { formTemplates } from "./JsonFormFormat";
import AddForm from "./AddForm";
import { connect } from "react-redux";
import { getClientid, getMenuList, getUserRoleId } from "redux/selectors";
import EditForm from "./EditForm";
import { bindActionCreators } from "redux";
import { toggleProcessingModal, toggleSuccessModal } from "redux/actions";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

const { Meta } = Card;

function generateRandomLightColors(count) {
  const red = Math.floor(Math.random() * 50) + 205; // Values between 205 and 255 for a lighter color
  const green = Math.floor(Math.random() * 50) + 205;
  const blue = Math.floor(Math.random() * 50) + 205;
  const color = `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;

  return color;
}

function FormBuilder({
  ClientId,
  updateProcessingModal,
  updateSuccessModal,
  menuList,
  userRoleId,
}) {
  const [data, setData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [pageView, setPageView] = useState("list");
  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];
  const [loading, setLoading] = useState(false);

  const displayedTemplates = showAll
    ? formTemplates
    : formTemplates.slice(0, 5);
  const img =
    "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png";

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await CALL_API(`formbuilder/${ClientId}`, "get");
      setData(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setPageView("save");
  };

  const MemoizedCard = useMemo(() => {
    return React.memo(({ item, description = false }) => (
      <Wrapper>
        <Card
          key={item.id}
          hoverable
          cover={
            <CardImage
              backgroundColor={generateRandomLightColors()}
              className="px-4"
            >
              <img alt="example" src={item?.img ? item?.img : img} />
            </CardImage>
          }
          bordered={true}
          className="cards"
          onClick={() => handleEdit(item)}
        >
          {description ? (
            <Meta
              title={item?.title}
              description={`Created on ${moment(item?.insertedAt).format(
                "DD MMM YYYY"
              )}`}
            />
          ) : (
            <Meta title={item?.title} />
          )}
        </Card>
      </Wrapper>
    ));
  }, []);

  return (
    <Wrapper>
      <div>
        <>
          {pageView !== "list" && (
            <h6>
              <strong onClick={() => setPageView("list")}>Form Builder</strong>
              {pageView === "save" && (
                <span>
                  {pageView === "save" && selectedItem?.title
                    ? ` > ${selectedItem.title}`
                    : ""}
                </span>
              )}
              {pageView === "add" && <span>{" > New Form"}</span>}
            </h6>
          )}
        </>
        {pageView === "list" && (
          <div className="text-end">
            {Menulist?.find(
              (item) =>
                item?.key === "HRResources" &&
                item?.subMenu?.find(
                  (subItem) =>
                    subItem?.key === "formbuilder" && subItem?.permission.create
                )
            ) && (
              <Button type="primary" onClick={() => setPageView("add")}>
                + Create New form
              </Button>
            )}
          </div>
        )}
      </div>
      {pageView === "list" && (
        <>
          {/* template forms */}
          {Menulist?.find(
            (item) =>
              item?.key === "HRResources" &&
              item?.subMenu?.find(
                (subItem) =>
                  subItem?.key === "formbuilder" && subItem?.permission.create
              )
          ) && (
            <div className="template">
              <h6>Start a New Form</h6>
              <div className="text-end ">
                <span onClick={() => setShowAll(!showAll)}>
                  {" "}
                  {!showAll
                    ? "Show All Templates "
                    : " Show Less Templates"}{" "}
                  <UnfoldMoreIcon />
                </span>
              </div>

              <div className="card-container">
                {displayedTemplates?.map((item, index) => (
                  <MemoizedCard item={item} />
                ))}
              </div>
            </div>
          )}
          {/* Uploaded Files */}

          <div className="template">
            <h6>Recent Forms</h6>
            {loading && data?.length === 0 && (
              <div className="text-center">
                <Spin />
              </div>
            )}
            <div className="card-container">
              {data?.map((item, index) => (
                <MemoizedCard item={item} description={true} />
              ))}
            </div>
            {!loading && data?.length === 0 && <Empty />}
          </div>
        </>
      )}
      {pageView === "save" && (
        <EditForm
          pageView="edit"
          selectedItem={selectedItem}
          setPageView={setPageView}
          fetchData={fetchData}
          ClientId={ClientId}
          updateProcessingModal={updateProcessingModal}
          access={
            Menulist?.find(
              (item) =>
                item?.key === "HRResources" &&
                item?.subMenu?.find(
                  (subItem) =>
                    subItem?.key === "formbuilder" && subItem?.permission.edit
                )
            )
              ? true
              : false
          }
        />
      )}
      {pageView === "add" && (
        <AddForm
          setPageView={setPageView}
          fetchData={fetchData}
          ClientId={ClientId}
          updateProcessingModal={updateProcessingModal}
          updateSuccessModal={updateSuccessModal}
        />
      )}
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);
