import { Button, Col, Row, Spin, Table } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { getMenuList, getUserRoleId } from "redux/selectors";
import styled from "styled-components";
import { AddEditLevels } from "./AddEditLevels";
import { AddLevels } from "./AddLevels";
import EditLevels from "./EditLevels";

const StyledButton = styled(Button)`
  width: 150px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

const Index = ({ menuList, userRoleId }) => {
  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];
  const headerStyle = { fontSize: "20px", fontWeight: "bold" };

  const [isAddLevels, setIsAddLevels] = useState(false);
  const [isEditLevels, setIsEditLevels] = useState(false);
  const [displayLevelsLoader, setDisplayLevelsLoader] = useState(false);
  const [levelDetails, setLevelDetails] = useState({});

  const tempdata = [
    {
      id: 1,
      name: "level 1",
      description:
        "Levels are a company's method of standardizing employees' scope of assumed ability, responsibility and in many cases experience",
      employeecount: "2",
    },
  ];

  const columns = [
    {
      title: "Levels",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "No of Employee",
      dataIndex: "employeecount",
      key: "employeecount",
      width: "25%",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "50%",
      align: "center",
    },
  ];

  return (
    <>
      {!isAddLevels && !isEditLevels && (
        <>
          <Row className="page-title">
            <Col sm={24} md={12} style={headerStyle}>
              List of Levels
            </Col>
            {Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "levels" && subItem?.permission.create
                )
            ) && (
              <Col sm={24} md={12} className="align-right">
                <StyledButton
                  className="mx-0"
                  type="primary"
                  htmlType="submit"
                  onClick={() => setIsAddLevels(true)}
                >
                  + Add Levels
                </StyledButton>
              </Col>
            )}
          </Row>

          <Spin spinning={displayLevelsLoader}>
            <Table
              className="mt-2"
              dataSource={tempdata}
              columns={columns}
              rowKey="id"
              onRow={(record) => {
                return {
                  onClick: () => {
                    setIsEditLevels(true);
                    setLevelDetails(record);
                  },
                };
              }}
              pagination={false}
            />
          </Spin>
        </>
      )}
      {isAddLevels && (
        <AddLevels isAddLevels={isAddLevels} setIsAddLevels={setIsAddLevels} />
      )}
      {isEditLevels && (
        <EditLevels
          levelDetails={levelDetails}
          setLevelDetails={setLevelDetails}
          isModalVisible={isEditLevels}
          closeModal={setIsEditLevels}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps)(Index);
