import React from 'react';
import { Bar } from '@ant-design/plots';

const data = [
    {
        labelName: 'Active',
        value: 220,
    },
    {
        labelName: 'Inactive',
        value: 110,
    },
];

const OverviewBar = () => {
    const config = {
        data,
        xField: 'value',
        yField: 'labelName',
        seriesField: 'labelName',
        theme: {
            colors10: ['#9E00FF', '#0505B1'], // Custom color palette
        },
        geometryOptions: {
            cornerRadius: 8,
        },
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            title: null,
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        yAxis: {
            title: null,
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        meta: {
            value: {
                alias: 'Value',
            },
        },
    };

    return (
        <div className='overview_bar_container'>
            <div style={{ width: '100%' }}>
                <Bar {...config} style={{ height: '100px' }} />
            </div>
        </div>
    );
};

export default OverviewBar;
