import { SearchOutlined } from "@mui/icons-material";
import { Input } from "antd";
import Table from "atoms/Table";
import COLORS from "common/Colors";
import React, { useEffect, useState } from "react";
import { ButtonFilled, ButtonLink } from "reusableComponent/UIButtons";
import { CALL_API } from "common/API";
import { getContractJson } from "JsonFormat/ContractJson";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import moment from "moment";
import IssueContract from "./IssueContract";

const cursorstyle = { cursor: "pointer" };

function TemplateContract({
  goBack,
  formdata,
  selectedCandidate,
  getData,
  goTab,
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showComponent, setShowComponent] = useState("table");
  const [contractList, setContractList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDataSource = contractList?.filter((item) =>
    item?.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log("filteredDatasource--->", filteredDataSource);

  const columns = [
    {
      title: "Contract Name",
      dataIndex: "title",
      key: "title",
      render: (val) => {
        return val ? val : "N/A";
      },
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (val) => {
    //     return val ? val : "N/A";
    //   },
    // },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => {
        const date = moment(record?.date).format("DD MMM YYYY");
        return date;
      },
    },
    {
      title: "Action",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (_, record) => (
        <ButtonLink
          className="text-primary"
          style={cursorstyle}
          onClick={() => {
            setShowComponent("contract");
            setSelectedItem(record);
          }}
        >
          Select
        </ButtonLink>
      ),
    },
  ];

  const getContractData = async () => {
    setLoading(true);
    const { data } = await CALL_API(`recruitment/contract`, "get", {});
    setContractList(data);
    setLoading(false);
  };

  useEffect(() => {
    getContractData();
  }, []);
  const { ContractForm } = getContractJson(data);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitForm = async (values) => {
    // API For create contract
    setLoading(true);
    await CALL_API(`recruitment/contract`, "post", values);
    setData({});
    setLoading(false);
    setShowComponent("table");
    getContractData();
    // getData()
  };
  const handleBack = (e) => {
    setShowComponent("table");
  };

  const styleCursor = { cursor: "pointer", color: `${COLORS.PRIMARY}` };

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "16px" };
  return (
    <div>
      {showComponent === "table" && (
        <>
          <h6>
            {" "}
            <span style={styleCursor} onClick={() => goBack()}>
              {" Back "}
            </span>{" "}
            {" > "}Choose Template For Contract
          </h6>
          <br />
          <div className="d-flex justify-content-between">
            <div>
              <Input
                style={styledInput}
                prefix={<SearchOutlined style={styledIcon} />}
                placeholder="Search"
                onChange={handleSearchChange}
              />
            </div>
            <div>
              <ButtonFilled onClick={() => setShowComponent("add")}>
                + Create Contract
              </ButtonFilled>
            </div>
          </div>

          <Table
            dataSource={filteredDataSource}
            columns={columns}
            pagination={false}
            loading={loading}
            // Note: Second params needed
          />
        </>
      )}
      {showComponent === "add" && (
        <>
          <h6>
            {" "}
            <span style={styleCursor} onClick={() => setShowComponent("table")}>
              {" Back "}
            </span>{" "}
            {" > "}Add New Contract
          </h6>
          <br />
          <GridBuilder
            formStructureJson={ContractForm}
            handleChange={handleFormChange}
            formSubmit={handleSubmitForm}
            initialValues={data}
            handleBack={handleBack}
            loading={loading}
          />
        </>
      )}

      {showComponent === "contract" && (
        <>
          <h6>
            {" "}
            <span style={styleCursor} onClick={() => setShowComponent("table")}>
              {" Back "}
            </span>{" "}
            {" > Issue Contract"}{" "}
          </h6>
          <br />
          <IssueContract
            setShowComponent={setShowComponent}
            contractDetails={selectedItem}
            formdata={formdata}
            selectedCandidate={selectedCandidate}
            getData={getData}
            goTab={goTab}
          />
        </>
      )}
    </div>
  );
}

export default TemplateContract;
