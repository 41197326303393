import { SearchOutlined } from "@mui/icons-material";
import { Input, message } from "antd";
import Table from "atoms/Table";
import COLORS from "common/Colors";
import moment from "moment";
import React, { useRef, useState } from "react";
import { reduce } from "lodash";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { CSVLink } from "react-csv";
import styled from "styled-components";

const StyleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  @media (max-width: 426px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
`;

function OnboardCandidate({ data, isloading, searchTerm, setSearchTerm }) {
  const csvLinkEl = useRef();

  // const [searchTerm, setSearchTerm] = React.useState("");
  const [onboardedEmployeeCSVlist, setOnboardedEmployeeCSVlist] = useState([]);

  const filteredDataSource = data?.filter((item) =>
    item?.firstName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e?.target?.value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, record) => {
        return record?.firstName + " " + record?.lastName;
      },
    },
    {
      title: "Employment Type",
      dataIndex: "employmentType",
      key: "employmentType",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (_, record) => {
        return moment(record?.startDate).format("DD-MM-YYYY");
      },
    },
  ];

  const prepareCSV = async () => {
    if (filteredDataSource.length > 0) {
      setOnboardedEmployeeCSVlist(
        reduce(
          filteredDataSource,
          function (result, list) {
            result.push({
              "Employee ID": list?.employeeId,
              Location: list?.location,
              "Secondary Manager": list?.secondaryManager,
              "Company Name": list?.companyName,
              "Email ID": list?.emailId,
              "Working Hours Per Day": list?.workingHoursPerDay,
              "Phone No": list?.phoneNo,
              "First Name": `${list?.firstName} ${list?.lastName}`,
              "Candidate ID": list?.candidateId,
              "Working Days Per Week": list?.workingDaysPerWeek,
              "Pay Rate": list?.payRate,
              "Employment Type": list?.employmentType,
              "Start Date": moment(list?.startDate).format("DD-MM-YYYY"),
              "Job Title": list?.jobTitle,
              "Payment By": list?.paymentBy,
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "16px" };

  return (
    <div>
      <StyleDiv className="">
        <div>
          <Input
            style={styledInput}
            value={searchTerm}
            prefix={<SearchOutlined style={styledIcon} />}
            placeholder="Search People"
            onChange={handleSearchChange}
          />
        </div>
        <div>
          <ButtonOutlined onClick={prepareCSV}>
            <span className="add-title">Export CSV File</span>
          </ButtonOutlined>
          <CSVLink
            data={onboardedEmployeeCSVlist}
            filename={"Employee-list.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </div>
      </StyleDiv>

      <Table
        dataSource={filteredDataSource}
        columns={columns}
        pagination={false}
        loading={isloading}
      />
    </div>
  );
}

export default OnboardCandidate;
