import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  Menu,
  Dropdown,
  Input,
  Typography,
  Modal,
  message,
} from "antd";
import { Pie } from "@ant-design/plots";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { ButtonFilled } from "reusableComponent/UIButtons";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SearchOutlined } from "@mui/icons-material";
import { CurrenciesList, EXPENSE_STATUS, STATUS_CODE } from "common/Constants";
import RequestToReimburse from "./RequestToReimburse";
import { CALL_API } from "common/API";
import Deleted from "../../../../assets/Deleted.svg";
import Filter from "../../../../assets/Filter.svg";

const { TextArea } = Input;

const { Text } = Typography;

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-cell:nth-last-child(2) {
    white-space: normal;
  }
  .non-white {
    background: #f9f9f9;
  }
  .ellipsis {
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledViewMenu = styled.div`
  width: 150px;
  cursor: pointer;
`;
const StyledDropdownMenu = styled(Dropdown)`
  width: 150px;
  cursor: pointer;
`;

const MyReimbursement = ({
  ClientId,
  userInfo,
  updateSuccessModal,
  setHideTitle,
  Menulist,
  searchString,
  setSearchString,
}) => {
  const hasEditPermission = Menulist?.find(
    (item) => item?.key === "mytravelexpense" && item?.permission.edit
  );
  const hasDeletePermission = Menulist?.find(
    (item) => item?.key === "mytravelexpense" && item?.permission.delete
  );

  const hasCreatePermission = Menulist?.find(
    (item) => item?.key === "mytravelexpense" && item?.permission?.create
  );
  const showView = hasEditPermission || hasDeletePermission;

  const columns = [
    {
      title: "Reimb.ID",
      dataIndex: "reimbursementId",
      key: "reimbursementId",
    },
    {
      title: "Type of Expense",
      dataIndex: "typeofExpense",
      key: "typeofExpense",
      render: (text, record) => {
        return record.typeofExpense === "travel" ? "Travel" : "Others";
      },
    },

    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      ellipsis: true,
      render: (text) => (
        <Text className="ellipsis" ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
      width: "10%",
    },
    {
      title: "Total Expense",
      dataIndex: "billProofs",
      key: "expense",
      render: (billProofs, record) => {
        let totalExpense = 0;

        for (let i = 0; i < billProofs.length; i++) {
          const bill = billProofs[i];
          if (typeof bill.totalAmount === "string") {
            const amount = parseInt(bill.totalAmount);
            if (!isNaN(amount)) {
              totalExpense += amount;
            } else {
              console.error("Invalid totalAmount value:", bill.totalAmount);
            }
          } else if (typeof bill.totalAmount === "number") {
            totalExpense += bill.totalAmount;
          } else {
            console.error("Invalid totalAmount value:", bill.totalAmount);
          }
        }
        const currency = CurrenciesList.find(
          (currency) => currency.code === billProofs[0].amountType
        );
        const symbol = currency ? currency.symbol : "";

        return symbol + " " + totalExpense;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => (
        <StyledDropdownMenu
          onClick={() => handleDropdownClick()}
          onVisibleChange={(visible) => handleDropdownVisibleChange(visible)}
          overlay={
            <StyledViewMenu>
              <Menu>
                {record.status !== "Waiting" || !showView ? (
                  <>
                    {Menulist?.find(
                      (item) => item?.key === "mytravelexpense" && item?.access
                    ) && (
                      <Menu.Item
                        key="2"
                        onClick={() => {
                          setSelectedItem(record);
                          setView(true);
                          setEdit(true);
                          setType(record?.typeofExpense);
                          setBills(record?.billProofs);
                          setOpen(true);
                          getFilesId(record?.id);
                        }}
                      >
                        View
                      </Menu.Item>
                    )}
                  </>
                ) : (
                  <>
                    {hasEditPermission && (
                      <Menu.Item
                        key="2"
                        onClick={() => {
                          setSelectedItem(record);
                          setOpen(true);
                          setEdit(true);
                          getFilesId(record?.id);
                        }}
                      >
                        Edit
                      </Menu.Item>
                    )}
                    {hasDeletePermission && (
                      <Menu.Item
                        key="4"
                        onClick={() => {
                          setDeleteId(record?.id);
                          setDeleteData(true);
                        }}
                      >
                        Delete
                      </Menu.Item>
                    )}
                  </>
                )}
              </Menu>
            </StyledViewMenu>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <MoreHorizIcon />
          </span>
        </StyledDropdownMenu>
      ),
    },
  ];

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 100,
    showSizeChanger: false,
  };

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "16px" };
  const containerRef = useRef(null);

  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [showLoader, toggleLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [view, setView] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [edit, setEdit] = useState(false);
  const [type, setType] = useState(false);
  const [uploadView, setUploadView] = useState(false);
  const [tempFile, setTempFile] = useState("");
  const [bills, setBills] = useState([]);
  const [traveldata, setTraveldata] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  const [value, setValue] = useState({
    reason: "",
    days: 0,
    departure: null,
    arrival: null,
    destination: "",
    travelId: "",
    reasonforExpense: "",
    dateType: "",
    specificdate: null,
    expenseMadefrom: null,
    expenseMadetill: null,
  });
  const [formData, setFormData] = useState({
    nameofexpense: "",
    totalAmount: "",
    amountType: selectedCurrency,
    files: [],
    fileNames: [],
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    lastEvaluatedKey: null,
  });

  const [filteredData, setFilteredData] = useState(traveldata);
  const [isClickWithinDropdownMenu, setIsClickWithinDropdownMenu] =
    useState(false);

  const handleDropdownClick = () => {
    setIsClickWithinDropdownMenu(true);
  };

  const handleDropdownVisibleChange = (visible) => {
    setIsClickWithinDropdownMenu(visible);
  };

  useEffect(() => {
    if (searchString.length) {
      const filtered = traveldata.filter((item) =>
        String(item?.reimbursementId)
          .toLowerCase()
          .includes(String(searchString.toLowerCase()))
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(traveldata);
    }
    // setFilteredData(traveldata);
  }, [traveldata, searchString]);

  useEffect(() => {
    setHideTitle(edit || view || open);
  }, [edit, view, open, setHideTitle]);

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "travelId":
        if (value.length > 0 && value.length <= 3) {
          error = "Travel ID must be longer than 3 characters";
        }
        break;
      case "typeofExpense":
        if (!value) {
          error = "This field is required";
        } else {
          console.log("call");
          error = false;
        }
        break;
      case "reason":
      case "reasonforExpense":
        if (!value) {
          error = "This field is required";
        }
        break;
      case "destination":
        if (!value) {
          error = "This field is required";
        }
        break;
      case "departure":
        if (!value) {
          error = "This field is required";
        }
        break;
      case "arrival":
        if (!value) {
          error = "This field is required";
        }
        break;
      case "specificdate":
        if (!value) {
          error = "This field is required";
        }
        break;
      case "expenseMadefrom":
        if (!value) {
          error = "This field is required";
        }
        break;
      case "expenseMadetill":
        if (!value) {
          error = "This field is required";
        }
        break;
      case "dateType":
        if (!value) {
          error = "This field is required";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleSearchChange = (e) => {
    const searchString = e.target.value;
    setSearchString(searchString);
  };

  const handleSelectChange = (selectedValue) => {
    if (selectedValue !== "All") {
      const filtered = traveldata.filter(
        (item) => item.status === selectedValue
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(traveldata);
    }
  };

  const showModal = () => {
    setOpen(true);
  };
  const onChangeValue = (e) => {
    const { name, value: inputValue } = e.target;
    const error = validateField(name, inputValue);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    setValue((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  const onChange = (date, dateString, field) => {
    const error = validateField(field, date);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
    setValue((prevState) => ({
      ...prevState,
      [field]: date,
    }));
  };

  const onChangeEdit = (e) => {
    const { name, value: inputValue } = e.target;
    if (name === "dateType") {
      setSelectedItem((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          [name]: inputValue,
        },
      }));
    } else {
      setSelectedItem((prevState) => ({
        ...prevState,
        [name]: inputValue,
      }));
    }
  };

  const onChangeEditdate = (date, dateString, field) => {
    if (
      field === "specificdate" ||
      field === "expenseMadefrom" ||
      field === "expenseMadetill"
    ) {
      setSelectedItem((prevState) => ({
        ...prevState,
        date: {
          ...prevState.date,
          [field]: date,
        },
      }));
    } else {
      setSelectedItem((prevState) => ({
        ...prevState,
        [field]: date,
      }));
    }
  };

  const disabledDateBefore = (current, targetDate) => {
    return (
      current &&
      (current < moment(targetDate).startOf("day") ||
        current > moment().endOf("day"))
    );
  };

  const disabledDateAfter = (current, targetDate) => {
    return (
      current &&
      (current > moment(targetDate).endOf("day") ||
        current > moment().endOf("day"))
    );
  };

  const updateDays = (departure, arrival) => {
    if (departure && arrival) {
      const daysDifference = moment(arrival).diff(departure, "days");

      let days;
      if (daysDifference === 0) {
        days = 1;
      } else if (daysDifference === 1) {
        days = 2;
      } else if (daysDifference > 1) {
        days = daysDifference + 1;
      } else {
        days = 0;
      }

      if (edit) {
        setSelectedItem((prevState) => ({
          ...prevState,
          days: days,
        }));
      } else {
        setValue((prevState) => ({
          ...prevState,
          days: days,
        }));
      }
    }
  };

  const [errors, setErrors] = useState({
    reason: "",
    destination: "",
    specificdate: "",
    expenseMadefrom: "",
    expenseMadetill: "",
    departure: "",
    arrival: "",
    reasonforExpense: "",
    dateType: "",
    bills: "",
    typeofExpense: "",
  });

  const validateFields = () => {
    let valid = true;
    const newErrors = { ...errors };
    if (!type) {
      newErrors.typeofExpense = "This field is required";
      valid = false;
    } else {
      newErrors.typeofExpense = "";
    }

    if (type === "travel") {
      if (!value.reason) {
        newErrors.reason = "This field is required";
        valid = false;
      } else {
        newErrors.reason = "";
      }
      if (!value.departure) {
        newErrors.departure = "This field is required";
        valid = false;
      } else {
        newErrors.departure = "";
      }
      if (!value.arrival) {
        newErrors.arrival = "This field is required";
        valid = false;
      } else {
        newErrors.arrival = "";
      }
      if (!value.destination) {
        newErrors.destination = "This field is required";
        valid = false;
      } else {
        newErrors.destination = "";
      }
    }

    if (type === "others") {
      if (!value.reasonforExpense) {
        newErrors.reasonforExpense = "This field is required";
        valid = false;
      } else {
        newErrors.reasonforExpense = "";
      }
      if (!value.dateType) {
        newErrors.dateType = "This field is required";
        valid = false;
      } else {
        newErrors.dateType = "";
      }
      if (value.dateType === "Specific date" && !value.specificdate) {
        newErrors.specificdate = "This field is required";
        valid = false;
      } else {
        newErrors.specificdate = "";
      }
      if (
        value.dateType === "Range of dates" &&
        (!value.expenseMadefrom || !value.expenseMadetill)
      ) {
        newErrors.expenseMadefrom = "This field is required";
        newErrors.expenseMadetill = "This field is required";
        valid = false;
      } else {
        newErrors.expenseMadefrom = "";
        newErrors.expenseMadetill = "";
      }
    }

    if (bills.length === 0) {
      newErrors.bills =
        "Please upload atleast one bill before submitting reimbursement.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleRowClick = (record) => {
    if (!isClickWithinDropdownMenu) {
      setSelectedItem(record);
      setView(true);
      setType(record?.typeofExpense);
      setBills(record?.billProofs);
      setOpen(true);
      getFilesId(record?.id);
      setEdit(true);
    }
  };

  const getFilesId = async (id) => {
    const { code, url } = await CALL_API(
      `travel-expense/reimbursement/billproofs?clientId=${ClientId}&id=${id}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (url) {
        setBills(url);
      }
    } else {
      message.error("Error loading Files!");
    }
  };

  const getData = async () => {
    setDisplayTableLoader(true);

    const { code, data, LastEvaluatedKey } = await CALL_API(
      `travel-expense/reimbursement?LastEvaluatedKey=0&clientId=${ClientId}&userId=${userInfo.userid}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      setTraveldata(data);
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: LastEvaluatedKey,
      }));
      setDisplayTableLoader(false);
    } else {
      toggleLoader(false);
      message.error("Error");
      setDisplayTableLoader(false);
    }
  };

  const getMoreData = async () => {
    setDisplayTableLoader(true);
    const { code, data, LastEvaluatedKey } = await CALL_API(
      `travel-expense/reimbursement?LastEvaluatedKey=${
        pagination.lastEvaluatedKey || 0
      }&clientId=${ClientId}&userId=${userInfo.userid}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      setTraveldata([...traveldata, ...data]);
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: LastEvaluatedKey,
      }));
      setDisplayTableLoader(false);
    } else {
      toggleLoader(false);
      message.error("Error");
      setDisplayTableLoader(false);
    }
  };

  const handleDelete = async () => {
    toggleLoader(true);
    const { code } = await CALL_API(
      `travel-expense/reimbursement?&clientId=${ClientId}&id=${deleteId}`,
      "delete"
    );
    if (code === STATUS_CODE.SUCCESS) {
      toggleLoader(false);
      updateSuccessModal({
        visibility: true,
        message: "Deleted",
        Sucessicon: (
          <div className="d-flex justify-content-center align-itemw-center">
            {" "}
            <img className="d-block" src={Deleted} alt="deleted" />
          </div>
        ),
      });
      setDeleteData(false);
      reset();
      setTraveldata([]);
      getData();
    } else {
      toggleLoader(false);
      message.error("Error");
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextPage = () => {
    if (pagination.lastEvaluatedKey !== null) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: prevPagination.current + 1,
      }));
    }
  };

  useEffect(() => {
    if (pagination.lastEvaluatedKey !== null) {
      getMoreData();
    }
  }, [pagination.current]);

  const reset = () => {
    setFormData({
      nameofexpense: "",
      totalAmount: "",
      amountType: selectedCurrency,
      files: [],
      fileNames: [],
    });
    setValue({
      reason: "",
      days: 0,
      departure: null,
      arrival: null,
      destination: "",
      travelId: "",
      reasonforExpense: "",
      dateType: "",
      specificdate: null,
      expenseMadefrom: null,
      expenseMadetill: null,
    });
    setBills([]);
    setType("");
    setSelectedItem([]);
    setEdit(false);
    setOpen(false);
    setView(false);
    setErrors({
      reason: "",
      destination: "",
      specificdate: "",
      expenseMadefrom: "",
      expenseMadetill: "",
      departure: "",
      arrival: "",
      reasonforExpense: "",
      dateType: "",
      bills: "",
      typeofExpense: "",
    });
  };

  return (
    <div>
      {open ? (
        <RequestToReimburse
          setOpen={setOpen}
          selectedItem={selectedItem}
          value={value}
          onChangeEdit={onChangeEdit}
          edit={edit}
          onChangeEditdate={onChangeEditdate}
          updateDays={updateDays}
          onChange={onChange}
          onChangeValue={onChangeValue}
          disabledDateAfter={disabledDateAfter}
          disabledDateBefore={disabledDateBefore}
          setType={setType}
          type={type}
          setBills={setBills}
          bills={bills}
          tempFile={tempFile}
          uploadView={uploadView}
          setTempFile={setTempFile}
          setUploadView={setUploadView}
          ClientId={ClientId}
          setValue={setValue}
          userInfo={userInfo}
          updateSuccessModal={updateSuccessModal}
          getData={getData}
          setFormData={setFormData}
          formData={formData}
          reset={reset}
          view={view}
          getFilesId={getFilesId}
          setEdit={setEdit}
          setDeleteId={setDeleteId}
          setDeleteData={setDeleteData}
          setView={setView}
          errors={errors}
          validateFields={validateFields}
          validateField={validateField}
          setErrors={setErrors}
          hasDeletePermission={hasDeletePermission}
          hasEditPermission={hasEditPermission}
          setSelectedCurrency={setSelectedCurrency}
          selectedCurrency={selectedCurrency}
        />
      ) : (
        <>
          <Row className="page-title">
            <Col
              xs={24}
              sm={8}
              md={5}
              className="page-title-head pe-3"
              ref={containerRef}
            >
              <Input
                style={styledInput}
                prefix={<SearchOutlined style={styledIcon} />}
                placeholder="Search "
                onChange={handleSearchChange}
                value={searchString}
                className="me-3"
              />
            </Col>
            <Col
              xs={24}
              sm={8}
              md={5}
              className="page-title-head"
              ref={containerRef}
            >
              <Select
                placeholder={
                  <>
                    <img src={Filter} alt="filter" />{" "}
                    <span className="Status-placeholder">Status</span>
                  </>
                }
                opt
                options={EXPENSE_STATUS}
                getPopupContainer={() => containerRef.current}
                className=" w-100"
                onChange={handleSelectChange}
              />
            </Col>
            <Col xs={24} sm={24} md={14} className="align-right">
              {hasCreatePermission && (
                <div>
                  <ButtonFilled onClick={showModal}>
                    Get Reimbursed
                  </ButtonFilled>
                </div>
              )}
            </Col>
          </Row>

          <Row className="page-title">
            <Col
              xs={24}
              sm={19}
              md={19}
              className="page-title-head"
              ref={containerRef}
            ></Col>
          </Row>
          <Spin spinning={displayTableLoader}>
            <div style={{ overflowX: "auto" }}>
              <StyledTable
                dataSource={filteredData}
                columns={columns}
                rowKey="user_leave_id"
                pagination={
                  filteredData.length > 100 ? paginationOptions : false
                }
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                })}
              />
            </div>
            {pagination.lastEvaluatedKey !== null && (
              <div className="d-flex justify-content-center align-items-center my-3">
                <Button
                  type="primary"
                  onClick={handleNextPage}
                  disabled={displayTableLoader}
                >
                  Load More
                </Button>
              </div>
            )}
          </Spin>
        </>
      )}
      <Modal
        open={deleteData}
        onOk={() => {
          handleDelete();
        }}
        confirmLoading={showLoader}
        okText={"Yes"}
        cancelText={"No"}
        onCancel={() => setDeleteData(false)}
        closable={false}
      >
        <Typography.Title className="p-2" level={5}>
          Are you sure want to delete this travel request?
        </Typography.Title>
      </Modal>
    </div>
  );
};

export default MyReimbursement;
