import styled from "styled-components";

export const PaymentWrap = styled.div`
  .title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add {
      color: #2d53da;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .card-section {
    display: flex;
    gap: 16px;
    height: 100%;

    .left {
      height: 100%;
      .active-employees {
        font-size: 18px;
        font-weight: 500;
      }
      .content {
        display: flex;
        margin-top: 26px;
        margin-bottom: 26px;

        .left-content {
          &.present {
            padding: 6px 10px;
            flex-basis: 300px;
          }
          .plan-cost {
            color: #636980;
            font-size: 14px;
          }
          .plan-validthru {
            font-size: 14px;
            font-style: italic;
          }
        }

        .right-content {
          border: 1px dashed #d9d9d9;
          width: 100%;
          padding: 6px 10px;
          border-radius: 6px;

          .plan-employees {
            font-size: 16px;
            font-weight: 500;
            color: #636980;
          }

          .plan-amount {
            font-size: 16px;
            font-weight: 500;

            .upcoming-month {
              font-size: 14px;
              font-weight: 400;
              color: #636980;
              font-style: italic;
            }
          }

          .free-trial-warning {
            .warning {
              display: flex;
              gap: 8px;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 6px;
            }
            .note {
              font-size: 14px;
              font-style: italic;
              color: #636980;
            }
          }
        }
      }
    }

    .right {
      height: 100%;

      .add-card {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        height: 100%;
        color: #636980;
        cursor: pointer;
      }

      .atm-cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 16px;
      }

      .view-all {
        text-align: end;
        margin-top: 6px;
        cursor: pointer;
        &:hover {
          color: #2d53da;
        }
      }

      .atm-card {
        border: 1px solid #d9d9d9;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 4px 27px 0px #00000017;
        }

        .ant-card-body {
          padding: 10px;
        }
      }
    }
  }

  .billed-monthly {
    background-color: #edfbf1;
    color: #03c83e;
    font-size: 14px;
    padding: 0px 8px;
    border-radius: 22px;
  }

  .current-plan {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 16px;
    text-transform: capitalize;
  }

  .note-1 {
    font-size: 18px;
    margin-top: 46px;
    color: #636980;
    text-align: center;
  }
  .note-2 {
    font-size: 18px;
    margin-top: 6px;
    text-align: center;
  }

  /* style override */
  .ant-card {
    box-shadow: 0px 4px 27px 0px #00000017;
    border: 1px solid #e2e2e8;
    width: 100%;
    border-radius: 6px;
    .ant-card-body {
      padding: 14px;
      height: 100%;
    }
  }

  .card-number {
    font-size: 16px;
    font-weight: 500;
  }

  .card-expiry {
    font-size: 14px;
    font-weight: 500;
    color: #636980;
  }
  .card-brand {
    font-size: 14px;
    font-weight: 500;
  }

  .ant-btn {
    width: 100%;
    box-shadow: 0px 4px 58px 0px #2d53da0d;
    border: 1px solid #2d53da;
    color: #2d53da;
    border-radius: 6px;
    font-weight: 500;
  }
`;
