import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import Button from "atoms/Button";
import { Form, notification } from "antd";
import { CALL_API } from "common/API";
import { getClientid } from "redux/selectors";
import { useSelector } from "react-redux";

const AddCard = ({ data, setShowAddAtmCardModal, getCardDetails }) => {
  const [clientSecret] = useState(data.clientSecret);
  const [subscriptionId] = useState(data.subscriptionId);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const clientId = useSelector(getClientid);
  const [messages, _setMessages] = useState("");

  const [renderPaymentElement, setRenderPaymentElement] = useState(false);

  useEffect(() => {
    // This will re-render the PaymentElement when the component is mounted
    setRenderPaymentElement(true);
  }, []);

  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return "";
  }

  const handleSubmit = async (values) => {
    setSubscribeLoading(true);
    const setupResponse = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });

    const { error, setupIntent } = setupResponse;

    if (error) {
      // show error and collect new card details.
      setMessage(error.message);
      setSubscribeLoading(false);

      return;
    }
    if (setupIntent?.status === "requires_action") {
      // Card was declined, show error and collect new card details.
      setMessage(setupIntent.next_action.message);
      setSubscribeLoading(false);

      return;
    }
    console.log("handleSubmit  paymentIntent----->", setupIntent);
    const response = await CALL_API("card/create", "post", {
      clientId,
      payment_method: setupIntent.payment_method,
    });

    if (setupIntent && setupIntent.status === "succeeded" && response) {
      notification.success({
        message: "Card Added successful",
        description: response ?? "",
      });
    } else {
      notification.error({
        message: "Failed to Add Card Details",
        description: response ?? "Please try again",
      });
    }
    getCardDetails();
    setShowAddAtmCardModal(false);
    setSubscribeLoading(false);
  };

  return (
    <>
      {/* <p>
        Try the successful test card: <span>4242424242424242</span>.
      </p>

      <p>
        Try the test card that requires SCA: <span>4000002500003155</span>.
        </p>

        <p>
        Use any <i>future</i> expiry date, CVC,5 digit postal code
      </p> */}

      <Form onFinish={handleSubmit} id="payment-form">
        <h2>Add Card Details</h2>
        {/* <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter your name",
            },
          ]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item> */}
        {/* 
        <LinkAuthenticationElement
          id="link-authentication-element"
          className="mb-2"
        /> */}
        {renderPaymentElement && (
          <PaymentElement
            id="payment-element"
            options={{
              layout: {
                type: "tabs",
                defaultCollapsed: false,
              },
              paymentMethodOrder: ["card"],
            }}
          />
        )}

        <div className="text-end mt-4">
          <Button
            type="primary"
            htmlType="submit"
            disabled={!stripe}
            loading={subscribeLoading}
          >
            Add Card
          </Button>
        </div>

        <div>{messages}</div>
      </Form>
    </>
  );
};

export default AddCard;
