import { Col, Row } from 'antd';
import Styled from "styled-components";
import OverviewBar from './overviewChart';
import { Button, Dropdown } from 'antd';
import Icon from 'atoms/Icon';
import LineChart from './lineChart';

const StyledContainer = Styled.div` 
.overview_contatiner{
    border: 1px solid #E2E2E8;
    border-radius:5px;
    background: #FFFFFF;
    box-shadow: 0px 4px 27px 0px #00000017;
    padding:15px;
    margin:10px;
}

.table th{
    color:#636980;
    font-size:14px;
    text-align:center;
}

.table>:not(caption)>*>*{
    border-bottom-width:0;
}

.table td{
    color:#636980;
    text-align:center;
}
.float_right{
    float:right;
}

.float_left{
    float:left;
}
.text_right{
    text-align:right;
}
.overview_contatiner .overview_bar_container canvas{
    height:100px;
}
.client_detail_container{
    position:relative;
}
.success_indicator{
    background-color:#08C108;
    position:absolute;
    top: -25px;
    right: -25px;
    border-radius:50%;
    width:26px;
    height:26px;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#fff;
    font-weight:500;
    font-size:12px;
}

.mb_25{
    margin-bottom:35px;
}
.subscription{
    margin-bottom:25px;
}
.subscription .ant-btn{
    border:1px solid #E2E2E8;
    border-color:#E2E2E8;
    border-radius: 8px;
    color:#636980;
    font-size:14px;
    font-weight:400;
    padding:4px;
    width:100%;
}

.m_5{
    margin:5px;
}
`;

const items = [
    {
        key: '1',
        label: "Standard",
    },
    {
        key: '2',
        label: "Premium",
    }, {
        key: '3',
        label: "Enterprise"
    }
];

const location_Client = [
    {
        country: "Kenya",
        count: 3
    },
    {
        country: "Tanzania",
        count: 5
    },
    {
        country: "Ethiopio",
        count: 2
    },
    {
        country: "Uganda",
        count: 3
    },
    {
        country: "Rwanda",
        count: 2
    },
    {
        country: "Rwanda",
        count: 3
    },
]

const country = [
    {
        key: 'country-1',
        label: 'Kenya'
    },
    {
        key: 'country-2',
        label: 'Tanzania',
    },
    {
        key: 'country-3',
        label: 'Ethiopia'
    },
    {
        key: 'country-4',
        label: 'Rwanda',
    }
];

const countRange = [
    {
        key: 'countRange-1',
        label: '0 - 100'
    },
    {
        key: 'countRange-2',
        label: '101 - 200'
    },
    {
        key: 'countRange-3',
        label: '201 - 300'
    },
    {
        key: 'countRange-4',
        label: '301 - 500'
    },
];

const BackOffice = () => {


    return (
        <StyledContainer>
            <Row>
                <Col lg={14}>
                    <div className='overview_contatiner'>
                        <div className='subscription'>
                            <h6>Overview</h6>
                            <Row className='mb_25'>
                                <Col lg={8}>
                                    <div className='m_5'>
                                        <Dropdown
                                            menu={{
                                                items: items,
                                            }}
                                            placement="bottomLeft"
                                        >
                                            <Button>
                                                <Icon name="dropDownFilter" className="float_left" />
                                                Subscription
                                                <Icon name="arrowDown" className="float_right" />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className='m_5'>
                                        <Dropdown
                                            menu={{
                                                items: country,
                                            }}
                                            placement="bottomLeft"
                                        >
                                            <Button>
                                                <Icon name="dropDownFilter" className="float_left" />
                                                Country
                                                <Icon name="arrowDown" className="float_right" />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className='m_5'>
                                        <Dropdown
                                            key="countRange-1"
                                            menu={{
                                                items: countRange
                                            }}
                                            placement="bottomLeft"
                                        >
                                            <Button>
                                                <Icon name="dropDownFilter" className="float_left" />
                                                Employee Count
                                                <Icon name="arrowDown" className="float_right" />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>

                            <OverviewBar />
                        </div>
                    </div>
                </Col>
                <Col lg={10}>
                    <div className='overview_contatiner'>
                        <div className='client_detail_container'>
                            <span className='success_indicator'>2</span>
                            <Row>
                                <Col lg={18}>
                                    <h6>Active Clients</h6>
                                </Col>
                                <Col lg={6}>
                                    <h6 className='text_right'>30</h6>
                                </Col>
                                <div>(2) new clients have started using the system</div>
                            </Row>
                        </div>

                    </div>
                    <div className='overview_contatiner'>
                        <Row>
                            <Col lg={18}>
                                <h6>InActive Clients</h6>
                            </Col>
                            <Col lg={6}>
                                <h6 className='text_right'>2</h6>
                            </Col>
                            <div>No new inactive client since a week</div>

                        </Row>
                    </div>

                    <div className='overview_contatiner'>
                        <Row>
                            <Col lg={18}>
                                <h6>Payment Failed</h6>
                            </Col>
                            <Col lg={6}>
                                <h6 className='text_right'>30</h6>
                            </Col>
                            <div>(2) client have fount some issues</div>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={14}>
                    <div className='overview_contatiner'>
                        <h6>Overall Client Growth in a year</h6>
                        <LineChart />
                    </div>
                </Col>
                <Col lg={10}>
                    <div className='overview_contatiner'>
                        <h6>Client Locations</h6>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Country</th>
                                    <th scope="col">Client Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {location_Client.map((data, idx) => {
                                    return (
                                        <tr>
                                            <td>{data.country}</td>
                                            <td>{data.count}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </StyledContainer >
    )
}

export default BackOffice