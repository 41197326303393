export const formTemplates = [
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "Yes",
            placeholder: "Option 1",
          },
          {
            optionText: "No",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 1,
        question: "How can we contact you?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "Option 1",
          },
        ],
        formType: "SHORT_ANS",
        id: 2,
        question: "Full Name",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "Email",
          },
          {
            optionText: "Phone",
          },
          {
            optionText: "Mail",
          },
        ],
        formType: "CHECKBOX",
        id: 3,
        question: "Preferred Contact Method",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "Yes",
          },
          {
            optionText: "No",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 4,
        question: "Do you have any dietary restrictions?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Contact Information Form",
    clientId: "WATSKIG",
    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Contact Information",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "Yes",
            placeholder: "Option 1",
          },
          {
            optionText: "No",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 1,
        question: "Will you be attending the event?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "Option 1",
          },
        ],
        formType: "SHORT_ANS",
        id: 2,
        question: "Full Name",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "Email",
          },
          {
            optionText: "Phone",
          },
          {
            optionText: "Mail",
          },
        ],
        formType: "CHECKBOX",
        id: 3,
        question: "Preferred Contact Method",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "Yes",
          },
          {
            optionText: "No",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 4,
        question: "Any dietary restrictions?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Event Registration Form",
    clientId: "WATSKIG",
    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Event Registration",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "1",
          },
          {
            optionText: "2",
          },
          {
            optionText: "3",
          },
          {
            optionText: "4",
          },
          {
            optionText: "5",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 1,
        question: "Rate your overall experience (1-5):",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 2,
        question: "What did you enjoy the most?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 3,
        question: "What could be improved?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "Yes",
          },
          {
            optionText: "No",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 4,
        question: "Would you recommend this event?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Event Feedback Form",
    clientId: "WATSKIG",
    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Event Feedback",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "High",
          },
          {
            optionText: "Medium",
          },
          {
            optionText: "Low",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 1,
        question: "Urgency of the request:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 2,
        question: "Describe the nature of your work request:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "FILE_UPLOAD",
        id: 3,
        question: "Attach any relevant documents:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "DATE_PICKER",
        id: 4,
        question: "Preferred completion date:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Work Request Form",
    clientId: "WATSKIG",
    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Work Request",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "1",
          },
          {
            optionText: "2",
          },
          {
            optionText: "3",
          },
          {
            optionText: "4",
          },
          {
            optionText: "5",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 1,
        question: "How satisfied are you with our service (1-5)?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 2,
        question: "What aspects do you think we excel in?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 3,
        question: "Is there anything we could improve?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "Yes",
          },
          {
            optionText: "No",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 4,
        question: "Would you use our services again?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Customer Feedback Form",
    clientId: "WATSKIG",
    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Customer Feedback",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 1,
        question: "Position applied for:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "FILE_UPLOAD",
        id: 2,
        question: "Upload your resume:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 3,
        question: "Describe your relevant experience:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 4,
        question: "What motivates you to apply for this position?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 5,
        question: "Expected salary:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Job Application Form",
    clientId: "WATSKIG",
    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Job Application",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 1,
        question: "Product/Service Name:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "NUMBER_INPUT",
        id: 2,
        question: "Quantity:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 3,
        question: "Preferred color/size (if applicable):",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "TEXTAREA",
        id: 4,
        question: "Delivery address:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "TEXTAREA",
        id: 5,
        question: "Additional comments:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Order Form",
    clientId: "WATSKIG",
    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Order Form",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "1",
          },
          {
            optionText: "2",
          },
          {
            optionText: "3",
          },
          {
            optionText: "4",
          },
          {
            optionText: "5",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 1,
        question: "Subject of Assessment:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 2,
        question: "Rate your proficiency (1-5):",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "TEXTAREA",
        id: 3,
        question: "Describe your strengths in this area:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "TEXTAREA",
        id: 4,
        question: "Areas for improvement:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "TEXTAREA",
        id: 5,
        question: "Any additional comments:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Assessment Form",
    clientId: "WATSKIG",
    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Assessment",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 1,
        question: "Topic of the worksheet:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "TEXTAREA",
        id: 2,
        question: "Provide your answers/solutions:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "MULIPLE_CHOICE",
        id: 3,
        question: "Are there any challenges you faced?",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "TEXTAREA",
        id: 4,
        question: "Additional comments or thoughts:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "FILE_UPLOAD",
        id: 5,
        question: "Attach any supporting materials:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Worksheet Form",
    clientId: "WATSKIG",
    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Worksheet",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
  {
    deleteStatus: false,
    questions: [
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "DATE_TIME_PICKER",
        id: 1,
        question: "Event date and time:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "TEXTAREA",
        id: 2,
        question: "Venue address:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "DATE_PICKER",
        id: 3,
        question: "RSVP by (date):",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 4,
        question: "Dress code (if any):",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
      {
        options: [
          {
            optionText: "",
          },
        ],
        formType: "SHORT_ANS",
        id: 5,
        question: "Special instructions or requests:",
        question_placeholder: "Question",
        requiredSwitch: false,
      },
    ],
    updatedAt: "2024-02-07T07:05:34.507Z",
    status: true,
    assignedDetails: [],
    description: "Party Invite Form",
    clientId: "WATSKIG",

    insertedAt: "2024-02-07T07:05:34.507Z",
    title: "Party Invite",
    img: "https://form-template1.s3.ap-south-1.amazonaws.com/template-image1.png",
  },
];
