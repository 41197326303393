import React, { useState } from "react";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import { Form, Input, message, Modal } from "antd";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { STATUS_CODE } from "common/Constants";
import { CALL_API } from "common/API";
import { connect } from "react-redux";
import { getClientid } from "redux/selectors";

const StyledModal = styled(Modal)`
  border-radius: 10px;
  padding-top: 5%;
  p {
    font-size: 18px;
    color: black;
    font-weight: 600;
  }
  small {
    font-size: 14px;
    color: ${COLORS.TEXT.PRIMARY};
  }

  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-close-x {
    /* display: none; */
    padding-top: 10px;
  }
  .ant-modal-footer {
    display: none;
  }
  .stylebtn {
    min-width: 130px;
  }
  .ant-modal-body {
    padding: 24px 24px 0px 24px;
  }
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    border-radius: 10px;
  }
`;

function EditAvailableCount({
  clientId,
  userId,
  leaveId,
  isModalOpen,
  handleCancel,
  name,
  totalLeaveConsumed,
  totalLeaveAvailable,
  leaveAvailable,
  getUserLeaveData
}) {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [btnLoader, setBtnLoader] = useState(false)

  const handleSubmit = async (value) => {
    setBtnLoader(true)
    const { code } = await CALL_API(
      `employee-leave-details/${clientId}`,
      "patch",
      {
        userId: userId,
        leaveType: leaveId,
        availableCount: Number(value?.avilablecount),
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      getUserLeaveData();
      message.success("Leave count updated successfully");
      setBtnLoader(false);
      handleCancel();
      form.resetFields();
      setError("")
    }
  };

  return (
    <div>
      <StyledModal
        title=""
        open={isModalOpen}
        onCancel={()=>{handleCancel();form.resetFields();setError("")}}
        // closeIcon={true}
        footer={[]}
      >
        <p>{name}</p>

        <div className="p-2 mb-3 box">
          <div
            style={{
              color:
                error ===
                  "Available count should not go beyond consumed count" &&
                "#FF9B9C",
            }}
          >
            <small
              style={{
                color:
                  error ===
                    "Available count should not go beyond consumed count" &&
                  "#FF9B9C",
              }}
            >
              Consumed:
            </small>{" "}
            {totalLeaveConsumed}
          </div>
          <div
            style={{
              color:
                error === "Available count should not exceed maximum limit" &&
                "red",
            }}
          >
            <small
              style={{
                color:
                  error === "Available count should not exceed maximum limit" &&
                  "red",
              }}
            >
              Maximum available leave count:
            </small>{" "}
            {totalLeaveAvailable}
          </div>
        </div>

        <Form
          className=""
          form={form}
          name="addemployeeform"
          layout="vertical"
          initialValues={{
            avilablecount: totalLeaveAvailable,
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="avilablecount"
            label="Available Leave Count"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    setError("Available count is required");
                    return Promise.reject(
                      new Error("Available count is required")
                    );
                  } else if (value > totalLeaveAvailable) {
                    setError("Available count should not exceed maximum limit");
                    return Promise.reject(
                      new Error(
                        "Available count should not exceed maximum limit"
                      )
                    );
                  } else if (value < totalLeaveConsumed) {
                    setError(
                      "Available count should not go beyond consumed count"
                    );
                    return Promise.reject(
                      new Error(
                        "Available count should not go beyond consumed count"
                      )
                    );
                  }else{
                    setError("");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder="Available Leave Count"
              maxLength={5}
              onKeyDown={(e) => {
                const allowedChars = /^[0-9]$/;
                const specialKeys = [
                  "Backspace",
                  "ArrowLeft",
                  "ArrowRight",
                  "Delete",
                  "Tab",
                ];

                if (!allowedChars.test(e.key) && !specialKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
          <div className="text-end mb-3">
            <ButtonOutlined
              key="back"
              onClick={()=>{handleCancel();form.resetFields();setError("")}}
              className="stylebtn"
            >
              Cancel
            </ButtonOutlined>
            <ButtonFilled
              key="submit"
              loading={btnLoader}
              className="stylebtn"
              htmlType="submit"
            >
              Save
            </ButtonFilled>
          </div>
        </Form>
      </StyledModal>
    </div>
  );
}
const mapStateToProps = (state) => ({
  clientId: getClientid(state),
});
export default connect(mapStateToProps, null)(EditAvailableCount);
