import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { ButtonFilled } from "reusableComponent/UIButtons";
import styled from "@emotion/styled";
import MobileApp from "./assets/MobileApp.png";
import MobileAppBg from "./assets/MobileAppBg.png";

const StyledText = styled.p`
  font-size: 1rem;
  text-align: center;
`;

const BackgroundContainer = styled.div`
  background-image: url(${MobileAppBg});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  .center-image {
    max-width: 100px;
    height: auto;
  }
`;

const MobileAppPrompt = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //   setIsMobile(true);
    //   setIsModalVisible(true);
    // }
    if (/android/i.test(userAgent) && !window.MSStream) {
      setIsMobile(true);
      setIsModalVisible(true);
    }
  }, []);

  return (
    <Modal visible={isModalVisible} footer={false} closable={false}>
      <BackgroundContainer className="background-container">
        <img src={MobileApp} alt="Mobile App" className="center-image" />
      </BackgroundContainer>
      <div className="p-3">
      <StyledText>
        For a better experience, download our mobile app to continue further.
        Enjoy seamless access to all features, real-time updates, and a
        user-friendly interface
      </StyledText>
      <div className="d-flex justify-content-center">
        <ButtonFilled
          type="primary"
          href="https://play.google.com/store/apps/details?id=com.tymeplus.saas"
          target="_blank"
        >
          Download Now
        </ButtonFilled>
      </div>
      </div>
    </Modal>
  );
};

export default MobileAppPrompt;
